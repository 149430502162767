import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./NewReport.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { useForm, Controller } from "react-hook-form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import icon from "../../../assets/Images/anonymous.svg";
import { IoIosArrowDown } from "react-icons/io";
import { Validation } from "../../../utils/Validation";
import { handleKeyPress, onInputChange } from "../../../utils/manageConstant";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import SublyApi from "../../../helpers/Api";
import Loader from "../../../utils/Loader/Loader";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import {
  DATE_FORMATES,
  FIELD_TYPE,
  GENDER,
  IMAGE_AS,
  IS_FOUND,
  LOCATION,
  REPORT_AS,
} from "../../../utils/Constants";
import CategoryModal from "../NewReport/CategoryModal";
import { ImCross } from "react-icons/im";
import { formatDate } from "../../../utils/CommonDateFormate";
import InputGroups from "../../../commonComponent/InputGroup";
import VehicleModal from "./SelectVehicleModal";
import SliderAge from "../../../commonComponent/SliderAge";
import EthenicityModal from "./EthenicityModal";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import TimePicker from "./TimePicker";
import ReportSubmitPopup from "./ReportSubmitPopup";
import ReactPlayer from "react-player";
import ReportExitPopup from "./ReportExitPopup";
import MapReportView from "../../../commonComponent/GoogleMap/MapReportView";
import leftIcon from "../../../assets/Images/angle-left.svg";
import rightIcon from "../../../assets/Images/angle-right.svg";
import calender from "../../../assets/Images/Calendar.svg";
import clock from "../../../assets/Images/Clock.svg";
import map from "../../../assets/Images/Location.svg";
import locationIcon from "../../../assets/Images/location_ico.svg";
import TermsModal from "../../Content/TermsModal";
import {
  setReportExit,
  setReportExitModal,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { toast } from "react-toastify";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import { FiCamera } from "react-icons/fi";
import {
  ContentState,
  convertToRaw,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createHashtagPlugin from "draft-js-hashtag-plugin";
import defaultImage from "../../../assets/Images/user_profile_Default_image.png";

const hashtagPlugin = createHashtagPlugin();
const plugins = [hashtagPlugin];

const customStyleMap = {
  RED: {
    color: "red",
  },
  BLUE: {
    color: "blue",
  },
  GREEN: {
    color: "green",
  },
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowLeft} onClick={onClick}>
      <img src={leftIcon} alt="icon" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.arrrowRight} onClick={onClick}>
      <img src={rightIcon} alt="icon" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={`${styles.arrrowBgLeft}`} onClick={onClick}>
      <img src={leftIcon} alt="icon" />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className={`${styles.arrrowBgRight}`} onClick={onClick}>
      <img src={rightIcon} alt="icon" />
    </div>
  );
};

//---function for user new report---------
function NewReport() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });
  const [autoCompleteLoad, setAutoCompleteLoad] = useState(null);
  const onLoad = (autocompleteInstance) => {
    setAutoCompleteLoad(autocompleteInstance);
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [formatedDate, setFormatedDate] = useState("");
  const [loader, setLoader] = useState(false);
  const [sourceGeometry, setSourceGeometry] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });

  const autocomplete = useRef(null);
  const { userToken, userData } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [crimesCategory, setCrimesCategory] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [imageIds, setImageIds] = useState([]);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [isTimeUnknown, setIsTimeUnknown] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [vehicleModal, setVehicleModal] = useState(false);
  const [age, setAge] = useState("");
  const [ethenicity, setEthenicity] = useState("");
  const [ethenicityModal, setEthenicityModal] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [gender, setGender] = useState("");
  const [target, setTarget] = useState(null);
  const [hashtags, setHashtags] = useState([]);
  const location = useLocation();
  const detailId = location?.state?.id;
  const isUpdate = location?.state?.isUpdate;
  const sliderRef = useRef(null);
  const [reportId, setReportId] = useState("");
  const [testImg, setTestImg] = useState("");
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [targetTime, setTargetTime] = useState(null);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [reportShow, setReportShow] = useState(false);
  const [formData, setFormData] = useState();
  const [currentPosition, setCurrentPosition] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });
  const [termsModal, setTermsModal] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const [activeKeyCat, setActiveKeyCat] = useState();
  const [report, setReportData] = useState();
  const [sourceLat, setSourceLat] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });

  useEffect(() => {
    setSourceLat(sourceGeometry);
  }, [sourceGeometry]);

  const [position, setPosition] = useState({
    lat: 0.0,
    lng: 0.0,
  });

  const onMarkerDragEnd = (e) => {
    setPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [disable, setDisable] = useState(false);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const contentState = newEditorState.getCurrentContent();
    const selectionState = newEditorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = contentState.getBlockForKey(anchorKey);
    const text = currentContentBlock.getText();

    const lastWord = text.split(" ").pop();

    // Check if backspace was pressed
    const isBackspace =
      editorState.getLastChangeType() === "backspace-character";

    if (!isBackspace && lastWord.startsWith("#")) {
      setShowSuggestions(true);
      const filteredSuggestions = hashtags.filter((tagItem) =>
        tagItem.tag.startsWith(lastWord.replace("#", ""))
      );
      setSuggestions(filteredSuggestions);

      // this code for not selected #tag
      if (hashtags.length > 0 && filteredSuggestions.length == 0) {
        setTimeout(() => {
          handleSuggestionClick({ tagID: "", tag: lastWord.replace("#", "") });
        }, [3000]);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  function applyHashtagStyle(text) {
    const hashtagRegex = /#\w+/g;
    let match;

    // Create ContentState from the input text
    let contentState = ContentState.createFromText(text);

    // Iterate over all blocks in the content state
    const blockMap = contentState.getBlockMap();
    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const blockText = block.getText();

      // Use the regex to find hashtags in the block text
      while ((match = hashtagRegex.exec(blockText)) !== null) {
        const start = match.index;
        const end = match.index + match[0].length;

        const selection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end,
        });

        contentState = Modifier.applyInlineStyle(
          contentState,
          selection,
          "RED"
        );
      }
    });

    return contentState;
  }

  const handleSuggestionClick = (suggestion) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const anchorOffset = selection.getAnchorOffset();
    const anchorKey = selection.getAnchorKey();

    const blockText = currentContent.getBlockForKey(anchorKey).getText();

    // Find the start of the hashtag
    const hashtagStart = blockText.lastIndexOf("#", anchorOffset);
    let hashtagsCount = (blockText.match(new RegExp("#", "g")) || []).length;

    // Create a new selection state specifically for the hashtag
    const hashtagSelection = selection.merge({
      anchorOffset: hashtagsCount == 1 ? hashtagStart : hashtagStart - 1,
      focusOffset: endOffset,
    });

    // Replace the text in the hashtag range and apply the style
    const contentWithEntity = Modifier.replaceText(
      currentContent,
      hashtagSelection,
      " #" + suggestion.tag + " ",
      editorState.getCurrentInlineStyle().merge(["RED"]) // Apply the RED color style
    );

    // Move the cursor to the end of the inserted hashtag
    const updatedSelection = contentWithEntity.getSelectionAfter();

    // Insert a space after the hashtag
    const contentWithSpace = Modifier.insertText(
      contentWithEntity,
      updatedSelection,
      " ",
      editorState.getCurrentInlineStyle().remove("RED") // Reset to default style (no color)
    );

    const newEditorState = EditorState.push(
      editorState,
      contentWithSpace,
      "insert-characters"
    );

    setEditorState(newEditorState);
    setShowSuggestions(false); // Hide suggestions after selecting
  };

  const getPlainText = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const text = rawContent.blocks.map((block) => block.text).join("\n");
    return text;
  };

  const fetchHashtagSuggestions = async (userToken, tag = "") => {
    const response = await SublyApi.hashtagSuggestion(userToken, tag);
    if (response && response.status_code === STATUS_CODES.SUCCESS) {
      setHashtags(response.data.tags);
    }
  };
  const MAX_IMAGES = 8;
  const MAX_VIDEOS = 3;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const extractImageIds = (attachments) => {
    const ids = attachments.map((attachment) =>
      parseInt(attachment.reportAttachmentID)
    );
    return ids;
  };

  const formatWeekDay = (day) => {
    const dayMap = {
      Su: "Sun",
      Mo: "Mon",
      Tu: "Tue",
      We: "Wed",
      Th: "Thu",
      Fr: "Fri",
      Sa: "Sat",
    };
    return dayMap[day.substring(0, 2)];
  };

  useEffect(() => {
    const date = new Date();
    const newDate = formatDate(date);
    setFormatedDate(newDate);
    setValue("validDate", date);
    dispatch(setReportExitModal(true));
    fetchHashtagSuggestions(userToken);
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
      dispatch(setReportExit(true));
    };

    // Add the event listener
    window.addEventListener("popstate", handlePopState);

    window.history.pushState(null, null, window.location.href);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handlePrefillValues = (data) => {
    const defaultText = data.details;

    const initialContentState = applyHashtagStyle(defaultText);

    initialContentState &&
      setEditorState(EditorState.createWithContent(initialContentState));

    setReportId(data.reportID);
    setValue("description1", data.details);
    setValue("location", data.location);
    setSelectedSubCategory({
      id: data.crimeCategoryID,
      name: data.category_name,
      field_type: data.field_type,
      parent_category_id: data.parent_category_id,
    });

    setActiveKey(data && data.parent_category_id);

    setSourceGeometry({
      location: data.location,
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    });
    setPosition({
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude),
    });

    if (data && data.date) {
      const date = moment(data.date, DATE_FORMATES.YY_MM_DD).toDate();
      setStartDate(date);
      const newDate = formatDate(date);
      setFormatedDate(newDate);
      setValue("validDate", date);
    } else {
      setStartDate("");
    }
    if (data && data.time && data.time !== null && data.time !== "00:00:00") {
      const [h, m, s] = data.time.split(":");
      setHours(h);
      setMinutes(m);
      setIsTimeUnknown(false);
    } else {
      setIsTimeUnknown(true);
    }
    resetSlider(data.reported_as == 1 ? 0 : 1);
    setSelectedFiles(data.report_attachments);

    const filterImg = data.report_attachments.filter(
      (el) => el.video_thumb_url == null
    );
    const filterImage = filterImg.map((val) => {
      return { ...val, id: val.reportAttachmentID };
    });
    setSelectedImage(filterImage);

    const filtered = data.report_attachments.filter(
      (el) => el.video_thumb_url != null
    );
    const filterVideo = filtered.map((val) => {
      return { ...val, id: val.reportAttachmentID };
    });
    setSelectedVideo(filterVideo);

    const idsArray = extractImageIds(data.report_attachments);
    setImageIds(idsArray);

    if (data.field_type == FIELD_TYPE.PERSON) {
      setValue("fullName", data.person_report.full_name);
      setAge(data.person_report.age);

      setGender(
        data?.person_report?.gender == 0
          ? ""
          : data?.person_report?.gender == 2
          ? "female"
          : "male"
      );
      setEthenicity({
        id: data.person_report.ethnicity,
        name: data.person_report.ethinicity_name,
      });
      // setEthenicity(data.person_report.ethnicity);
      setIsFound(data.person_report.is_found == 1 ? true : false);
    } else if (data.field_type == FIELD_TYPE.VEHICLE) {
      setValue("registration", data.vehicle_report.registration);
      setValue("vehicleModal", data.vehicle_report.model);
      setValue("vehicleColor", data.vehicle_report.color);
      setVehicle({
        id: data.vehicle_report.vehicle_make_id,
        name: data.vehicle_report.vehicle_make,
      });
    }
  };

  useEffect(() => {
    async function getReportDetails() {
      setLoader(true);
      await SublyApi.reportDetails(userToken, detailId).then(
        async (response) => {
          setLoader(false);
          const errormsg = response && response.data && response.data.message;
          if (response && response.status_code === STATUS_CODES.SUCCESS) {
            setReportData(response?.data?.report);
            handlePrefillValues(response?.data?.report);
          } else if (
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "ACCOUNT_DISABLED") ||
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "SESSION_EXPIRED") ||
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "INVALID_TOKEN")
          ) {
            dispatch(userLogoutClear());
            navigate("/");
            Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
          } else {
            Toster(t(errormsg), "error");
          }
        }
      );
    }
    detailId && getReportDetails();
  }, []);

  const resetFormState = () => {
    reset();
    resetSlider(0);
    setStartDate("");
    setFormatedDate("");
    setSourceGeometry("");
    setSelectedSubCategory("");
    setEthenicity("");
    setSelectedFiles([]);
    setSelectedImage([]);
    setSelectedVideo([]);
    setImageIds([]);
    setVehicle("");
    setGender("");
    setAge("");
    setIsFound(false);
    setIsTimeUnknown(false);
    setActivePageIndex(0);
    setHashtags([]);
  };

  // function for change age
  const handleAgeChange = (event, newValue) => {
    setAge(newValue);
  };

  // function for handle gender change
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  // function for get category list api calling
  async function crimeCategory() {
    await SublyApi.crimeCategoryList(userToken).then((response) => {
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setActiveKeyCat(
          response.data &&
            response.data.categories.length > 0 &&
            response.data.categories[0].parentCategoryId
        );

        setCrimesCategory(response && response.data);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    crimeCategory();
    userToken && dispatch(userDetailData(userToken));
  }, []);

  useEffect(() => {
    if (report) {
      handlePrefillValues(report);
      setValue("location", report?.location);
    }
  }, [crimesCategory, report]);

  const IMAGE_SIZE_MB = 10;
  const VIDEO_SIZE_MB = 200;
  const MIN_IMAGE_WIDTH = 150;
  const MIN_IMAGE_HEIGHT = 150;
  const MAX_IMAGE_WIDTH = 4000;
  const MAX_IMAGE_HEIGHT = 4000;
  const VALID_VIDEO_EXTENSIONS = ["mp4", "MP4", "3gp", "3GP"];

  // function for manage vidio upload case
  const handleExtractFrame = (videoFile) => {
    if (!videoFile) return;

    const videoElement = document.createElement("video");

    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true; // Mute the video for autoplay
    videoElement.play(); // Start playing the video to ensure metadata is loaded
    videoElement.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      // Ensure the video is loaded and can be drawn
      videoElement.currentTime = 0;

      videoElement.onseeked = () => {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          const imageURL = URL.createObjectURL(blob);

          // Send the image blob to the API
          const requestData = new FormData();
          requestData.append("attachment_thumb", blob, "frame.jpg");
          requestData.append("attachment", videoFile);
          requestData.append("type", IMAGE_AS.VIDIO);
          setLoader(true);
          const response = await SublyApi.addReportImage(
            requestData,
            userToken
          );
          setLoader(false);
          if (response && response.status_code === STATUS_CODES.SUCCESS) {
            const newImageId = response.data.attachment_id;
            setImageIds((prevIds) => [...prevIds, newImageId]);

            setSelectedFiles((prevFiles) => [
              ...prevFiles,
              {
                video_thumb_url: imageURL,
                attachment_url: URL.createObjectURL(videoFile),
              },
            ]);
            setSelectedVideo((pre) => [
              ...pre,
              {
                video_thumb_url: imageURL,
                attachment_url: URL.createObjectURL(videoFile),
                id: newImageId,
              },
            ]);
          } else if (
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "ACCOUNT_DISABLED") ||
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "SESSION_EXPIRED") ||
            (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
              response?.data?.error_type === "INVALID_TOKEN")
          ) {
            dispatch(userLogoutClear());
            navigate("/");
            Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
          } else {
            const errormsg = response && response.data && response.data.message;
            Toster(t(errormsg), "error");
          }
        }, "image/jpeg");
      };
    };
  };

  async function imageUpload(requestData, e, file) {
    requestData.append("attachment", e.target.files[0]);
    setLoader(true);
    const response = await SublyApi.addReportImage(requestData, userToken);
    setLoader(false);
    if (response && response.status_code === STATUS_CODES.SUCCESS) {
      const newImageId = response.data.attachment_id;
      setImageIds((prevIds) => [...prevIds, newImageId]);

      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        { attachment_thumb: URL.createObjectURL(file) },
      ]);
      setSelectedImage((pre) => [
        ...pre,
        {
          attachment_thumb: URL.createObjectURL(file),
          id: newImageId,
        },
      ]);
    } else if (
      (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
        response?.data?.error_type === "ACCOUNT_DISABLED") ||
      (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
        response?.data?.error_type === "SESSION_EXPIRED") ||
      (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
        response?.data?.error_type === "INVALID_TOKEN")
    ) {
      dispatch(userLogoutClear());
      navigate("/");
      Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
    } else {
      const errormsg = response && response.data && response.data.message;
      Toster(t(errormsg), "error");
    }
  }

  // function for manage multiple image selection
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const binaryData = reader.result;
        const blob = new Blob([binaryData], { type: file.type });

        let requestData = new FormData();

        if (imageFiles.length + selectedImage?.length > MAX_IMAGES) {
          Toster(t("USER_SELECT_UP_TO_8_IMAGE"), "error");
          return;
        }

        if (file.type.startsWith("image/")) {
          const img = new Image();
          img.src = URL.createObjectURL(blob);

          img.onload = async () => {
            requestData.append("type", IMAGE_AS.IMAGE);

            // Check image dimensions
            if (img.width < MIN_IMAGE_WIDTH) {
              Toster(t("IMAGE_WIDTH_MUST_BE_150px"), "error");
              return false;
            }
            if (img.width > MAX_IMAGE_WIDTH) {
              Toster(t("IMAGE_WIDTH_MUST_BE_4000px"), "error");
              return false;
            }
            if (img.height < MIN_IMAGE_HEIGHT) {
              Toster(t("IMAGE_HEIGHT_MUST_BE_150px"), "error");
              return false;
            }
            if (img.height > MAX_IMAGE_HEIGHT) {
              Toster(t("IMAGE_HEIGHT_MUST_BE_4000px"), "error");
              return false;
            }

            // Check image file size
            if (file.size > IMAGE_SIZE_MB * 1024 * 1024) {
              Toster(t("IMAGE_SIZE_MUST_BE_10MB"), "error");
              return false;
            }

            // Check limits for images and videos

            imageUpload(requestData, e, file);
          };
        } else if (file.type.startsWith("video/")) {
          // Check video file extension
          const fileExtension = file.name.split(".").pop();
          if (!VALID_VIDEO_EXTENSIONS.includes(fileExtension)) {
            Toster(t("INVALID_VIDEO_FORMAT_MP4_3GP_ONLY"), "error");
            return;
          }

          const videoElement = document.createElement("video");
          videoElement.src = URL.createObjectURL(blob);

          videoElement.onloadedmetadata = () => {
            if (
              videoElement.videoWidth === 0 ||
              videoElement.videoHeight === 0
            ) {
              Toster(t("This video format not supported"), "error");
              return; // Handle the error gracefully
            }

            if (selectedVideo?.length >= MAX_VIDEOS) {
              Toster(t("USER_SELECT_UP_TO_3_VIDEO"), "error");
              return;
            }

            // Check video dimensions
            if (videoElement.videoWidth < MIN_IMAGE_WIDTH) {
              Toster(t("VIDEO_WIDTH_MUST_BE_150px"), "error");
              return;
            }
            if (videoElement.videoWidth > MAX_IMAGE_WIDTH) {
              Toster(t("VIDEO_WIDTH_MUST_BE_4000px"), "error");
              return;
            }
            if (videoElement.videoHeight < MIN_IMAGE_HEIGHT) {
              Toster(t("VIDEO_HEIGHT_MUST_BE_150px"), "error");
              return;
            }
            if (videoElement.videoHeight > MAX_IMAGE_HEIGHT) {
              Toster(t("VIDEO_HEIGHT_MUST_BE_4000px"), "error");
              return;
            }

            // Check video file size
            if (file.size > VIDEO_SIZE_MB * 1024 * 1024) {
              Toster(t("VIDEO_SIZE_MUST_BE_200MB"), "error");
              return;
            }

            // Handle video frame extraction and upload
            handleExtractFrame(file);
          };
        }
      };
    });
  };

  // function for manage remove image
  const onImageRemove = async (id) => {
    setLoader(true);
    await SublyApi.DeleteReportImage(userToken, id).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        const filtered = selectedImage.filter((el) => el.id != id);
        setSelectedImage(filtered);

        const filteredVideo = selectedVideo.filter((el) => el.id != id);
        setSelectedVideo(filteredVideo);

        const filteredId = imageIds.filter((el) => el != id);
        setImageIds(filteredId);
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  // function for handle date
  const handleDateChange = (date) => {
    const newDate = formatDate(date);
    setFormatedDate(newDate);
  };

  useEffect(() => {
    const currentTime = new Date();
    const formattedHours = String(currentTime.getHours()).padStart(2, "0");
    const formattedMinutes = String(currentTime.getMinutes()).padStart(2, "0");

    setHours(formattedHours);
    setMinutes(formattedMinutes);
  }, []);

  // function for handle unknown time
  const handleUnknownTimeChange = () => {
    setIsTimeUnknown(!isTimeUnknown);
    if (!isTimeUnknown) {
      setHours("");
      setMinutes("");
    } else {
      const currentTime = new Date();
      const formattedHours = String(currentTime.getHours()).padStart(2, "0");
      const formattedMinutes = String(currentTime.getMinutes()).padStart(
        2,
        "0"
      );
      setHours(formattedHours);
      setMinutes(formattedMinutes);
    }
  };

  // function for handle is_found
  const handleIsFound = () => {
    setIsFound(!isFound);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange: (current) => setActivePageIndex(current),
    beforeChange: (current, next) => setActivePageIndex(next),
  };

  const settingImg = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (current) => setActivePageIndex(current),
    beforeChange: (current, next) => setActivePageIndex(next),
  };

  const resetSlider = (sliderIndex = 0) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(sliderIndex);
    }
  };

  //----------function for handle set error---------
  const handleAddressSelect = (place) => {
    const location = place && place.formatted_address;
    const lat = place && place.geometry.location.lat();
    const lng = place && place.geometry.location.lng();
    setPosition({ location, lat, lng });
    setSourceGeometry({ location, lat, lng });
  };

  function getCurrentLocationPermisttion() {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            // Permission was already granted
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback
            );
          } else if (result.state === "prompt") {
            // Permission has not been requested yet, will show the popup
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback
            );
          } else if (result.state === "denied") {
            setPosition({
              location: LOCATION.LOC,
              lat: LOCATION.LAT,
              lng: LOCATION.LONG,
            });
            setSourceGeometry({
              location: LOCATION.LOC,
              lat: LOCATION.LAT,
              lng: LOCATION.LONG,
            });
            setValue("location", LOCATION.LOC);
            // Permission was denied previously, handle accordingly
          }
        });
    } else {
      // Fallback for browsers that don't support the Permissions API
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }

    async function successCallback(position) {
      const { latitude, longitude } = position.coords;

      const locationName = await fetchLocationName(latitude, longitude);
      locationName && getCurrentLocation(locationName, latitude, longitude);

      if (report) {
        setSourceGeometry({
          location: report?.location,
          lat: report ? parseFloat(report?.latitude) : latitude,
          lng: report ? parseFloat(report?.longitude) : longitude,
        });
        setPosition({
          location: report?.location,
          lat: report ? parseFloat(report?.latitude) : latitude,
          lng: report ? parseFloat(report?.longitude) : longitude,
        });
        setCurrentPosition({
          location: report?.location,
          lat: report ? parseFloat(report?.latitude) : latitude,
          lng: report ? parseFloat(report?.longitude) : longitude,
        });
        setValue("location", report?.location);
      } else {
        setPosition({
          location: "",
          lat: latitude,
          lng: longitude,
        });
        locationName &&
          setCurrentPosition({
            lat: latitude,
            lng: longitude,
            location: locationName,
          });
        locationName &&
          setSourceGeometry({
            lat: latitude,
            lng: longitude,
            location: locationName,
          });
        setValue("location", locationName);
      }
    }

    function errorCallback(error) {
      setPosition({
        location: LOCATION.LOC,
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
      });
      setSourceGeometry({
        location: LOCATION.LOC,
        lat: LOCATION.LAT,
        lng: LOCATION.LONG,
      });
      setValue("location", LOCATION.LOC);
    }
  }
  useEffect(() => {
    getCurrentLocationPermisttion();
  }, []);

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY; // Use your Google API key here
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response?.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      return null; // No results found
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(
        position?.lat,
        position?.lng
      );
      if (locationName) {
        setSourceGeometry({
          location: locationName,
          lat: position?.lat,
          lng: position?.lng,
        });
        setValue("location", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position?.lat, position?.lng]);

  // function for get current location
  async function getCurrentLocation(locationSet, lat, lng) {
    let requestData = new FormData();
    requestData.append("current_location", locationSet);
    requestData.append("current_latitude", parseFloat(lat));
    requestData.append("current_longitude", parseFloat(lng));

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });
    const urlEncodedData = qs.stringify(dataObject);
    await SublyApi.currentLocation(userToken, urlEncodedData).then(
      (response) => {
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
        }
      }
    );
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSourceLat((prevSourceLat) => ({
      ...prevSourceLat,
      [name]: value,
    }));

    setPosition((pre) => ({
      ...pre,
      [name]: parseFloat(value),
    }));
  };

  const handleReCenter = () => {
    if (currentPosition.lat != 0 && currentPosition.lng != 0) {
      setPosition(currentPosition);
    } else {
      getCurrentLocationPermisttion();
    }
  };

  function extractTags(text) {
    // Use a regular expression to find all #tags
    let tagsArray = text.match(/#(\w+)/g);

    // Remove the # symbol from each tag
    let cleanedTags = tagsArray ? tagsArray.map((tag) => tag.slice(1)) : [];

    // Join the tags into a comma-separated string
    let commaSeparatedTags = cleanedTags.join(",");

    return commaSeparatedTags;
  }

  const handleSubmitReport = () => {
    setDisable(true);
    const plainText = getPlainText().trim();

    // Check if the plainText is empty or just whitespace
    if (plainText.length === 0) {
      Toster(t("DESCRIBE_TEXT_REQUIRED"), "error");
      setDisable(false);
      return;
    }

    const regex = /#[^\s#]+/g;
    var foundHashtags = extractTags(plainText);

    isUpdate === true
      ? updateApi(formData, foundHashtags || "", plainText)
      : createApi(formData, foundHashtags || "", plainText);
  };

  const onSubmit = (data) => {
    if (!selectedSubCategory) {
      Toster(t("PLEASE_SELECT_CATEGORY"), "error");
      return;
    }

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContentBlock = contentState.getBlockForKey(anchorKey);
    const text = currentContentBlock.getText().trim();

    // Check if text is empty or too short
    if (text.length === 0) {
      Toster(t("DESCRIBE_TEXT_REQUIRED"), "error");
      return;
    }

    if (text.length < 2) {
      Toster(
        t("Description too short. Please add more incident details"),
        "error"
      );
      return;
    }

    toast.dismiss();
    setFormData(data);
    setReportShow(true);
    setShow(false);
  };

  //----------function for create new report api calling----------
  const createApi = (formdata, foundHashtags, plainText) => {
    let requestData = new FormData();
    requestData.append(
      "crime_category_id",
      selectedSubCategory ? selectedSubCategory.id : ""
    );
    requestData.append(
      "location",
      sourceGeometry ? sourceGeometry.location : ""
    );
    requestData.append("city", "");
    requestData.append("state", "");
    requestData.append("country", "");
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("details", plainText ? plainText : "");
    requestData.append("hash_tag", foundHashtags);
    requestData.append(
      "field_type",
      selectedSubCategory ? selectedSubCategory.field_type : ""
    );

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.TIP_OFF
    ) {
      requestData.append("reported_as", REPORT_AS.ANONYMOUS);
    } else {
      requestData.append(
        "reported_as",
        activePageIndex === 0 ? REPORT_AS.PROFILE : REPORT_AS.ANONYMOUS
      );
      requestData.append("date", formatedDate ? formatedDate : "");

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedTime = `${formattedHours}:${formattedMinutes}`;
      requestData.append(
        "time",
        isTimeUnknown ? "" : formattedTime ? formattedTime : ""
      );

      requestData.append("attachments", JSON.stringify(imageIds));
      requestData.append("street_number", "");
      requestData.append("street", "");
      requestData.append("postal_code", "");
    }

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.PERSON
    ) {
      requestData.append("full_name", formdata ? formdata.fullName : "");
      requestData.append(
        "gender",
        gender === "male"
          ? GENDER.MALE
          : gender === "female"
          ? GENDER.FEMALE
          : ""
      );
      requestData.append("age", age ? age : "");
      requestData.append("ethnicity", ethenicity ? ethenicity.id : "");
      requestData.append(
        "is_found",
        isFound ? IS_FOUND.CHECK : IS_FOUND.UNCHCEK
      );
    }

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.VEHICLE
    ) {
      requestData.append("registration", formdata ? formdata.registration : "");
      requestData.append("make", vehicle ? vehicle.id : "");
      requestData.append("model", formdata ? formdata.vehicleModal : "");
      requestData.append("color", formdata ? formdata.vehicleColor : "");
    }

    setLoader(true);
    setReportShow(false);
    SublyApi.addNewReport(requestData, userToken).then((response) => {
      setLoader(false);
      setDisable(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        dispatch(setReportExitModal(false));
        Toster(t(msg), "success");
        navigate("/your-report");
        resetFormState();
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  //----------function for update new report api calling----------
  const updateApi = (formdata, foundHashtags, plainText) => {
    let requestData = new FormData();
    requestData.append(
      "crime_category_id",
      selectedSubCategory ? selectedSubCategory.id : ""
    );
    requestData.append(
      "location",
      sourceGeometry ? sourceGeometry.location : ""
    );
    requestData.append("city", "");
    requestData.append("state", "");
    requestData.append("country", "");
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("details", plainText ? plainText : "");
    requestData.append("hash_tag", foundHashtags);
    requestData.append(
      "field_type",
      selectedSubCategory ? selectedSubCategory.field_type : ""
    );

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.TIP_OFF
    ) {
      requestData.append("reported_as", REPORT_AS.ANONYMOUS);
    } else {
      requestData.append(
        "reported_as",
        activePageIndex === 0 ? REPORT_AS.PROFILE : REPORT_AS.ANONYMOUS
      );
      requestData.append("date", formatedDate ? formatedDate : "");

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedTime = `${formattedHours}:${formattedMinutes}`;
      requestData.append(
        "time",
        isTimeUnknown ? "" : formattedTime ? formattedTime : ""
      );

      requestData.append("attachments", JSON.stringify(imageIds));
      requestData.append("street_number", "");
      requestData.append("street", "");
      requestData.append("postal_code", "");
    }

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.PERSON
    ) {
      requestData.append("full_name", formdata ? formdata.fullName : "");
      requestData.append(
        "gender",
        gender === "male"
          ? GENDER.MALE
          : gender === "female"
          ? GENDER.FEMALE
          : ""
      );
      requestData.append("age", age ? age : "");
      requestData.append("ethnicity", ethenicity ? ethenicity.id : "");
      requestData.append(
        "is_found",
        isFound ? IS_FOUND.CHECK : IS_FOUND.UNCHCEK
      );
    }

    if (
      selectedSubCategory &&
      selectedSubCategory.field_type == FIELD_TYPE.VEHICLE
    ) {
      requestData.append("registration", formdata ? formdata.registration : "");
      requestData.append("make", vehicle ? vehicle.id : "");
      requestData.append("model", formdata ? formdata.vehicleModal : "");
      requestData.append("color", formdata ? formdata.vehicleColor : "");
    }

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });

    // Convert the object to URL-encoded string
    const urlEncodedData = qs.stringify(dataObject);
    setLoader(true);
    setReportShow(false);
    SublyApi.UpdateNewReport(urlEncodedData, userToken, reportId).then(
      (response) => {
        setLoader(false);
        setDisable(false);
        const msg = response && response.message;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          dispatch(setReportExitModal(false));
          Toster(t(msg), "success");
          navigate("/your-report");
          resetFormState();
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            {location.pathname !== "/report" && (
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
            )}

            <Col
              lg={location.pathname !== "/report" ? 9 : 12}
              md={location.pathname !== "/report" ? 8 : 12}
              className={
                location.pathname !== "/report" ? "rightPart" : "centerSet"
              }
            >
              {location.pathname !== "/report" && (
                <div className="rightTopPart">
                  <div className={styles.searchBoxCls}>
                    <h2>{t("NEW_REPORT")}</h2>
                  </div>
                </div>
              )}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={
                    location.pathname !== "/report"
                      ? styles.reportIncident
                      : styles.reportMargin
                  }
                >
                  <h3>
                    <strong>{t("REPORT")} </strong>
                    {t("INCIDENT")}
                  </h3>
                  <div className={`${styles.reportSelect} tooltipCls`}>
                    <Button
                      ref={target}
                      onClick={(event) => {
                        setShow(!show);
                        setTarget(event.target);
                      }}
                    >
                      <div className={styles.reportManage}>
                        {selectedSubCategory?.name ? (
                          <p>{selectedSubCategory.name}</p>
                        ) : (
                          <p>{t("SELECT_CRIME")}</p>
                        )}
                        <IoIosArrowDown />
                      </div>
                    </Button>
                    <Overlay
                      target={target}
                      show={show}
                      rootClose={true}
                      placement="bottom"
                      onHide={() => setShow(false)}
                    >
                      {(props) => (
                        <Tooltip
                          id="overlay-example"
                          {...props}
                          className="tooltipSetCrime"
                        >
                          <CategoryModal
                            setShow={setShow}
                            selectedSubCategory={selectedSubCategory}
                            setSelectedSubCategory={setSelectedSubCategory}
                            crimesCategory={crimesCategory}
                            activeKey={activeKey ? activeKey : activeKeyCat}
                            setActiveKey={setActiveKey}
                          />
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>

                  {selectedSubCategory.field_type != FIELD_TYPE.TIP_OFF && (
                    <div className={styles.filterSection}>
                      <div>
                        <h5>{t("DATE")}</h5>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className={styles.filterInputGroup}
                          >
                            <img src={calender} alt="icon" />
                          </InputGroup.Text>
                          <div className={`${styles.datepicker} DateSetPicker`}>
                            <Controller
                              name="validDate"
                              control={control}
                              defaultValue={null}
                              rules={Validation.dateValidate}
                              render={({ field }) => (
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => {
                                    handleDateChange(date);
                                    setStartDate(date);
                                    field.onChange(date);
                                  }}
                                  maxDate={new Date()}
                                  dateFormat="eee dd MMM yyyy"
                                  placeholderText={t("SELECT_DATE")}
                                  formatWeekDay={formatWeekDay}
                                />
                              )}
                            />
                          </div>
                        </InputGroup>
                        {errors.validDate && (
                          <div className={styles.errorDiv}>
                            <span className="errorMsg">
                              {errors.validDate.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className={styles.timeboxSet} ref={targetTime}>
                        <div className={styles.chekboxSet}>
                          <h5>{t("TIME")}</h5>
                          <Form.Check
                            type="checkbox"
                            id="unknownTimeCheckbox"
                            label={t("UNKNOWN")}
                            checked={isTimeUnknown}
                            onChange={handleUnknownTimeChange}
                            className="customCheckbox"
                          />
                        </div>
                        <div
                          className={styles.timePicker}
                          onClick={(event) => {
                            setShowTimePicker(!showTimePicker);
                            setTargetTime(event.target);
                          }}
                        >
                          <div className={styles.timePickerSvg}>
                            <img src={clock} alt="icon" />
                          </div>
                          <p>
                            {isTimeUnknown
                              ? "00"
                              : String(hours).padStart(2, "0")}
                            :
                            {isTimeUnknown
                              ? "00"
                              : String(minutes).padStart(2, "0")}
                          </p>
                        </div>
                        <Overlay
                          target={targetTime}
                          show={showTimePicker}
                          rootClose={true}
                          placement="bottom"
                          onHide={() => setShowTimePicker(false)}
                        >
                          {(props) => (
                            <Tooltip
                              id="overlay-examples"
                              {...props}
                              className="tooltipSet"
                            >
                              <TimePicker
                                setShowTimePicker={setShowTimePicker}
                                hours={hours}
                                setHours={setHours}
                                setMinutes={setMinutes}
                                minutes={minutes}
                                isTimeUnknown={isTimeUnknown}
                                setIsTimeUnknown={setIsTimeUnknown}
                              />
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                    </div>
                  )}

                  <div className={styles.location}>
                    <h5>
                      <strong>{t("LOCATION")} </strong>
                      {t("OF_INCIDENT")}
                    </h5>
                    {isLoaded ? (
                      <Autocomplete
                        placeApiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                        onLoad={onLoad}
                        onPlaceChanged={() => {
                          if (autoCompleteLoad !== null) {
                            const place = autoCompleteLoad.getPlace();
                            handleAddressSelect(place);
                          }
                        }}
                      >
                        <InputGroup>
                          <InputGroup.Text className={styles.inputGroup}>
                            <img src={map} alt="icon" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            placeholder={t("HAPPEN_TEXT")}
                            onInputChange={(e) => handleKeyPress(e, setValue)}
                            {...register("location", Validation.location)}
                          />
                        </InputGroup>
                      </Autocomplete>
                    ) : (
                      <></>
                    )}
                    <p>{t("CONFIRM_PIN")}</p>
                    {errors.location && (
                      <span className="errorMsg">
                        {errors.location.message}
                      </span>
                    )}

                    <div className={styles.googleMapSet}>
                      <MapReportView
                        position={position}
                        zoom={16}
                        setPosition={setPosition}
                        onMarkerDragEnd={onMarkerDragEnd}
                        mapContainerStyle={{
                          height: "250px",
                          width: "100%",
                        }}
                      />
                      <div className={styles.showCenter}>
                        <h6 onClick={() => handleReCenter()}>
                          <img src={locationIcon} alt="icon" />
                          {t("RECENTRE")}
                        </h6>
                        <p>
                          <strong>{t("LAT")}</strong>{" "}
                          <input
                            type="text"
                            name="lat"
                            onChange={(e) => handleInputChange(e)}
                            value={sourceLat.lat}
                          />
                        </p>
                        <p>
                          <strong>{t("LONG")}</strong>{" "}
                          <input
                            type="text"
                            name="lng"
                            onChange={(e) => handleInputChange(e)}
                            value={sourceLat.lng}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* if field type is vehicle */}
                  {selectedSubCategory &&
                    selectedSubCategory.field_type == FIELD_TYPE.VEHICLE && (
                      <div className={styles.location}>
                        <InputGroups
                          name="registration"
                          type="text"
                          register={register}
                          onInputChange={(e) => onInputChange(e, setValue)}
                          errors={errors}
                          label=""
                          placeholder={t("REGISTRATION")}
                          maxCount={""}
                          text={""}
                          isHideGroup
                          isRequired={false}
                        />
                        <div
                          className={`${styles.vehicleDiv} mb-3`}
                          onClick={() => setVehicleModal(true)}
                        >
                          {vehicle?.name ? (
                            <h6>{vehicle.name}</h6>
                          ) : (
                            <p>{t("VEHICLE_MAKE")}</p>
                          )}
                          <IoIosArrowDown />
                        </div>

                        <InputGroups
                          name="vehicleModal"
                          type="text"
                          register={register}
                          onInputChange={(e) => onInputChange(e, setValue)}
                          errors={errors}
                          label=""
                          placeholder={t("INPUT_VEHICLE")}
                          maxCount={""}
                          text={""}
                          isHideGroup
                          isRequired={false}
                        />
                        <InputGroups
                          name="vehicleColor"
                          type="text"
                          register={register}
                          onInputChange={(e) => onInputChange(e, setValue)}
                          errors={errors}
                          label=""
                          placeholder={t("VEHICLE_COLOR")}
                          maxCount={""}
                          text={""}
                          isHideGroup
                          isRequired={false}
                        />
                      </div>
                    )}

                  {/* if field type is person */}
                  {selectedSubCategory &&
                    selectedSubCategory.field_type == FIELD_TYPE.PERSON && (
                      <div className={styles.location}>
                        <InputGroups
                          name="fullName"
                          type="text"
                          register={register}
                          onInputChange={(e) => handleKeyPress(e, setValue)}
                          errors={errors}
                          validationRules={Validation.name}
                          label={t("FULLNAME")}
                          placeholder=""
                          maxCount={""}
                          text={""}
                          isHideGroup
                          isRequired={false}
                        />
                        <div className={styles.personDetails}>
                          <div className="switch-field genderRadioButoon">
                            <Form.Check
                              type="radio"
                              id="radio-one"
                              name="switch-one"
                              value="female"
                              label={t("FEMALE")}
                              checked={gender === "female"}
                              onChange={handleGenderChange}
                            />
                            <Form.Check
                              style={{ marginLeft: "20px" }}
                              type="radio"
                              id="radio-two"
                              name="switch-one"
                              value="male"
                              label={t("MALE")}
                              checked={gender === "male"}
                              onChange={handleGenderChange}
                            />
                          </div>
                          <SliderAge
                            min={0}
                            max={99}
                            value={age}
                            onChange={handleAgeChange}
                          />
                          <h3>{t("ETHNICITY")}</h3>
                          <div
                            className={`${styles.ethenicDiv} mb-3`}
                            onClick={() => setEthenicityModal(true)}
                          >
                            {ethenicity?.name ? (
                              <p>{ethenicity.name}</p>
                            ) : (
                              <p>{t("SELECT_ETHNIC")}</p>
                            )}
                            <IoIosArrowDown />
                          </div>
                        </div>
                      </div>
                    )}

                  <div className={styles.location}>
                    <div className="formCls">
                      <h5>
                        {t("INCIDENT")} <strong>{t("DETAILS")}</strong>
                      </h5>
                      <div
                        className={`editor-container ${styles.placeholderColor}`}
                      >
                        <Editor
                          editorState={editorState}
                          onChange={handleEditorChange}
                          plugins={plugins}
                          customStyleMap={customStyleMap}
                          placeholder={t("DESCRIBE_DETAIL")}
                        />

                        {showSuggestions && suggestions.length > 1 && (
                          <ul
                            className={styles.suggestionsList}
                            style={{
                              background: "#fff",
                              border: "1px solid rgba(0, 0, 0, .13)",
                              width: "100%",
                              maxWidth: "120px",
                              backgroundClip: "padding-box",
                              borderRadius: "4px",
                              overflowX: "hidden",
                              listStyle: "none",
                              margin: "7px 0 0",
                            }}
                          >
                            {suggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                className={styles.suggestionItem}
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                {suggestion.tag}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <p className={styles.hashTag}>{t("HASHTAG")}</p>
                    </div>
                  </div>
                </div>

                {/* if field type is person */}
                {selectedSubCategory &&
                  selectedSubCategory.field_type == FIELD_TYPE.PERSON && (
                    <div
                      className={`${styles.chekboxSet} ${styles.isFoundCheck}`}
                    >
                      <Form.Check
                        type="checkbox"
                        id="isFound"
                        label={t("CHCEK_FOUND")}
                        checked={isFound}
                        onChange={handleIsFound}
                      />
                    </div>
                  )}

                {selectedSubCategory.field_type != FIELD_TYPE.TIP_OFF && (
                  <>
                    <div className={styles.uploadSection}>
                      <input
                        accept="image/*,video/*"
                        id="file"
                        multiple
                        name="profileImage"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <label htmlFor="file" className={styles.uploadPic}>
                        <FiCamera /> {t("ADD_PHOTO")}
                      </label>
                      <div className={styles.showImages}>
                        {selectedImage.map((file, index) => {
                          return (
                            <div key={index} className={styles.showImageBox}>
                              <LightGallery
                                speed={500}
                                closable={true}
                                hideBarsDelay={0}
                                controls={false}
                                download={false}
                                counter={false}
                              >
                                <a href={file.attachment_thumb}>
                                  <img
                                    src={file.attachment_thumb}
                                    width="100"
                                    height="100"
                                    style={{ cursor: "pointer" }}
                                  />
                                </a>
                              </LightGallery>
                              <span className={styles.imageCrossIcon}>
                                <ImCross
                                  onClick={() => onImageRemove(file.id)}
                                />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className={`${styles.vidioUpload}`}>
                        <div className={styles.showImageBox}>
                          {selectedVideo.length > 1 ? (
                            <Slider {...settingImg}>
                              {selectedVideo.map((file, index) => (
                                <div>
                                  <div key={index}>
                                    <ReactPlayer
                                      url={file.attachment_url}
                                      controls={true}
                                      className={styles.reactPlayer}
                                    />
                                    <span className={styles.imageCrossIcons}>
                                      <ImCross
                                        onClick={() => onImageRemove(file.id)}
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          ) : (
                            selectedVideo.length == 1 && (
                              <div>
                                <ReactPlayer
                                  url={selectedVideo[0].attachment_url}
                                  controls={true}
                                  className={styles.reactPlayer}
                                />
                                <span className={styles.imageCrossIcon}>
                                  <ImCross
                                    onClick={() =>
                                      onImageRemove(selectedVideo[0].id)
                                    }
                                  />
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.vidioSection}></div>
                  </>
                )}

                {selectedSubCategory.field_type != FIELD_TYPE.TIP_OFF ? (
                  <div className={styles.reportSlider}>
                    <Slider
                      ref={sliderRef}
                      {...settings}
                      initialSlide={activePageIndex}
                    >
                      <div className={styles.reportInfo}>
                        <div className={styles.reportImg}>
                          <img
                            src={
                              userData?.profile_picture
                                ? userData?.profile_picture
                                : defaultImage
                            }
                            alt="img"
                          />
                        </div>
                        <div className={styles.reportText}>
                          <p>{t("REPORT_AS")}</p>
                          <h3>{userData && userData.name}</h3>
                        </div>
                      </div>
                      <div className={styles.reportInfo}>
                        <div className={styles.reportImgs}>
                          <img src={icon} alt="img" />
                        </div>
                        <div className={styles.reportText}>
                          <p>{t("REPORT_AS")}</p>
                          <h3>{t("ANONYMS")}</h3>
                          <h6>{t("IDENTITY_WITHELD")}</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                ) : (
                  <div
                    className={`${styles.reportInfo} ${styles.reportInfoSpace} `}
                  >
                    <div className={styles.reportImg}>
                      <img src={icon} alt="img" />
                    </div>
                    <div className={styles.reportText}>
                      <p>{t("REPORT_AS")}</p>
                      <h3>{t("ANONYMS")}</h3>
                      <h6>{t("IDENTITY_WITHELD")}</h6>
                    </div>
                  </div>
                )}

                <div className={styles.bottomReport}>
                  <h2>
                    {t("BY_POSTING")}{" "}
                    <strong onClick={() => setTermsModal(true)}>
                      {" "}
                      {t("TERMS")}
                    </strong>
                  </h2>
                  <div className={styles.reportButton}>
                    <Button
                      type="button"
                      className={styles.leftButton}
                      onClick={() => dispatch(setReportExit(true))}
                    >
                      {t("CANCEL")}
                    </Button>
                    <Button type="submit" className={styles.rightButton}>
                      {isUpdate === true
                        ? `${t("UPDATE_REPORT")}`
                        : `${t("SUBMIT_REPORT")}`}
                    </Button>
                  </div>
                </div>
                <div className={styles.noteReport}>
                  <p>
                    <strong>{t("PLEASE_NOTE")} </strong> {t("CRIME_TEXT")}
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <VehicleModal
        vehicleModal={vehicleModal}
        setVehicleModal={setVehicleModal}
        crimesCategory={crimesCategory}
        setVehicle={setVehicle}
        vehicle={vehicle}
      />
      <EthenicityModal
        ethenicityModal={ethenicityModal}
        setEthenicityModal={setEthenicityModal}
        crimesCategory={crimesCategory}
        setEthenicity={setEthenicity}
        ethenicity={ethenicity}
      />

      <ReportSubmitPopup
        reportShow={reportShow}
        setReportShow={setReportShow}
        handleSubmitReport={handleSubmitReport}
        disable={disable}
      />

      <ReportExitPopup />

      <TermsModal termsModal={termsModal} setTermsModal={setTermsModal} />
    </div>
  );
}
export default NewReport;
