import { Button } from "react-bootstrap";
import styles from "./CommonButton.module.css";

//--------Common button for user module----------
function UserCommonButton({ onClick, label, type }) {
  return (
    <div className={styles.userButton}>
      <Button onClick={onClick} type={type}>
        {label}
      </Button>
    </div>
  );
}

export default UserCommonButton;
