import { useEffect, useState } from "react";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import EmailAlertPopup from "./EmailAlertPopup";
import qs from "qs";
import { useNavigate } from "react-router-dom";

function EmailSubscribe() {
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get("email");
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
      navigate("/");
    }, 3000);

    // Cleanup the timeout when the component unmounts
    return () => clearTimeout(timer);
  }, [navigate, setShowPopup]);

  // function for manage email update alert
  async function emailUpdateAlert() {
    let requestData = new FormData();
    requestData.append("email", emailParam);

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });
    const urlEncodedData = qs.stringify(dataObject);

    await SublyApi.emailAlertUpdate(urlEncodedData).then((response) => {
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
      }
    });
  }

  useEffect(() => {
    emailUpdateAlert();
  }, []);

  return (
    <div className="main">
      <EmailAlertPopup showPopup={showPopup} setShowPopup={setShowPopup} />
    </div>
  );
}
export default EmailSubscribe;
