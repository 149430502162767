import { Col, Container, Row, Form, InputGroup } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./YourProfile.module.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MdOutlineMailOutline, MdOutlineAlternateEmail } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { TiSocialFacebook } from "react-icons/ti";
import { PiGlobeLight } from "react-icons/pi";
import { FaMobileAlt } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { ImPlus } from "react-icons/im";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import TextArea from "../../../commonComponent/TextAreaField";
import InputGroups from "../../../commonComponent/InputGroup";
import { Validation } from "../../../utils/Validation";
import {
  b64toBlob,
  formatLocalTime,
  getRandom,
  handleKeyPress,
  onInputChange,
} from "../../../utils/manageConstant";
import { MdCheckCircleOutline } from "react-icons/md";
import SublyApi from "../../../helpers/Api";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Loader from "../../../utils/Loader/Loader";
import {
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import CropImg from "./CropImg";
import mime from "mime";
import { BsTwitterX } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/Images/user_profile_Default_image.png";

function YourProfile() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY
  })

  const [autoCompleteLoad, setAutoCompleteLoad] = useState(null);
  const onLoad = (autocompleteInstance) => {
    setAutoCompleteLoad(autocompleteInstance);
  };
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const IMAGE_SIZE_MB = 10;
  const MIN_IMAGE_WIDTH = 200;
  const MIN_IMAGE_HEIGHT = 200;
  const MAX_IMAGE_WIDTH = 4000;
  const MAX_IMAGE_HEIGHT = 4000;

  const { userToken, userData } = useSelector((state) => state.user);
  const autocomplete = useRef(null);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [profilePreview, setProfilePreview] = useState(
    userData?.profile_picture ? userData?.profile_picture : null
  );

  const [showCrop, setShowCrop] = useState(false);

  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [profileImage, setProfileImage] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [sourceGeometry, setSourceGeometry] = useState({
    location: "",
    lat: 0.0,
    lng: 0.0,
  });


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;

        if (width < MIN_IMAGE_WIDTH) {
          Toster(t("MIN_PROFILE_IMAGE_WIDTH"), "error");
          return;
        }
        if (width > MAX_IMAGE_WIDTH) {
          Toster(t("MAX_PROFILE_IMAGE_WIDTH"), "error");
          return;
        }
        if (height < MIN_IMAGE_HEIGHT) {
          Toster(t("MIN_PROFILE_IMAGE_HEIGHT"), "error");
          return;
        }
        if (height > MAX_IMAGE_HEIGHT) {
          Toster(t("MAX_PROFILE_IMAGE_HEIGHT"), "error");
          return;
        }

        // Check image file size
        if (file.size > IMAGE_SIZE_MB * 1024 * 1024) {
          Toster(t("IMAGE_SIZE_MUST_BE_10MB"), "error");
          return;
        }

        // Continue with setting preview and cropping
        const fileUrl = URL.createObjectURL(file);
        setProfilePreview(fileUrl);
        setImageSrc(fileUrl);
        setIsCropper(true);
      };
      img.src = URL.createObjectURL(file);
    } else {
      setProfilePreview(null);
    }
  };

  //   setIsCropper(true);

  //   if (file) {
  //     const fileUrl = URL.createObjectURL(file);
  //     setProfilePreview(fileUrl);
  //     setImageSrc(fileUrl);
  //   } else {
  //     setProfilePreview(null);
  //   }
  // };

  const fetchUserProfileData = async () => {
    const response = userToken && (await dispatch(userDetailData(userToken)));
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleClose = () => {
    setShowCrop(false);
  };


  // display data pre-fill by user detail api
  useEffect(() => {
    if (userData?.username) {
      setIsUsernameAvailable(true);
    }
    setValue("name", userData?.name || "");
    setValue("userName", userData?.username || "");
    setValue("location", userData?.user_meta?.profile_location || userData?.profile_location|| "");
    setValue("description",userData?.user_meta?.profile_description || userData?.profile_description || "");
    setValue("websiteAddress", userData?.user_meta?.website_address || "");
    setValue("contact", userData?.user_meta?.profile_contact_number || "");
    setValue("email", userData?.user_meta?.profile_email || "");
    setValue(
      "facebookLink",
      userData?.user_meta?.facebook_profile_username || ""
    );
    setValue(
      "twitterLink",
      userData?.user_meta?.twitter_profile_username || ""
    );

    // Set source geometry
    setSourceGeometry({
      location: userData?.user_meta?.profile_location || "",
      lat: parseFloat(userData?.user_meta?.profile_latitude),
      lng: parseFloat(userData?.user_meta?.profile_longitude),
    });

    // Set profile image preview if exists
    if (userData?.profile_picture) {
      setProfilePreview(userData?.profile_picture);
    }
  }, [setValue, t]);

  const handleAddressSelect = (place) => {
    const location = place && place.formatted_address;
    const lat = place && place.geometry.location.lat();
    const lng = place && place.geometry.location.lng();
    setSourceGeometry({ location, lat, lng });
  };

  // Update image api function
  function onImageChange() {
    let requestData = new FormData();

    var blockCrop = profilePreview.split(";");
    var cropContentTypes = blockCrop[0]?.split(":")[1];
    var blockRealData = blockCrop[1].split(",")[1];
    var cropBlobImg = b64toBlob(blockRealData, cropContentTypes);

    requestData.append(
      "profile_picture",
      cropBlobImg,
      getRandom() + "." + mime.getExtension(cropBlobImg.type)
    );

    setLoader(true);
    SublyApi.UpdateProfilePic(requestData, userToken)
      .then((response) => {
        setLoader(false);
        const msg = response && response.message;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          Toster(t(msg), "success");
          setShowCrop(false);
          setIsCropper(false);
          fetchUserProfileData(userToken);
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        Toster(t("Error uploading image"), "error");
      });
  }

  function onImageRemove() {
    setProfilePreview("");
    setProfileImage("");
    setShowCrop(false);
    setIsCropper(false);
  }

  const handleEditClick = () => {
    setIsEditMode(true);
    document.getElementById('uploadImage').click();
  };

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  // Edit profile api
  const onSubmit = (formdata) => {
    if (!isUsernameAvailable) {
      Toster("Username is not available", "error");
      return;
    }
    let requestData = new FormData();
    requestData.append("name", formdata.name);
    requestData.append("username", formdata.userName);
    requestData.append("location", formdata.location);
    requestData.append("latitude", sourceGeometry ? sourceGeometry.lat : "");
    requestData.append("longitude", sourceGeometry ? sourceGeometry.lng : "");
    requestData.append("description", formdata.description);
    requestData.append("website_address", formdata.websiteAddress);
    requestData.append("profile_contact_number", formdata.contact);
    requestData.append("suburb", formdata.suburb || "");
    requestData.append("profile_email", formdata.email);
    requestData.append("facebook_profile_link", formdata.facebookLink);
    requestData.append("twitter_profile_link", formdata.twitterLink);

    const dataObject = {};
    requestData.forEach((value, key) => {
      dataObject[key] = value;
    });

    setLoader(true);
    SublyApi.yourProfile(dataObject, userToken).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");

        setUsernameAvailable("");
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  // function for get terms and condition api calling
  async function handleInputChange(e) {
    const { value } = e.target;
    await SublyApi.checkUserName(userToken, value).then((response) => {
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setUsernameAvailable(response);
        if (response.is_available == 1) {
          setIsUsernameAvailable(true);
        } else {
          setIsUsernameAvailable(false);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        setIsUsernameAvailable(false);
        setUsernameAvailable("");
      }
    });
  }

  const viewReport = () => {
    navigate(`/your-report`);
  };

  const followers = () => {
    navigate(`/followers`);
  };

  const following = () => {
    navigate(`/following`);
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("YOUR_PROFILE")}</h2>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.profileInfo}>
                  <div
                    className={styles.profileImg}
                    style={{ cursor: "pointer" }}
                    // onClick={() => TimeLineViewProfile(userData?.userID)}
                  >
                    <img
                      src={
                        userData?.profile_picture
                          ? userData?.profile_picture
                          : defaultImage
                      }
                      alt="img"
                    />
                  </div>
                  <div className={styles.profileText}>
                    <p>@{userData && userData?.username}</p>
                    <h3>{userData?.name}</h3>
                    <h5>
                      <span
                        onClick={() => {
                          viewReport();
                        }}
                      >
                        <strong>{userData?.total_reports}</strong>{" "}
                        {t("REPORTS")} |{" "}
                      </span>{" "}
                      <span
                        onClick={() => {
                          followers();
                        }}
                      >
                        <strong>{userData?.follower_count}</strong>{" "}
                        {t("FOLLOWERS")} |{" "}
                      </span>
                      <span
                        onClick={() => {
                          following();
                        }}
                      >
                        {" "}
                        <strong>{userData?.following_count}</strong>{" "}
                        {t("FOLLOWING")}{" "}
                      </span>
                    </h5>
                    <h6>
                      {t("JOINED")} {formatLocalTime(userData?.created_at)} -{" "}
                      {t("LAST_LOGIN")}{" "}
                      {formatLocalTime(userData?.last_login_at)}
                    </h6>
                  </div>
                </div>
                <div className={styles.profileBox}>
                  <div className={styles.profileDiv}>
                    <div className={styles.profileWidth}>
                      <InputGroups
                        icon={{
                          image: <MdOutlineAlternateEmail />,
                          color: "#d70303",
                        }}
                        name="name"
                        type="text"
                        register={register}
                        onInputChange={(e) => handleKeyPress(e, setValue)}
                        errors={errors}
                        validationRules={Validation.publicName}
                        label={t("PUBLIC_NAME")}
                        placeholder={t("DISPLAY_NAME")}
                        maxCount={50}
                        isRightIcon
                        text={t("DISPLAY_PROFILE")}
                        isHideGroup
                        IconComponent={MdCheckCircleOutline}
                        iconColor="green"
                      />

                      <InputGroups
                        icon={{
                          image: <MdOutlineAlternateEmail />,
                          color: "#d70303",
                        }}
                        name="userName"
                        type="text"
                        register={register}
                        onInputChange={handleInputChange}
                        errors={errors}
                        validationRules={Validation.userName}
                        label={t("USERNAME")}
                        placeholder={t("ENTER_USERNAME")}
                        maxCount={15}
                        isRightIcon
                        text={t("CREATE_ANYTIME")}
                        IconComponent={MdCheckCircleOutline}
                        iconColor="green"
                        usernameAvailable={usernameAvailable}
                      />
                    </div>
                  </div>
                  <div className={styles.profileSection}>
                    <div className={styles.profileWidth}>
                      <div
                        className={`${styles.profileFlex} ${styles.profileBlock}`}
                      >
                        <h2>{t("PUBLIC_PROFILE")}</h2>
                        <h6>{t("ALL_FIELD")}</h6>
                      </div>
                      <Form.Group className="mb-3 formCls">
                        <div className={styles.profileFlex}>
                          <Form.Label>{t("DESCRIPTION")}</Form.Label>
                          <h6>200</h6>
                        </div>
                        <TextArea
                          name="description"
                          placeholder={t("ENTER_DESCRIPTION")}
                          register={register}
                          errors={errors}
                          onInputChange={(e) => handleKeyPress(e, setValue)}
                          validationRules={Validation.description}
                        />
                        {errors.description && (
                          <span className="errorMsg">
                            {errors.description.message}
                          </span>
                        )}
                      </Form.Group>
                      <div className="LocationInput">
                        <h5>{t("YOUR_LOCATION")}</h5>
                        {isLoaded?(<Autocomplete
                          placeApiKey={
                            process.env.REACT_APP_GOOGLE_PLACE_API_KEY
                          }
                          onLoad={onLoad}
                          onPlaceChanged={() => {
                            if (autoCompleteLoad !== null){
                              const place = autoCompleteLoad.getPlace();
                              handleAddressSelect(place);
                            }
                            
                          }}
                          
                        >
                          <InputGroup>
                            <InputGroup.Text className={styles.inputGroup}>
                              <GrLocation
                                style={{
                                  backgroundColor: "#d70303",
                                  color: "#ffffff",
                                }}
                                size={20}
                              />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              label={t("YOUR_LOCATION")}
                              name="location"
                              placeholder={t("ENTER_LOCATION")}
                              onInputChange={(e) => handleKeyPress(e, setValue)}
                              {...register("location")}
                            />
                          </InputGroup>
                        </Autocomplete>):<></>}
                        <p>{t("EDIT_ANYTIME")}</p>
                      </div>
                      <InputGroups
                        icon={{
                          image: <PiGlobeLight />,
                          color: "#d70303",
                        }}
                        name="websiteAddress"
                        type="text"
                        register={register}
                        onInputChange={(e) => onInputChange(e, setValue)}
                        errors={errors}
                        validationRules={Validation.url}
                        label={t("WEBSITE_ADDRESS")}
                        placeholder={t("ENTER_WEBSITE")}
                        isRightIcon={false}
                        text={t("EDIT_ANYTIME")}
                        IconComponent={""}
                        iconColor=""
                      />

                      <InputGroups
                        icon={{
                          image: <FaMobileAlt />,
                          color: "#d70303",
                        }}
                        name="contact"
                        type="number"
                        register={register}
                        onInputChange={(e) => onInputChange(e, setValue)}
                        errors={errors}
                        label={t("TELEPHONE")}
                        placeholder={t("ENTER_CONTACT")}
                        isRightIcon={false}
                        text={t("EDIT_ANYTIME")}
                        IconComponent={""}
                        iconColor=""
                        validationRules={Validation.PhoneNumber}
                      />
                      {errors.PhoneNumber && (
                        <span className="errorMsg">
                          {errors.PhoneNumber.message}
                        </span>
                      )}

                      <InputGroups
                        icon={{
                          image: <MdOutlineMailOutline />,
                          color: "#d70303",
                        }}
                        name="email"
                        type="text"
                        register={register}
                        onInputChange={(e) => onInputChange(e, setValue)}
                        errors={errors}
                        validationRules={Validation.email1}
                        label={t("DISPLAY_EMAIL")}
                        placeholder={t("EMAIL_ADDRESS")}
                        isRightIcon={false}
                        text={t("EDIT_ANYTIME")}
                        IconComponent={""}
                        iconColor=""
                      />
                    </div>
                  </div>
                  <div className={styles.profileSection}>
                    <div className={styles.profileWidth}>
                      <h2>{t("SOCIAL_MEDIA")}</h2>
                      <InputGroups
                        icon={{
                          image: <TiSocialFacebook />,
                          color: "#d70303",
                        }}
                        name="facebookLink"
                        type="text"
                        register={register}
                        onInputChange={(e) => onInputChange(e, setValue)}
                        errors={errors}
                        label={""}
                        placeholder={t("FACEBOOK_LINK")}
                        isRightIcon={false}
                        text={""}
                        IconComponent={""}
                        iconColor=""
                      />

                      <InputGroups
                        icon={{
                          image: (
                            <BsTwitterX
                              style={{ width: "23px", height: "16px" }}
                            />
                          ),

                          color: "#d70303",
                        }}
                        name="twitterLink"
                        type="text"
                        register={register}
                        onInputChange={(e) => onInputChange(e, setValue)}
                        errors={errors}
                        label={""}
                        placeholder={t("TWITTER_LINK")}
                        isRightIcon={false}
                        text={""}
                        IconComponent={""}
                        iconColor=""
                      />
                    </div>
                  </div>
                  <div className={styles.profileSection}>
                    <div className={styles.profileWidth}>
                      <h2 className="mb-3">{t("PROFILE_LOGO")}</h2>
                      <div className={styles.profilePicture}>
                        <div className={styles.uploadSection}>
                          <input
                            accept="image/*"
                            id="uploadImage"
                            name="profileImage"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleFileChange(e)}
                          />
                          {profilePreview ? (
                            <div className={styles.uploadInputImg}>
                              <img src={profilePreview} alt="Uploaded" />
                              <button
                                type="button"
                                onClick={handleEditClick}
                                className={styles.cancelButton}
                              >
                                {t("EDITS")}
                              </button>
                            </div>
                          ) : (
                            <div className={styles.uploadIcon}>
                              <label htmlFor="uploadImage">
                                <ImPlus />
                              </label>
                              <p>{t("ADD_IMAGE")}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.profileSection}>
                    <UserCommonButton
                      type="button"
                      label={t("CANCEL")}
                      onClick={() => navigate(-1)}
                    />
                    <UserCommonButton type="submit" label={t("SUBMIT")} />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <CropImg
        handleClose={handleClose}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setProfilePreview}
        aspectRatio={1}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
        onImageChange={onImageChange}
      />
    </div>
  );
}

export default YourProfile;
