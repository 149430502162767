import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";

const initialState = {
  currentUser: {},
  userData: {},
  pushNotificationData: {},
  isLoading: false,
  userToken: null,
  success: false,
  error: null,
  reportExit: false,
  reportExitModal: false,
  reportExitModalUrl: "",
  pushAlertStatus: {},
  searchQuery: "",
  searchFilterQuery: "",
  searchCheckboxesQuery: "",
  searchCountApi: 0,
  isReadTerms: 0,
  firebaseToken: null,
  total_alerts: 0,
  following_count: 0,
  zoom: 15,
};

// Thunk for user login
export const userLogin = createAsyncThunk(
  "user/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.login(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user SignUp
export const userSignupVerify = createAsyncThunk(
  "user/userSignupVerify",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.varifySignupCode(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for Social Signup
export const socialSignup = createAsyncThunk(
  "user/socialSignup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.socialSignup(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk for user detail data
export const userDetailData = createAsyncThunk(
  "user/userDetailData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await SublyApi.userProfileData(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogoutClear: (state, action) => {
      state.currentUser = {};
      state.userData = {};
      state.isLoading = false;
      state.pushNotificationData = {};
      state.userToken = null;
      state.success = false;
      state.reportExit = false;
      state.reportExitModal = false;
      state.reportExitModalUrl = "";
      state.pushAlertStatus = {};
      state.searchQuery = "";
      state.searchFilterQuery = "";
      state.searchCheckboxesQuery = "";
      state.searchCountApi = 0;
      state.isReadTerms = 0;
      state.firebaseToken = null;
      state.total_alerts = 0;
      state.following_count = 0;
      state.zoom = 15;
    },
    setUpdateUserData: (state, action) => {
      state.userData = action.payload;
    },
    setReportExit: (state, action) => {
      state.reportExit = action.payload;
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    setReportExitModal: (state, action) => {
      state.reportExitModal = action.payload;
    },
    setReportExitUrl: (state, action) => {
      state.reportExitModalUrl = action.payload;
    },
    updatePushNotificationData: (state, action) => {
      state.pushNotificationData = {
        ...state.pushNotificationData,
        ...action.payload,
      };
    },

    updateEmailAlert: (state, action) => {
      state.userData.email_alert = action.payload;
    },
    updatePushAlert: (state, action) => {
      state.pushAlertStatus = {
        ...state.pushAlertStatus,
        ...action.payload,
      };
      state.userData.push_alert = action.payload.push_alert;
    },

    updateSettingCount: (state, action) => {
      const updatePaload = action.payload;
      const keyName = updatePaload.keyName;
      state.userData[keyName] = updatePaload.value;

      // state.userData.following_count = action.payload;
    },

    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSearchFilterQuery: (state, action) => {
      state.searchFilterQuery = action.payload;
    },
    setSearchCheckboxesQuery: (state, action) => {
      state.searchCheckboxesQuery = action.payload;
    },
    setSearchCountApi: (state, action) => {
      state.searchCountApi = action.payload;
    },
    initializeCheckboxes(state, action) {
      state.searchCheckboxesQuery = action.payload;
    },
    updateTermsAndCondition: (state, action) => {
      state.isReadTerms = action.payload;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },

  extraReducers: (builder) => {
    // ------Builder for user login-------
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken =
          response &&
          response.data &&
          response.data.user_details &&
          response.data.user_details.auth_token;
        state.currentUser =
          response && response.data && response.data.user_details;
        state.userData =
          response && response.data && response.data.user_details;
        state.success = true;
        state.reportExit = false;
        state.reportExitModal = false;
        state.reportExitModalUrl = "";
      } else {
        state.userToken = null;
        state.success = false;
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for user signup-------
    builder.addCase(userSignupVerify.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userSignupVerify.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken =
          response &&
          response.data &&
          response.data.user_details &&
          response.data.user_details.auth_token;
        state.currentUser =
          response && response.data && response.data.user_details;
        state.userData =
          response && response.data && response.data.user_details;

        state.success = true;
      } else {
        state.userToken = null;
        state.success = false;
      }
    });
    builder.addCase(userSignupVerify.rejected, (state, action) => {
      state.isLoading = false;
    });

    // ------Builder for social signup-------
    builder.addCase(socialSignup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(socialSignup.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        state.userToken =
          response &&
          response.data &&
          response.data.user_details &&
          response.data.user_details.auth_token;
        state.currentUser =
          response && response.data && response.data.user_details;
        state.userData =
          response && response.data && response.data.user_details;
        state.success = true;
        state.reportExit = false;
        state.reportExitModal = false;
        state.reportExitModalUrl = "";
      } else {
        state.userToken = null;
        state.success = false;
      }
    });
    builder.addCase(socialSignup.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // ------Builder for User detail-------
    builder.addCase(userDetailData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userDetailData.fulfilled, (state, action) => {
      const response = action.payload;
      state.isLoading = false;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        // state.userToken =
        //   response &&
        //   response.data &&
        //   response.data.user_details &&
        //   response.data.user_details.auth_token;

        state.userData = response && response.data?.user_details;
        const userMeta = response.data.user_details.user_meta;
        const pushAlertSettings =
          response.data.user_details.push_alert_settings;

        state.pushNotificationData = {
          ...pushAlertSettings,
          suspicious_activity_alert: userMeta.suspicious_activity_alert,
          new_message_alert: userMeta.new_message_alert,
          push_alert: response.data?.user_details.push_alert,
        };
        state.success = true;
      } else {
        // state.userToken = null;
        state.success = false;
      }
    });
    builder.addCase(userDetailData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  userLogoutClear,
  setReportExit,
  setReportExitModal,
  setReportExitUrl,
  setUpdateUserData,
  updatePushNotificationData,
  updatePushAlert,
  updateEmailAlert,
  updateSettingCount,
  setSearchQuery,
  setSearchFilterQuery,
  setSearchCheckboxesQuery,
  updateTermsAndCondition,
  setSearchCountApi,
  initializeCheckboxes,
  setFirebaseToken,
  setZoom,
} = userSlice.actions;
export default userSlice.reducer;
