//firebase
import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import { useDispatch } from "react-redux";
import { setFirebaseToken } from "../store/slices/Userslice";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

function generateFirebaseToken(dispatch) {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem(
          process.env.REACT_APP_FIREBASE_TOKEN,
          currentToken
        );

        // Dispatch the token to Redux
        dispatch(setFirebaseToken(currentToken));
      } else {
        console.log("Failed to generate the registration token.");
      }
    })
    .catch((err) => {
      console.log(
        "An error occurred when requesting to receive the token.",
        err
      );
    });
}

export function requestNotificationPermission(dispatch) {
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    // console.log("This browser does not support notifications.");
    // alert('This browser does not support notifications.');
    return;
  }

  // Check if permission has already been granted
  if (Notification.permission === "granted") {
    // console.log("Permission to receive notifications has been granted");
    generateFirebaseToken(dispatch);
  } else if (Notification.permission !== "denied") {
    // Request permission from the user
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("Permission to receive notifications has been granted");
        generateFirebaseToken(dispatch);
      }
    });
  }
}
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const messaging = getMessaging(app);
const storage = getStorage();

export { app, database, generateFirebaseToken, storage };
