import { useSelector } from "react-redux";
import ReportDetails from "./ReportDetails";
import ReportDetailsPublic from "./ReportDetailsPublic";
import { useEffect } from "react";
import branch from "branch-sdk";

function ReportDetailsShow() {
  const { currentUser } = useSelector((state) => state.user);
  // useEffect(() => {
  //   branch.init(process.env.REACT_APP_BRANCH_IO_KEY, (err, data) => {
  //     if (err) {
  //       console.error("Branch initialization error:", err);
  //     } else {
  //       console.log("Branch initialized:", data);
  //     }
  //   });
  // }, []);

  return (
    <div>
      {Object.keys(currentUser)?.length === 0 ? (
        <ReportDetailsPublic />
      ) : (
        <ReportDetails />
      )}
    </div>
  );
}

export default ReportDetailsShow;
