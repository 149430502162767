import styles from "../../authComponent/Login/Login.module.css";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AuthButton from "../../commonComponent/CommonButton/AuthButton";

//---------function for password success message----------
function PasswordSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { emailActive } = location.state;

  return (
    <div className="main">
      <Container>
        <div className="topSpace">
          <div className={styles.passwordSet}>
            <h3>{t("PASSWORD_REMINDER")}</h3>
            <h4>
              {t("PASSWORD_SAND")}{" "}
              {emailActive ? `${t("EMAIL_SMALL")}` : `${t("NUMBER")}`}.
            </h4>
          </div>
          <div className={styles.formBlock}>
            {/* common button */}
            <AuthButton
              type="submit"
              onClick={() => navigate("/login")}
              label={t("RETURN TO LOGIN")}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default PasswordSuccess;
