import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Security_icon from "../../../assets/Images/Security_announcement.svg";
import styles from "./Message.module.css";
import { RxCross2 } from "react-icons/rx";

const ConfirmationModal = ({ show, handleClose, handleConfirm, action }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        className="mainModule"
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  handleClose();
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.securityIconDiv}>
              <span>
                <img src={Security_icon} />
              </span>
            </div>

            <div className={styles.deleteHeading}>
            <h2>Confirm {action}</h2>
            <p>Are you sure you want to {action} this {action === "delete" ? "chat" : "user"}?</p>
            </div>

            <div className={styles.deleteBtn}>
              <button className={styles.deleteContinue} onClick={handleConfirm}>
                {" "}
                {t("CONTINUE")}
              </button>
              <button className={styles.deleteCancel} onClick={handleClose}>
                {" "}
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
