import loader from "../../assets/Images/loader.gif";
import styles from "./Loader.module.css";

//-----function for loader------
function Loader() {
  return (
    <div className={styles.loader}>
      <img src={loader} alt="loader" />
    </div>
  );
}
export default Loader;
