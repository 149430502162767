import Login from "./Login";
import Signup from "./Signup";
import styles from "./Login.module.css";
import { Container } from "react-bootstrap";
import Loader from "../../utils/Loader/Loader";
import { useState } from "react";

//---------function for Join---------
function Join() {
  return (
    <>
      <div className={styles.mainjoin}>
        <Container>
          <div className={styles.JoinCls}>
            <div className={styles.leftSpaceManage}>
              <Signup />
            </div>

            <span className={styles.JoinBorder}></span>

            <div className={styles.rightSpaceManage}>
              <Login />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
export default Join;
