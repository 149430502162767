import icon1 from "../assets/Images/icon1.svg";
import icon2 from "../assets/Images/icon2.svg";
import icon3 from "../assets/Images/icon3.svg";
import icon4 from "../assets/Images/icon4.svg";
import icon5 from "../assets/Images/icon5.svg";
import icon6 from "../assets/Images/icon6.svg";
import icon7 from "../assets/Images/icon7.svg";

// function for manage diffrent images for categories
const getIconByCategoryType = (categoryType) => {
  const iconMap = {
    1: icon2,
    2: icon1,
    3: icon5,
    4: icon3,
    5: icon4,
    6: icon5,
    7: icon7,
    8: icon6,
  };

  return iconMap[categoryType] || null;
};

export default getIconByCategoryType;
