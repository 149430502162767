import React, { useRef, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import getIconByCategoryType from "../CategoryIcons";
import { formatTime } from "../../utils/manageConstant";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function MapView({
  center,
  markerPosition,
  onMarkerDragEnd,
  mapContainerStyle,
  markers,
  streetViewVisible,
  icon,
  zoom,
}) {
  const mapRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const infoWindowRef = useRef(null);
  const [map, setMap] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
  });
  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    mapRef.current = map;
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const streetView = mapRef.current.getStreetView();
      streetView.setVisible(streetViewVisible);
      if (streetViewVisible) {
        streetView.setPosition(markerPosition || center);
      }
    }
  }, [streetViewVisible, markerPosition, center]);

  // Handle clicks outside the InfoWindow to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target)
      ) {
        setSelectedMarker(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const mapOptions = {
    disableDefaultUI: true, // Disable all default UI controls
    zoomControl: true, // Disable zoom control
    scrollwheel: false, // Disable zoom on scroll
    draggable: false, // Disable panning by dragging
    gestureHandling: "none", // Disable all gesture handling (pinch, swipe, etc.)
  };
  return isLoaded ? (
    <GoogleMap
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={mapContainerStyle}
      options={{ minZoom: 4 }}
    >
      {map &&
        markerPosition &&
        center &&
        window.google &&
        window.google.maps && (
          <Marker
            draggable={false}
            position={markerPosition}
            onDragEnd={onMarkerDragEnd}
            icon={icon}
          />
        )}
      {markers &&
        markers.map(
          (marker, index) =>
            map &&
            center &&
            window.google &&
            window.google.maps && (
              <Marker
                key={index}
                position={{
                  lat: Number(marker.latitude),
                  lng: Number(marker.longitude),
                }}
                icon={getIconByCategoryType(marker.crime_type)}
                onClick={() => setSelectedMarker(marker)}
              />
            )
        )}

      {/* Display country name */}
      {selectedMarker && (
        <InfoWindow
          position={{
            lat: Number(selectedMarker.latitude),
            lng: Number(selectedMarker.longitude),
          }}
          options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
        >
          <div
            className="mapInfo"
            ref={infoWindowRef}
            onClick={() => {
              navigate(`/report/${selectedMarker.reportID}`);
            }}
          >
            <p>{selectedMarker.category_name}</p>
            <span>
              {selectedMarker.date} {t("At")}{" "}
              {selectedMarker.time
                ? formatTime(selectedMarker.time)
                : t("UNKNOWN")}
            </span>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}
