import { Col, Container, Row } from "react-bootstrap";
import { NetworksDashboardBox } from "../../../mockdata";
import { useTranslation } from "react-i18next";
import icon from "../../../assets/Images/Group.svg";
import NetWorksSidebar from "../NetWorksSidebar";
import "../../../assets/Styles/Common.css";

//---function for user dashboard---------
function NetworksDashboard() {
  const { t } = useTranslation();
  return (
    <div className="main">
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <NetWorksSidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("DASHBOARD")}</h2>
              </div>

              <div className="dashboardInfo ">
                <div className="dashboardImg">
                  <img src={icon} alt="img" />
                </div>
                <div className="dashboardText">
                  <p>@manitousecurity</p>
                  <h3>{t("PROFILE_TEXT")}</h3>
                  <h5>
                    <strong>34</strong> {t("REPORTS")} | <strong>12</strong>{" "}
                    {t("FOLLOWERS")} | <strong>23</strong> {t("FOLLOWING")}
                  </h5>
                  <h6>
                    Joined 2019-10-13 15:30:41 - Last Login 2019-07-17 00:59:10
                    - 23 Logins
                  </h6>
                </div>
              </div>

              <div className="dashboardBoxes">
                <Row>
                  {NetworksDashboardBox.map((item, index) => (
                    <Col lg={4} md={4} sm={4} xs={6} key={index}>
                      <div className="dashboardBox">
                        <h1>{item.count}</h1>
                        <p>{item.text}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default NetworksDashboard;
