import { t } from "i18next";
import { FiMapPin } from "react-icons/fi";
import { HiOutlineBell } from "react-icons/hi";
import { FiMenu } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { IoSettingsOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import icon from "./assets/Images/Bitmap.svg";
const currentYear = new Date().getFullYear();

export const HeaderData = [
  {
    id: 1,
    text: `${t("MAP")}`,
    icon: <FiMapPin />,
    link: "/map",
  },
  {
    id: 2,
    text: `${t("TIMELINE")}`,
    icon: <FiMenu />,
    link: "/timeline",
  },
  {
    id: 3,
    text: `${t("ALERT")}`,
    icon: <HiOutlineBell />,
    link: "/alerts",
  },
];

export const footerLinks = [
  {
    id: 1,
    link: "#",
    label: `${t("COPYRIGHT")}`,
    extraText: currentYear,
  },
  { id: 2, link: "/privacy-policy", label: `${t("PRIVACY")}` },
  { id: 3, link: "/terms-conditions", label: `${t("TERMS")}` },
  { id: 4, link: "/contactus", label: `${t("CONTACTUS")}` },
];

// export const SideBar = [
//   {
//     id: 1,
//     text: "Dashboard",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 2,
//     text: "New Report",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 3,
//     text: "Your Report",
//     link: "javascript:",
//     textSide: "2,321",
//   },
//   {
//     id: 4,
//     text: "Messages",
//     link: "javascript:",
//     textSide: "3",
//   },
//   {
//     id: 5,
//     text: "Comments",
//     link: "javascript:",
//     textSide: "67",
//   },
//   {
//     id: 6,
//     text: "Alerts",
//     link: "javascript:",
//     textSide: "1",
//   },
//   {
//     id: 7,
//     text: "Push Notifications",
//     link: "javascript:",
//     textSide: "ON",
//   },
//   {
//     id: 8,
//     text: "Email Notifications",
//     link: "javascript:",
//     textSide: "ON",
//   },
//   {
//     id: 9,
//     text: "Alert Locations",
//     link: "javascript:",
//     textSide: "0",
//   },
//   {
//     id: 10,
//     text: "Your Profile",
//     link: "javascript:",
//     textSide: <CgProfile />,
//   },
//   {
//     id: 11,
//     text: "Login Settings",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 12,
//     text: "Followers",
//     link: "javascript:",
//     textSide: "34",
//   },
//   {
//     id: 13,
//     text: "Following",
//     link: "javascript:",
//     textSide: "1,234",
//   },
//   {
//     id: 14,
//     text: "Networks",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 15,
//     text: "Connect Twitter",
//     link: "javascript:",
//     textSide: "@cadredoom",
//   },
//   {
//     id: 16,
//     text: "Connect FB",
//     link: "javascript:",
//     textSide: "@bedfordviewcpf",
//   },
//   {
//     id: 17,
//     text: "Help Centre",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 18,
//     text: "Terms and Conditions",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 19,
//     text: "Privacy Policy",
//     link: "javascript:",
//     textSide: "",
//   },
//   {
//     id: 20,
//     text: "Log Out",
//     link: "javascript:",
//     textSide: "",
//   },
// ];

export const dashboardBox = [
  {
    id: 1,
    count: "13",
    text: "Reports",
    link: "/your-report",
  },
  {
    id: 2,
    count: "34",
    text: "Alerts",
    link: "/alerts",
  },
  {
    id: 3,
    count: "67",
    text: "Comments",
    link: "/comments",
  },
  {
    id: 4,
    count: "0",
    text: "Alert Location",
    link: "/alert-location",
  },
  {
    id: 5,
    count: <SlUser />,
    text: "Profile",
    link: "/profile",
  },
  {
    id: 6,
    count: <IoSettingsOutline />,
    text: "Settings",
    link: "/login-settings",
  },
];
export const NetworksDashboardBox = [
  {
    id: 1,
    count: "15",
    text: "Reports",
  },
  {
    id: 2,
    count: "34",
    text: "Alerts",
  },
  {
    id: 3,
    count: "67",
    text: "Comments",
  },
  {
    id: 4,
    count: "0",
    text: "Alert Location",
  },
  {
    id: 5,
    count: <SlUser />,
    text: "Profile",
  },
  {
    id: 6,
    count: <IoSettingsOutline />,
    text: "Settings",
  },
];

export const filterProjects = [
  {
    id: "1",
    text: "View by Date ",
    value: "by_date",
  },
  {
    id: "2",
    text: "View by Most Views",
    value: "by_view",
  },
  {
    id: "3",
    text: "View by Most Comments",
    value: "by_comment",
  },
  {
    id: "4",
    text: "View by Most Trends",
    value: "by_trends",
  },
  {
    id: "5",
    text: "View by Most Bookmarks",
    value: "by_bookmark",
  },
];

// export const comments = [
//   {
//     id: "1",
//     text: "There was an incident there a few weeks back. Maybe the same people?",
//     title: "Report #3233 -",
//     date: "2017-08-05 at 20:30 - edit - delete",
//     image: "",
//   },
//   {
//     id: "2",
//     text: `Opposed to using 'Content here, content here', making it
//     look like readable English. Many desktop publishing
//     packages and web page editors now use Lorem Ipsum asnd a
//     search for 'lorem ipsum' will uncover many web sites still
//     in their infancy. Various versions have evolved over the
//     years, sometimes by accident, sometimes on purpose
//     (injected humour and the like).`,
//     title: "Report #3233 -",
//     date: "2017-08-05 at 20:30 - edit - delete",
//     image: "",
//   },
//   {
//     id: "3",
//     text: "There was an incident there a few weeks back. Maybe the same people?",
//     title: "Report #3233 -",
//     date: "2017-08-05 at 20:30 - edit - delete",
//     image: "",
//   },
//   {
//     id: "4",
//     text: `Opposed to using 'Content here, content here', making it
//     look like readable English. Many desktop publishing
//     packages and web page editors now use Lorem Ipsum asnd a
//     search for 'lorem ipsum' will uncover many web sites still
//     in their infancy. Various versions have evolved over the
//     years, sometimes by accident, sometimes on purpose
//     (injected humour and the like).`,
//     title: "Report #3233 -",
//     date: "2017-08-05 at 20:30 - edit - delete",
//     image: { icon },
//   },
// ];
