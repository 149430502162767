import FacebookLogin from "react-facebook-login";
import facebook from "../../assets/Images/social-button-3.svg";
import { socialSignup } from "../../store/slices/Userslice";
import { STATUS_CODES } from "../../utils/statusCode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Toster from "../../utils/Toaster";
import { useTranslation } from "react-i18next";
import { SOCIAL_TYPE } from "../../utils/Constants";

//---------function for facebook social api call----------
function FacebookSocial() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Social Login with facebook.
  const responseFacebook = async (response) => {
    let userData = response;
    if (userData.id) {
      let userProfile = userData.picture ? userData.picture.data.url : "";
      let requestData = new FormData();
      requestData.append("social_id", userData.id ? userData.id.trim() : "");
      requestData.append("social_type", SOCIAL_TYPE.FACEBOOK);
      requestData.append("name", userData.name ? userData.name.trim() : "");
      requestData.append("email", userData.email ? userData.email.trim() : "");
      requestData.append("profile_picture", userProfile);
      requestData.append(
        "device_token",
        localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
          ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
          : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)
      );

      await dispatch(socialSignup(requestData)).then((signresponsejson) => {
        const response = signresponsejson.payload;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          navigate("/dashboard");
        } else {
          Toster(t(errormsg), "error");
        }
      });
    }
  };

  return (
    <div className="SocialLoginBtn">
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton={<img src={facebook} alt="facebook-logo" />}
      />
    </div>
  );
}

export default FacebookSocial;
