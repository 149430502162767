import { Button } from "react-bootstrap";
import styles from "./CommonButton.module.css";

//--------Common button for header----------
function HeaderButton({ onClick, label, type }) {
  return (
    <div className={styles.headerButton}>
      <Button onClick={onClick} type={type}>
        {label}
      </Button>
    </div>
  );
}

export default HeaderButton;
