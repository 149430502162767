import { Form } from "react-bootstrap";

//--------Common on/off switch for user module----------
function UserSwitch({ id, checked, onChange, disabled }) {
  return (
    <Form.Check
      type="switch"
      id={id}
      className="toggle_switch"
      checked={checked}
      onChange={onChange}
      // disabled={disabled}
    />
  );
}

export default UserSwitch;
