import axios from "axios";
import { DEVICE_TYPE } from "../utils/Constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_ID, uint32.toString(32));
}

// Function to get the timezone offset in GMT±HH:MM format
const getTimezoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset <= 0 ? "+" : "-";
  return `GMT${sign}${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
};

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    "Device-Type": DEVICE_TYPE.ONE,
    "Device-Id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    "Device-Token": localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
      : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    Timezone: "Asia/Kolkata",
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    // const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const headers = { ...SublyApi.commonHeaders, ...header };
    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  // catch (err) {
  //   if (err.response && err.response.status === 400) {
  //     console.log(err.response,"err.response");

  //     console.error("Session expired. Please log in again.");
  //     // Handle session expiration here, e.g., redirect to login page
  //     // window.location.href = "/login";
  //   }
  //   return err.response;
  // }

  /* ------LOGIN API -----*/
  static async login(data) {
    let res = await this.request(`/auth/login`, data, "post");
    return res;
  }

  /* ------SEND VERIFICATION CODE API -----*/
  static async sendCode(data) {
    let res = await this.request(`/auth/send-verification-code`, data, "post");
    return res;
  }

  /* ------SIGNUP VERIFY CODE API -----*/
  static async varifySignupCode(data) {
    let res = await this.request(`/auth/signup-verify-code`, data, "post");
    return res;
  }

  /* ------SOCIAL SIGNUP API -----*/
  static async socialSignup(data) {
    let res = await this.request(`/auth/social-user-signup`, data, "post");
    return res;
  }

  /* ------RESET PASSWORD API -----*/
  static async resetPassword(data) {
    let res = await this.request(`/auth/reset-password`, data, "post");
    return res;
  }

  /* ------LOGOUT API -----*/
  static async logout(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/auth/logout`, "", "get", header);
    return res;
  }

  /* ------USER REPORT API -----*/
  static async getUserReport(authToken, limit, offset) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/user/report?limit=${limit}&offset=${offset}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------CREATE NEW REPORT API -----*/
  static async addNewReport(data, authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/report`, data, "post", header);
    return res;
  }

  /* ------CREATE NEW REPORT API -----*/
  static async crimeCategoryList(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/general/list`, "", "get", header);
    return res;
  }

  /* ------ADD IMAGE REPORT API -----*/
  static async addReportImage(data, authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/report/attachment`, data, "post", header);
    return res;
  }
  /* ------DELETE REPORT API -----*/
  static async DeleteUserReport(authToken, id) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/report/${id}`, "", "delete", header);
    return res;
  }

  /* ------REPORT DETAILS API -----*/
  static async reportDetails(authToken, id) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/report/${id}`, "", "get", header);
    return res;
  }

  /* ------DELETE REPORT ATACHMENT API -----*/
  static async DeleteReportImage(authToken, id) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `report/attachment/${id}`,
      "",
      "delete",
      header
    );
    return res;
  }
  /* ------SEARCH REPORT API -----*/
  static async reportSearchList(
    authToken,
    limit,
    offset,
    crime_only,
    suspicious,
    people,
    my_reports,
    bookmark,
    missing,
    wanted,
    search_term
  ) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/report/search?limit=${limit}&offset=${offset}&crime_only=${crime_only}&suspicious=${suspicious}&people=${people}&my_reports=${my_reports}&bookmark=${bookmark}&missing=${missing}&wanted=${wanted}&search_term=${search_term}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------UPDATE NEW REPORT API -----*/
  static async UpdateNewReport(data, authToken, id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/report/${id}`, data, "put", header);
    return res;
  }

  /* ------REPORT MAP API -----*/
  static async getReportMapList(
    authToken,
    latitude,
    longitude,
    date_from,
    duration,
    date_to,
    categories,
    flag = ""
  ) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/report/map/search?latitude=${latitude}&longitude=${longitude}&date_from=${date_from}&duration=${duration}&date_to=${date_to}&categories=${categories}&flag=${flag}`,
      "",
      "get",
      header
    );
    return res;
  }
  // -----BOOKMARK--API----
  static async UpdateBookmark(authToken, id, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/report/${id}/bookmark`,
      data,
      "post",
      header
    );
    return res;
  }

  // -----TRENDMARK--API----
  static async UpdateTrend(authToken, id, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/report/${id}/trend`, data, "post", header);
    return res;
  }

  /* ------FOLLOW & UNFOLLOW-----*/
  static async followingUpdate(authToken, id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/user/${id}/following`, {}, "put", header);
    return res;
  }

  /* ------GET PUBLICPROFILE-----*/
  static async PublicProfile(authToken, user_id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/public-profile?user_id=${user_id}`,
      "",
      "get",
      header
    );
    return res;
  }

  // report details comment get
  static async getComment(id, limit, offset) {
    let res = await this.request(
      `/report/${id}/comment?limit=${limit}&offset=${offset}`,
      "",
      "get"
    );
    return res;
  }

  // report details comment post
  static async PostComment(authToken, id, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/report/${id}/comment`, data, "post", header);
    return res;
  }

  // report details flag
  static async PostFlag(authToken, id, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/report/${id}/flag`, data, "post", header);
    return res;
  }

  /* ------GET TIMELINE-----*/
  static async getTimeline(
    authToken,
    limit,
    offset,
    latitude,
    longitude,
    date_from,
    date_to,
    categories,
    location = ""
  ) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/timeline?limit=${limit}&offset=${offset}&latitude=${latitude}&longitude=${longitude}&location=${location}&date_from=${date_from}&date_to=${date_to}&categories=${categories}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------GET TIMELINE-----*/
  static async getTimelineGuest(
    limit,
    offset,
    latitude,
    longitude,
    date_from,
    date_to,
    categories,
    location = ""
  ) {
    let res = await this.request(
      `/guest_timeline?limit=${limit}&offset=${offset}&latitude=${latitude}&longitude=${longitude}&location=${location}&date_from=${date_from}&date_to=${date_to}&categories=${categories}`,
      "",
      "get"
    );
    return res;
  }

  /* ------CONTENT API-----*/
  static async contentType(type) {
    let res = await this.request(`/general/content?type=${type}`, "", "get");
    return res;
  }

  // alert  get
  static async getAlerts(authToken, limit, offset) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/alerts?limit=${limit}&offset=${offset}`,
      "",
      "get",
      header
    );
    return res;
  }
  /* ------NEW ACCOUNT LOGIN API -----*/
  static async UpdateLoginData(data, authToken, type) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/login-settings?type=${type}`,
      data,
      "put",
      header
    );
    return res;
  }

  // verify account api
  static async verifyAccount(data, authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/change-verify-code`,
      data,
      "post",
      header
    );
    return res;
  }

  // get getfollowers
  static async getfollowers(authToken, id, limit, offset, search) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/${id}/followers?limit=${limit}&offset=${offset}&search=${search}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------GET FOLLOWING-----*/
  static async getFollowing(authToken, user_id, limit, offset, search) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/${user_id}/following?limit=${limit}&offset=${offset}&search=${search}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------your profile API -----*/
  static async yourProfile(data, authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/user/profile`, data, "put", header);
    return res;
  }

  /* ------User detail API-----*/
  static async userProfileData(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/user/details`, "", "get", header);
    return res;
  }

  static async UpdateProfilePic(data, authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/user/update-avatar`, data, "post", header);
    return res;
  }

  /* ------GET ALERT LOCATION-----*/
  static async getAlertLoction(authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/alert/locations`, "", "get", header);
    return res;
  }

  /* ------DELETE ALERT LOCATION-----*/
  static async DeleteAlertLoction(authToken, id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/alert/location/${id}`, "", "delete", header);
    return res;
  }

  /* ------PUT ALERT LOCATION-----*/
  static async UpdateAlertLoction(data, authToken, id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/alert/location/${id}`, data, "put", header);
    return res;
  }

  /* ------PUT ALERT LOCATION-----*/
  static async AddAlertLoction(data, authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/alert/location`, data, "put", header);
    return res;
  }

  /* ------HASHTAG API-----*/
  static async hashtagSuggestion(authToken, tag) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`/tag?tag=${tag}`, "", "get", header);
    return res;
  }

  /* ------BOOKMARK TOOLTIP API-----*/
  static async BookmarkTooltip(authToken, id, limit, offset) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `user/${id}/report/bookmark?limit=${limit}&offset=${offset}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------RECENTLY VIEWED TOOLTIP API-----*/
  static async RecentlyViewedTooltip(authToken, id, limit, offset) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/user/${id}/report/recently-viewed?limit=${limit}&offset=${offset}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------MISSING PERSON TOOLTIP API-----*/
  static async MissingPersionTooltip(
    authToken,
    limit,
    offset,
    ethnicity,
    gender,
    age_to,
    type
  ) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/report/person?limit=${limit}&offset=${offset}&ethnicity=${ethnicity}&gender=${gender}&age_to=${age_to}&type=${type}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------USERNAME AVAILABLE CHCEK API-----*/
  static async checkUserName(authToken, term) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/username-available?term=${term}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------COMMENT API-----*/
  static async commentList(authToken, limit, offset, search) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `user/comment-list?limit=${limit}&offset=${offset}&search=${search}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------EMAIL NOTIFICATION API----*/
  static async emailNotification(authToken, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`alert/status`, data, "patch", header);
    return res;
  }

  static async pushNotificationStatus(authToken, data, id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `alert/push-setting/${id}`,
      data,
      "put",
      header
    );
    return res;
  }

  /* ------GET OTHER USER REPORT-----*/
  static async getOtherReport(authToken, id, limit, offset) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/${id}/reports?limit=${limit}&offset=${offset}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------DELETE COMMENT API -----*/
  static async DeleteComment(authToken, comment_id) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `user/delete-comment/${comment_id}`,
      "",
      "delete",
      header
    );
    return res;
  }

  /* ------CLEAR ALERT LOCATION-----*/
  static async ClearAlerts(authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(`/alert/clear`, "", "delete", header);
    return res;
  }

  /* ------UPDATE COMMENT API-----*/
  static async UpdateComment(data, authToken, comment_id) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `user/update-comment/${comment_id}`,
      data,
      "post",
      header
    );
    return res;
  }

  // term and condition
  static async AcceptTerms(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`user/terms-accept`, "", "patch", header);
    return res;
  }

  /* ------CONNECT--TWITTER--API-----*/
  static async ConnectTwitter(authToken, data) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/social/twitter-connect`,
      data,
      "post",
      header
    );
    return res;
  }

  static async DisConnectTwitter(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/social/twitter-disconnect`,
      "",
      "delete",
      header
    );
    return res;
  }

  static async ConnectTwitterAuth(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(
      `/social/twitter-authorize-url`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------INVITE FRIENDS-----*/
  static async inviteFriends(authToken, data) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`user/invite-friend`, data, "post", header);
    return res;
  }

  /* ------DELETE ACCOUNT API -----*/
  static async deleteAccount(authToken) {
    let header = { Authorization: `Bearer ${authToken}` };
    let res = await this.request(`user/delete`, "", "delete", header);
    return res;
  }

  // /* ------REPORT DETAILS API -----*/
  static async reportDetailsPublic(id) {
    let res = await this.request(`/report_details/${id}`, "", "get");
    return res;
  }

  // firebase chat api
  static async firebaseChatNotification(data, authToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/chat-notification`,
      data,
      "post",
      header
    );
    return res;
  }

  // firebase toke update api
  static async firebaseTokenUpdate(authToken, firebaseToken) {
    let header = {
      Authorization: `Bearer ${authToken}`,
      "Device-Token": firebaseToken,
    };
    let res = await this.request(`/auth/update-token`, {}, "put", header);
    return res;
  }

  /* ------PUT ALERT LOCATION-----*/
  static async emailAlertUpdate(data) {
    let res = await this.request(`/alert/update_email_alert`, data, "put");
    return res;
  }

  /* ------GET CURRENT LOCATION API----*/
  static async currentLocation(authToken, data) {
    let header = {
      Authorization: `Bearer ${authToken}`,
    };
    let res = await this.request(
      `/user/current-location`,
      data,
      "patch",
      header
    );
    return res;
  }
}

export default SublyApi;
