import { useEffect, useState } from "react";
import getIconByCategoryType from "../../../commonComponent/CategoryIcons";
import styles from "./NewReport.module.css";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";

function CategoryModal({
  setShow,
  crimesCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  setActiveKey,
  activeKey,
}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setting of selected subcategory based on initial screen width

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth, crimesCategory]);

  // Function to handle sub-category selection
  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory({
      id: subCategory.categoryId,
      name: subCategory.category_name,
      field_type: subCategory.field_type,
      parent_category_id: subCategory.parent_category_id,
    });
    setActiveKey(subCategory.parent_category_id);
    setShow(false);
  };

  const getCategoryRowSpan = (subCategoryLength) => {
    return Math.ceil((subCategoryLength + 1) / 4);
  };

  return (
    <>
      <div className={`${styles.showOptions} ${styles.categoryResponse}`}>
        <div className={styles.gridContainer}>
          {crimesCategory.categories &&
            crimesCategory.categories.map((category) => {
              const rowSpan = getCategoryRowSpan(category.sub_category.length);
              return (
                <div
                  key={category.category_id}
                  className={styles.categoryBox}
                  style={{ gridRowEnd: `span ${rowSpan}` }}
                >
                  <div className={styles.mainCategory}>
                    <img
                      src={getIconByCategoryType(category.crime_type)}
                      alt="icon"
                    />
                    <h5>{category.category_name}</h5>
                  </div>
                  {category.sub_category.map((subCategory) => (
                    <div
                      key={subCategory.categoryId}
                      className={`${styles.subCategory} ${
                        selectedSubCategory &&
                        selectedSubCategory.id === subCategory.categoryId
                          ? styles.selected
                          : ""
                      }`}
                      onClick={() => handleSubCategorySelect(subCategory)}
                    >
                      <div className={styles.displayOptionss}>
                        <p>{subCategory.category_name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.tabContent}>
        <Tab.Container
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
        >
          <Row>
            <Col sm={5} xs={6}>
              <Nav variant="pills" className="flex-column tabsAdd">
                {crimesCategory.categories &&
                  crimesCategory.categories.length > 0 &&
                  crimesCategory.categories.map((category) => (
                    <Nav.Item key={category.parentCategoryId}>
                      <Nav.Link
                        className={styles.tabNavlink}
                        eventKey={category.parentCategoryId}
                      >
                        <div className={styles.tabText}>
                          <>
                            <img
                              src={getIconByCategoryType(category.crime_type)}
                              alt="icon"
                            />
                            {category.category_name}
                          </>
                          <MdArrowForwardIos className="MdArrowForwardIos" />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            </Col>
            <Col sm={7} xs={6} className={styles.tabReportContent}>
              <Tab.Content>
                {crimesCategory.categories &&
                  crimesCategory.categories.length > 0 &&
                  crimesCategory.categories.map((category) => (
                    <Tab.Pane
                      className={styles.tabReport}
                      key={category.parentCategoryId}
                      eventKey={category.parentCategoryId}
                    >
                      {category.sub_category.map((sub) => (
                        <p
                          onClick={() => handleSubCategorySelect(sub)}
                          key={sub.categoryId}
                        >
                          {sub.category_name}
                        </p>
                      ))}
                    </Tab.Pane>
                  ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}
export default CategoryModal;
