import { Button } from "react-bootstrap";
import styles from "./CommonButton.module.css";

//--------Common button for auth module----------
function AuthButton({ onClick, label, type }) {
  return (
    <div className={styles.loginBtn}>
      <Button onClick={onClick} type={type}>
        {label}
      </Button>
    </div>
  );
}

export default AuthButton;
