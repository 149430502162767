import { Col, Container, Form, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./Comments.module.css";
import { useTranslation } from "react-i18next";
import UserSearchModule from "../../../commonComponent/UserSearchInput";
import CrudIcon from "../../../commonComponent/CrudIcon";
import { useEffect, useState, useRef } from "react";
import TextArea from "../../../commonComponent/TextAreaField";
import {
  b64toBlob,
  getRandom,
  handleKeyPress,
} from "../../../utils/manageConstant";
import { useForm } from "react-hook-form";
import { FaCirclePlus } from "react-icons/fa6";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import SublyApi from "../../../helpers/Api";
import { isLimit, offset } from "../../../utils/Constants";
import Toster from "../../../utils/Toaster";
import { STATUS_CODES } from "../../../utils/statusCode";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader/Loader";
import { ImCross } from "react-icons/im";
import ReactPlayer from "react-player";
import CropImg from "../YourProfile/CropImg";
import mime from "mime";
import { Link, useNavigate } from "react-router-dom";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import DeletePopup from "../YourReport/DeletePopup";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";

//---function for user comments---------
function Comments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editCommentIds, setEditCommentIds] = useState([]);
  const [deleteCommentIds, setDeleteCommentIds] = useState([]);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);

  const { userToken, userData } = useSelector((state) => state.user);
  const [showDataList, setShowDataList] = useState([]);
  const [page, setPage] = useState(1);
  const fileInputRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentCommentID, setCurrentCommentID] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoThumb, setVideoThumb] = useState(null);
  const [isRemove, setIsRemove] = useState(0);
  const [reportid, setReportId] = useState();
  const [totalReports, setTotalReports] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [showCrop, setShowCrop] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const DeleteHandleShow = () => setDeleteShow(true);
  const DeleteHandleClose = () => setDeleteShow(false);

  useEffect(() => {
    if (editCommentIds.includes(currentCommentID)) {
      const currentComment = showDataList.find(
        (item) => item.commentID === currentCommentID
      );
      if (currentComment) {
        setValue("description", currentComment.comment);
        if (currentComment.attachment_url) {
          if (currentComment.video_thumb_url) {
            setSelectedVideo({
              url: currentComment.attachment_url,
              type: "video",
            });
            setPreviewImage("");
          } else {
            setPreviewImage({
              url: currentComment.attachment_url,
              type: "image",
            });
            setSelectedVideo("");
          }
        }
      }
    }
  }, [editCommentIds, currentCommentID, setValue, showDataList]);

  const handleCropComplete = () => {
    setShowCrop(false);
    setIsRemove(1);
  };

  const IMAGE_SIZE_MB = 10;
  const VIDEO_SIZE_MB = 200;
  const MIN_IMAGE_WIDTH = 150;
  const MIN_IMAGE_HEIGHT = 150;
  const MAX_IMAGE_WIDTH = 4000;
  const MAX_IMAGE_HEIGHT = 4000;
  const VALID_VIDEO_EXTENSIONS = ["mp4", "MP4", "3gp", "3GP"];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const fileType = file.type.startsWith("video") ? "video" : "image";

    const fileData = {
      url: URL.createObjectURL(file),
      type: fileType,
      file: file,
    };

    if (fileType === "image") {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const { width, height } = img;

        if (width < MIN_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (width > MAX_IMAGE_WIDTH) {
          Toster(t("IMAGE_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (height < MIN_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (height > MAX_IMAGE_HEIGHT) {
          Toster(t("IMAGE_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check image file size
        if (file.size > IMAGE_SIZE_MB * 1024 * 1024) {
          Toster(t("IMAGE_SIZE_MUST_BE_10MB"), "error");
          return;
        }
        setIsCropper(true);
        setPreviewImage(fileData.url);
        setImageSrc(fileData.url);
        setSelectedVideo("");
        setVideoThumb("");
        setIsRemove(0);
      };
    } else if (fileType === "video") {
      const fileExtension = file.name.split(".").pop();
      if (!VALID_VIDEO_EXTENSIONS.includes(fileExtension)) {
        Toster(t("INVALID_VIDEO_FORMAT_MP4_3GP_ONLY"), "error");
        return;
      }
      const videoElement = document.createElement("video");
      videoElement.src = fileData.url;

      videoElement.onloadedmetadata = async () => {
        // Check video dimensions
        if (videoElement.videoWidth === 0 || videoElement.videoHeight === 0) {
          Toster(t("This video not supportive"), "error");
          return; // Handle the error gracefully
        }

        if (videoElement.videoWidth < MIN_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoWidth > MAX_IMAGE_WIDTH) {
          Toster(t("VIDEO_WIDTH_MUST_BE_4000px"), "error");
          return;
        }
        if (videoElement.videoHeight < MIN_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_150px"), "error");
          return;
        }
        if (videoElement.videoHeight > MAX_IMAGE_HEIGHT) {
          Toster(t("VIDEO_HEIGHT_MUST_BE_4000px"), "error");
          return;
        }

        // Check video file size
        if (file.size > VIDEO_SIZE_MB * 1024 * 1024) {
          Toster(t("VIDEO_SIZE_MUST_BE_200MB"), "error");
          return;
        }

        const videoThumbRes = await handleExtractFrame(fileData?.file);
        setSelectedVideo(fileData);
        setPreviewImage("");
        setImageSrc("");
        setIsCropper(false);
        setIsRemove(0);
      };
    }
  };

  useEffect(() => {
    if (isCropper) {
      setShowCrop(true);
    }
  }, [isCropper]);

  const handleClose = () => {
    setShowCrop(false);

    if (previewImage) {
      setIsRemove(1);
    } else {
      setIsRemove(0);
    }
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setIsRemove(1);
    setIsCropper(false);
  };

  const handleVideoRemove = () => {
    setSelectedVideo(null);
    setIsRemove(1);
    setIsCropper(false);
  };

  const handleShowEdit = (commentId) => {
    if (!editCommentIds.includes(commentId)) {
      setEditCommentIds((prevIds) => [commentId]);
      setCurrentCommentID(commentId);
    }
  };

  const handleShowDelete = (commentId) => {
    if (!deleteCommentIds.includes(commentId)) {
      setDeleteCommentIds((prevIds) => [...prevIds, commentId]);
    }
  };
  const handleHideEdit = (commentId) => {
    setEditCommentIds((prevIds) => prevIds.filter((id) => id !== commentId));
    setPreviewImage(null);
    setSelectedVideo(null);
    setIsRemove(0);
    setShowCrop(false);
    setIsCropper(false);
  };

  const handleHideDelete = (commentId) => {
    setDeleteCommentIds((prevIds) => prevIds.filter((id) => id !== commentId));
    setIsRemove(0);
    setShowCrop(false);
    setIsCropper(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    commentShow(page + 1);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} at ${hours}:${minutes}`;
  };

  async function commentShow(page = 1) {
    setLoader(true);
    await SublyApi.commentList(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit,
      search
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response?.data?.commentList;
        setTotalReports(response?.data?.total_comments);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setShowDataList(sortedReports);
          } else {
            setShowDataList((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setShowDataList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    commentShow();
  }, [search]);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  async function handleCommentDelete(id) {
    setLoader(true);
    await SublyApi.DeleteComment(userToken, id).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      const msg = response && response.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        commentShow();
        setIsRemove(0);

        const count = showDataList?.length > 0 ? showDataList?.length - 1 : 0;

        dispatch(
          updateSettingCount({
            keyName: "total_comments",
            value: count,
          })
        );
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const handleExtractFrame = (videoFile) => {
    if (!videoFile) return;

    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(videoFile);
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true; // Mute the video for autoplay
    videoElement.play(); // Start playing the video to ensure metadata is loaded
    videoElement.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");
      // Ensure the video is loaded and can be drawn
      videoElement.currentTime = 0; // Set to the first frame

      videoElement.onseeked = () => {
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(async (blob) => {
          // Send the image blob to the API
          const requestData = new FormData();
          requestData.append("attachment_thumb", blob, "frame.jpg");
          setVideoThumb(requestData.get("attachment_thumb"));
          return requestData.get("attachment_thumb");
        }, "image/jpeg");
      };
    };
  };

  //----------function for update comment api calling----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append("comment", formdata ? formdata.description : "");
    requestData.append("media_duration", "");
    requestData.append("flag", isRemove);

    if (isCropper) {
      const blockCrop = previewImage && previewImage.split(";");
      const cropContentTypes = blockCrop[0]?.split(":")[1];
      const blockRealData = blockCrop[1]?.split(",")[1];
      const cropBlobImg = b64toBlob(blockRealData, cropContentTypes);

      requestData.append(
        "attachment_file",
        cropBlobImg,
        `${getRandom()}.${mime.getExtension(cropBlobImg.type)}`
      );
    } else if (selectedVideo) {
      // Append video file
      requestData.append("video_thumb", videoThumb);
      requestData.append("attachment_file", selectedVideo.file);
    }

    if (!requestData.get("comment") && !requestData.get("attachment_file")) {
      Toster(
        "We need upload image or video either comment is required",
        "error"
      );
      return;
    }
    // return false;
    setLoader(true);
    await SublyApi.UpdateComment(requestData, userToken, currentCommentID).then(
      (response) => {
        setLoader(false);
        const msg = response && response.message;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          Toster(t(msg), "success");
          commentShow();
          handleHideEdit(currentCommentID);
          setShowCrop(false);
          setIsCropper(false);
          setSelectedVideo("");
          setVideoThumb("");
          setPreviewImage("");
          setIsRemove(0);
        } else if (
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "ACCOUNT_DISABLED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "SESSION_EXPIRED") ||
          (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
            response?.data?.error_type === "INVALID_TOKEN")
        ) {
          dispatch(userLogoutClear());
          navigate("/");
          Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  };

  const onToggleDelete = (id) => {
    DeleteHandleShow();
    setReportId(id);
  };

  const handleEdit = (item) => {
    navigate("/new-report", {
      state: { id: item.report_id, isUpdate: true },
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>{t("COMMENTS")}</h2>
                <UserSearchModule hadleSearch={handleSearch} value={search} />
              </div>
              <div className={styles.commentBox}>
                {showDataList && showDataList.length > 0 ? (
                  showDataList.map((item, index) => (
                    <div key={item.index}>
                      <div className={styles.commentInfo}>
                        <div className={styles.commentText}>
                          <h6>{item.comment}</h6>
                          {item.attachment_thumb && (
                            <div className={styles.commentImg}>
                              <LightGallery
                                speed={500}
                                closable={true}
                                hideBarsDelay={0}
                                controls={false}
                                download={false}
                                counter={false}
                              >
                                <a href={item.attachment_url}>
                                  <img
                                    src={item.attachment_url}
                                    width="100"
                                    height="100"
                                    style={{ cursor: "pointer" }}
                                  />
                                </a>
                              </LightGallery>
                            </div>
                          )}
                          {item.video_thumb_url && (
                            <ReactPlayer
                              url={item.attachment_url}
                              controls
                              className={styles.reactPlayerAdd}
                            />
                          )}

                          <p>
                            {item.commentID && (
                              <>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/report/${item.report_id}`}
                                >
                                  <strong>
                                    {t("REPORTEXT")} #{item.report_id}{" "}
                                  </strong>
                                </Link>
                                &nbsp; -&nbsp;
                              </>
                            )}
                            {formatDate(item.created_at)}
                            {userData?.userID === item?.reported_by_id ? (
                              <>
                                &nbsp; - &nbsp;
                                <span
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("EDIT")}
                                </span>{" "}
                                -
                                <span
                                  onClick={() => {
                                    onToggleDelete(item.report_id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("DELETE")}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                        {/* common crud icon */}
                        <div className={styles.responseIcon}>
                          <CrudIcon
                            commentId={item.commentID}
                            onToggleEdit={() => {
                              handleShowEdit(item.commentID);
                              setCurrentCommentID(item.commentID);
                            }}
                            onToggleDelete={() =>
                              handleShowDelete(item.commentID)
                            }
                          />
                        </div>
                      </div>
                      {deleteCommentIds.includes(item.commentID) && (
                        <div className={styles.commentInfoDelete}>
                          <div className={styles.commentButton}>
                            <UserCommonButton
                              type="button"
                              onClick={() => handleHideDelete(item.commentID)}
                              label={t("CANCEL")}
                            />
                            <p>{t("CONFIRM_DELETE")}</p>
                            <div className={styles.rightButton}>
                              <UserCommonButton
                                type="submit"
                                label={t("SUBMIT")}
                                onClick={() =>
                                  handleCommentDelete(item.commentID)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        {editCommentIds.includes(item.commentID) && (
                          <div className={styles.commentInfoEdit}>
                            <div className="formCls">
                              <TextArea
                                name="description"
                                register={register}
                                placeholder={"Please enter your comment"}
                                onInputChange={(e) =>
                                  handleKeyPress(e, setValue)
                                }
                              />
                            </div>
                            <div className="selectimageshow">
                              {isCropper == false ? (
                                <>
                                  {previewImage && (
                                    <div className="showSelectedImgs">
                                      <div className="showSelectedImgInners">
                                        {previewImage.type === "image" && (
                                          <LightGallery
                                            speed={500}
                                            closable={true}
                                            hideBarsDelay={0}
                                            controls={false}
                                            download={false}
                                            counter={false}
                                          >
                                            <a href={previewImage.url}>
                                              <img
                                                src={previewImage.url}
                                                width="100"
                                                height="100"
                                                style={{ cursor: "pointer" }}
                                              />
                                            </a>
                                          </LightGallery>
                                        )}
                                        <div className="imageCrossIcons">
                                          <ImCross
                                            onClick={handleRemoveImage}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {previewImage && (
                                    <div className="showSelectedImgs">
                                      <div className="showSelectedImgInners">
                                        <img src={previewImage} alt="Preview" />

                                        <div className="imageCrossIcons">
                                          <ImCross
                                            onClick={handleRemoveImage}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            <div className={styles.vidioUpload}>
                              {selectedVideo && (
                                <div className={styles.showImageBox}>
                                  <ReactPlayer
                                    url={selectedVideo.url}
                                    controls={true}
                                    className={styles.reactPlayer}
                                  />
                                  <span className={styles.imageCrossIcon}>
                                    <ImCross onClick={handleVideoRemove} />
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className={styles.commentButton}>
                              <div className={styles.commentButton}>
                                <UserCommonButton
                                  type="button"
                                  onClick={() => handleHideEdit(item.commentID)}
                                  label={t("CANCEL")}
                                />
                                <div className="commentsInputLeft">
                                  <FaCirclePlus
                                    onClick={() => fileInputRef.current.click()}
                                  />
                                  <input
                                    type="file"
                                    id="file"
                                    accept="image/*,video/*"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </div>
                              </div>
                              <div className={styles.rightButton}>
                                <UserCommonButton
                                  type="submit"
                                  label={t("SUBMIT")}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                  ))
                ) : (
                  <div className="records_Found">
                    <h2>{t("NO_REC_FOUND")}</h2>
                  </div>
                )}
              </div>

              {showDataList?.length < totalReports ? (
                <div className="loadMoreSection seprateLine">
                  <button className="loadMoreBtn" onClick={loadMoreReports}>
                    {t("LOAD_MORE")}
                  </button>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <CropImg
        handleClose={handleClose}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewImage}
        aspectRatio={1}
        setIsCropper={setIsCropper}
        setImageSrc={setImageSrc}
        onImageChange={handleCropComplete}
      />

      <DeletePopup
        deleteShow={deleteShow}
        DeleteHandleClose={DeleteHandleClose}
        reportid={reportid}
        setShowDataList={setShowDataList}
        userReport={commentShow}
        showDataList={showDataList}
      />
    </div>
  );
}
export default Comments;
