import Slider from "@mui/material/Slider";
import { useTranslation } from "react-i18next";

//--------Common distance slider for user module----------
function DistanceCommonSlider({ min, max, value, onChange, valueLabelFormat }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="sliderText">
        <p>
          {min}
          {t("KM")}
        </p>
        <p>
          {max}
          {t("KM")}
        </p>
      </div>
      <div className="SliderSetMuif">
        <Slider
          aria-label="Default"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          valueLabelDisplay="on"
          valueLabelFormat={valueLabelFormat}
          className="custom-slider"
        />
      </div>
    </div>
  );
}

export default DistanceCommonSlider;
