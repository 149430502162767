import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ImCross } from "react-icons/im";
import styles from "./Content.module.css";
import SublyApi from "../../helpers/Api";
import { useEffect, useState } from "react";
import { STATUS_CODES } from "../../utils/statusCode";
import Toster from "../../utils/Toaster";
import { CONTENT_TYPE } from "../../utils/Constants";
import { TextFormate } from "../../utils/removeExtraPlusSigns";

//---function for terms and condition---------
function TermsModal({ termsModal, setTermsModal }) {
  const { t } = useTranslation();
  const [termsData, setTermsData] = useState();

  // function for get terms and condition api calling
  async function termsCondition() {
    await SublyApi.contentType(CONTENT_TYPE.TERMS_CONDITION).then(
      (response) => {
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          setTermsData(
            response &&
              response.data &&
              response.data.content &&
              response.data.content.terms
          );
        } else {
          Toster(t(errormsg), "error");
        }
      }
    );
  }

  useEffect(() => {
    termsCondition();
  }, []);

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={termsModal}
        onHide={() => {
          setTermsModal(false);
        }}
        className="modalCls termsModalCls"
        backdrop="static"
      >
        <Modal.Header>
          <div className={styles.headerContent}>
            <h4>{t("TERMS")}</h4>
            <ImCross
              onClick={() => {
                setTermsModal(false);
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <p
            className={styles.paraCondition}
            dangerouslySetInnerHTML={{
              __html: TextFormate(termsData),
            }}
          ></p>
        </Modal.Body>
        <Modal.Footer className={styles.termsFooter}>
          <Button
            onClick={() => {
              setTermsModal(false);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default TermsModal;
