import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validateNumber } from "../utils/Validation";

function AuthInput({
  label,
  name,
  type,
  register,
  errors,
  onInputChange,
  validationRules,
  validate,
  control,
  phoneInputProps,
  placeholder,
  value,
}) {
  const { t } = useTranslation();

  const [phonePlaceholder, setPhonePlaceholder] = useState(
    `+${phoneInputProps?.dialCode || ""} XXX-XXX-XXXX`
  );

  const updatePlaceholder = (dialCode) => {
    const placeholder = `+${dialCode} XXX-XXX-XXXX`;
    setPhonePlaceholder(placeholder);
  };

  const renderInput = () => {
    if (type === "phone" || type === "number") {
      return (
        <>
          <Form.Group
            className="mb-3 formGroup phoneInputSet"
            style={{
              borderColor: errors && errors.phoneNumber ? "red" : "",
            }}
          >
            <Form.Label>{label}</Form.Label>
            <Controller
              control={control}
              placeholder={placeholder}
              name={name}
              defaultValue={phoneInputProps.phoneNo}
              render={({ field }) => (
                <PhoneInput
                  {...phoneInputProps}
                  value={
                    field.value ||
                    phoneInputProps.dialCode + phoneInputProps.phoneNo
                  }
                  // value={field.value}
                  onChange={(value, country) => {
                    const dialCode = country.dialCode;
                    const phone = value.slice(dialCode.length);
                    phoneInputProps.setCountryCode(country.countryCode);
                    phoneInputProps.setDialCode(dialCode);
                    phoneInputProps.setPhoneNo(phone);
                    field.onChange(value);
                    phoneInputProps.clearErrors(name);
                    updatePlaceholder(dialCode);
                    validate(phone);
                  }}
                  placeholder={t("Enter phone number")}
                />
              )}
              // rules={{ validate }}
              rules={{
                validate: (value) =>
                  validateNumber(value.slice(phoneInputProps.dialCode.length)),
              }}
            />
          </Form.Group>
          {errors[name] && (
            <div className="errorBox">
              <span className="errorMsg">{errors[name].message}</span>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <Form.Group
          className="mb-3 formGroup"
          style={{
            borderColor: errors && errors[name] ? "red" : "",
          }}
        >
          <Form.Label>{t(label)}</Form.Label>
          <Form.Control
            type={type}
            value={value}
            placeholder={placeholder}
            {...register(name, {
              onChange: (e) => onInputChange(e),
              ...validationRules,
              validate,
            })}
          />
        </Form.Group>
        {errors[name] && (
          <div className="errorBox">
            <span className="errorMsg">{errors[name].message}</span>
          </div>
        )}
      </>
    );
  };

  return <>{renderInput()}</>;
}

export default AuthInput;
