import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import styles from "./ReportPopup.module.css";
import securityIcon from "../../../assets/Images/conform_security_icon.png";

const ReportSubmitPopup = ({
  reportShow,
  setReportShow,
  handleSubmitReport,
  disable,
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    setReportShow(false);
  };

  return (
    <Modal className="mainModule" show={reportShow} onHide={() => onClose()}>
      <div className="MainBox">
        <div className="header">
          <span className="alertCross">
            <RxCross2 onClick={() => onClose()} />
          </span>
        </div>
        <div className="hadingMainDiv">
          <div className={styles.moduleTopIcon}>
            <span>
              <img src={securityIcon} />
            </span>
          </div>
          <div className={styles.conformHedingInner}>
            <h2>
              {t("OFFICIAL_REPORT")} <br />
              <span>
                {t("REPORT_THIS")}
                <br />
                {t("IMMEDIATE")}
              </span>
            </h2>
          </div>
          <div className={styles.conformHedingInnerTow}>
            <h2>
              {t("NEARBY_CRIME")}
              <br />
              {t("ADD_CRIEMETEXT")}
              <br /> {t("TO_ALL")}
              <br />
              {t("ADD_TO")}
            </h2>
          </div>
          <div className={styles.conformBtn}>
            <button
              className={styles.conformSubBtn}
              disabled={disable}
              onClick={handleSubmitReport}
            >
              {t("CONFIRM_SAVE")}
            </button>
            <button
              className={styles.conformCancelBtn}
              onClick={() => onClose()}
            >
              {t("CANCEL_EDIT")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportSubmitPopup;
