// dateUtils.js
export const formatDate = (date) => {
  const ddate = new Date(date);
  const year = ddate.getFullYear();
  const month = ddate.getMonth() + 1;
  const day = ddate.getDate();
  const formattedMonth = month < 10 ? "0" + month : month;
  const formattedDay = day < 10 ? "0" + day : day;
  return `${year}-${formattedMonth}-${formattedDay}`;
};
