import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { img_path, timestampToDate } from "../../../commonComponent/ExtraPlus";
import UserSearchModule from "../../../commonComponent/UserSearchInput";
import Sidebar from "../Sidebar";
import styles from "./Message.module.css";
import {
  ChatHistory,
  unreadTick,
  updateUserData,
} from "../../../firebase/FireBaseChat";
import { Col, Container, Row } from "react-bootstrap";
import icon from "../../../assets/Images/Group.svg";
import Loader from "../../../utils/Loader/Loader";

function Messages() {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [messages, setMessages] = useState([]);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const setMessageList = (messageList) => {
      // Sort messages by timestamp in descending order
      const sortedMessages = messageList.sort(
        (a, b) => b.timestamp - a.timestamp
      );
      setMessages(sortedMessages);
      setLoader(false);
    };
    const unsubscribe = ChatHistory(currentUser, setMessageList);

    return () => {
      unsubscribe(); // Cleanup function to detach listeners when component unmounts
    };

    // FireBaseChat(currentUser, setMessageList);
  }, [currentUser]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // Navigate to the chat room
  const handleClick = async (history) => {
    const data = { history: history };
    navigate(`/messages/${history?.id}`, { state: data });
  };

  async function updateChatUserChatCount() {
    // Convert unread_count to a number (if it's a string in some cases), and filter chats with unread_count > 0
    const unreadChatsCount = messages.filter(
      (chat) => Number(chat.unread_count) > 0
    ).length;

    const updateObject = {
      chat_count: unreadChatsCount,
    };
    await updateUserData(currentUser.userID, updateObject);
  }

  useEffect(() => {
    updateChatUserChatCount();
  }, [messages]);

  // Filter messages based on the search input
  const filteredMessages = messages.filter((message) => {
    const searchLower = search.toLowerCase();
    const usernameMatch =
      message.user_name && message.user_name.toLowerCase().includes(searchLower);
    const textMatch =
      message.text && message.text.toLowerCase().includes(searchLower);
    return searchLower === "" || usernameMatch || textMatch;
  });

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            <Col lg={3} md={4}>
              <Sidebar />
            </Col>
            <Col lg={9} md={8} className="rightPart">
              <div className="rightTopPart">
                <h2>Messages</h2>
                <UserSearchModule hadleSearch={handleSearch} value={search} />
              </div>
              <div className={styles.messageBox}>
                {filteredMessages.length > 0 ? (
                  filteredMessages.map((message, index) => (
                    
                    <div
                      key={message.id}
                      className={styles.messageInfo}
                      onClick={() => handleClick(message)}
                    >
                      <div className={styles.messageImg}>
                        {message.online === "isOnline" ? (
                          <div className={styles.greenIconUser}></div>
                        ) : (
                          <div className={styles.redIconUser}></div>
                        )}
                        <img
                          src={
                            (message.profile_url==2)? message.profile_pic : (message.profile_pic
                              ? img_path + message.profile_pic
                              : icon)
                          }
                          alt="img"
                        />
                      </div>
                      <div className={styles.messageText}>
                        <p>{message.user_name}</p>
                        {message.image_url !== "" && message.message == "" ? (
                          <h3>Image</h3>
                        ) : (
                          <h3>{message.message}</h3>
                        )}
                      </div>
                      <div className={styles.messageDate}>
                        <p>{timestampToDate(message.timestamp)}</p>
                        {message.unread_count > 0 && (
                          <span>{message.unread_count}</span>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p
                    className={styles.messageInfo}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    No data found
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Messages;
