import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "../../Content/Content.module.css";
import { useTranslation } from "react-i18next";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { useEffect, useState } from "react";
import { CONTENT_TYPE } from "../../../utils/Constants";
import { TextFormate } from "../../../utils/removeExtraPlusSigns";
import Loader from "../../../utils/Loader/Loader";

function HelpCenter() {
  const { t } = useTranslation();
  const [helpCenter, setHelpCenter] = useState();
  const [loader, setLoader] = useState(false);

  // function for get terms and condition api calling
  async function helpCenterContent() {
    setLoader(true);
    await SublyApi.contentType(CONTENT_TYPE.HELP_CENTRE).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setHelpCenter(
          response &&
            response.data &&
            response.data.content &&
            response.data.content.help_center
        );
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    helpCenterContent();
  }, []);

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("HELP_CENTER")}</h2>
                </div>

                <div className="reportIncidentOverlay">
                  <div className="reportIncident">
                    <p
                      className={styles.termsCondition}
                      dangerouslySetInnerHTML={{
                        __html: TextFormate(helpCenter),
                      }}
                    ></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
export default HelpCenter;
