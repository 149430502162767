import React, { useState, useEffect } from "react";
import { requestNotificationPermission } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
import { useNavigate } from "react-router-dom";
import Notify from "../assets/Images/logo.svg";
import { useLocation } from "react-router-dom";

const FireBaseNotification = () => {
  const { userToken } = useSelector((state) => state.user);
  const location = useLocation();

  const newPath = location.pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    title: "",
    body: "",
    type: "",
    reffrenceId: "",
  });

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => {
    setShowA(true);
    setTimeout(() => setShowA(false), 6000);
  };

  function ToastDisplay() {
    return (
      <div className="FirbaseToaster">
        <Toast
          show={showA}
          onClose={() => setShowA(false)}
          delay={5000}
          autohide
          animation
        >
          <Toast.Header>
            <div className="tosterNotifyMainDiv">
              <div className="Notifydiv">
                <img
                  src={Notify}
                  className="rounded mr-2"
                  alt="notification=img"
                />
              </div>
              <div
                className="NotifyPara"
                style={{ cursor: "pointer", paddingRight: "18px" }}
                onClick={() => {
                  if (notification.type == "crime_alert") {
                    navigate(`/report/${notification.reffrenceId}`);
                  } else if (notification.type == "new_follower") {
                    navigate(`/profile-detail/${notification.reffrenceId}`);
                  }
                }}
              >
                <strong className="me-auto">{notification?.title}</strong>
                <span className="NotifyPararr">
                  {notification?.body?.split(" ").slice(0, 16).join(" ")}
                </span>
              </div>

              <span
                className="NotifyParaCrossIcon"
                onClick={() => {
                  setShowA(false);
                }}
              >
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.3861 11L11 9.3861L7.09266 5.51062L11 1.6139L9.3861 0L5.48938 3.89672L1.6139 0L0 1.6139L3.87548 5.51062L0 9.3861L1.6139 11L5.48938 7.09266L9.3861 11Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </Toast.Header>
        </Toast>
      </div>
    );
  }

  useEffect(() => {
    requestNotificationPermission(dispatch);

    const handleServiceWorkerMessage = (event) => {
      const { data } = event;

      setNotification({
        title: data?.notification?.title,
        body: data?.notification?.body,
        type: data?.data?.type,
        reffrenceId: data?.data?.reference_id,
      });

      const messageWindowRoute = "/messages/" + data.data.reference_id;

      if (data.data.type === "chat") {
        if (newPath != messageWindowRoute) {
          toggleShowA();
        }
      } else {
        toggleShowA();
      }

      const notificationPayload = data;
      //   dispatch(setUpdateChatCount(notificationPayload.badge));
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleServiceWorkerMessage
    );

    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleServiceWorkerMessage
      );
    };
  }, [dispatch]);

  return <>{ToastDisplay()}</>;
};

export default FireBaseNotification;
