import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "../UserModule/NewReport/ReportPopup.module.css";
import img from "../../assets/Images/Security_announcement.svg";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

function JoinPopup({ showJoin, setShowJoin }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleJoin = () => {
    navigate("/join");
    setShowJoin(false);
  };

  return (
    <Modal
      className="mainModule"
      show={showJoin}
      onHide={() => setShowJoin(false)}
    >
      <div className="header">
        <span className="alertCross">
          <RxCross2 onClick={() => setShowJoin(false)} />
        </span>
      </div>
      <div className={styles.reportDivImgSignIn}>
        <div className={styles.signinPopupImg}>
          <img src={img} alt="img" />
        </div>
        <h5>
          {t("SIGNIN")} / {t("SIGNUP")}
        </h5>
        <p>
          {t("JOIN_PARA")} <br />
          {t("JION_PARANEW")}
        </p>

        <div className={styles.conformBtnExitNew}>
          <button className={styles.conformBtnNew} onClick={() => handleJoin()}>
            {t("JOIN_NOW")}
          </button>
          <button
            className={styles.cancelBtn}
            onClick={() => setShowJoin(false)}
          >
            {t("CANCEL")}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default JoinPopup;
