import Modal from "react-bootstrap/Modal";
import styles from "./NewReport.module.css";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

//---------function for select vehicle popup modal---------
function VehicleModal({
  vehicleModal,
  setVehicleModal,
  crimesCategory,
  setVehicle,
  vehicle,
}) {
  const { t } = useTranslation();

  // ----------- function for manage vehicle select onChange----------------
  const handleVehicleChange = (vehicle) => {
    setVehicle({
      id: vehicle.id,
      name: vehicle.value,
    });
    setVehicleModal(false);
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={vehicleModal}
        onHide={() => {
          setVehicleModal(false);
        }}
        className="modalCls"
        backdrop="static"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <h4>{t("VEHICLE_MAKE")}</h4>
            <MdCancel
              onClick={() => {
                setVehicleModal(false);
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.showVehicle}>
            {crimesCategory &&
              crimesCategory.vehicle_list &&
              crimesCategory.vehicle_list.length > 0 &&
              crimesCategory.vehicle_list.map((vehicleType) => {
                return (
                  <div
                    key={vehicleType.id}
                    className={`${styles.vehiclesAdd} ${
                      vehicle && vehicle.id === vehicleType.id
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => handleVehicleChange(vehicleType)}
                  >
                    <div className={styles.displayOptionAdd}>
                      <p>{vehicleType.value}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VehicleModal;
