import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import edit_icon from "../../src/assets/Images/edit_icon.png";

//--------Common CRUD icon for user module----------
function CrudIcon({ commentId, onToggleEdit, onToggleDelete }) {
  return (
    <div className="iconDisplay">
      <div className="setIcon">
        <FaRegTrashCan onClick={() => onToggleDelete(commentId)} />
      </div>
      <div className="">
        <img src={edit_icon} onClick={() => onToggleEdit(commentId)} />
      </div>
    </div>
  );
}

export default CrudIcon;
