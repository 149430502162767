import { Container } from "react-bootstrap";
import styles from "../PageNotFound/PageNotFound.module.css";
import map from "../../assets/Images/map_pin.png";
import { t } from "i18next";

function PageNotFound() {
  return (
    <div className="main">
      <Container>
        <div className={styles.PageNotFound}>
          <img src={map} alt="map-icon" />
          <h1>
            {t("WHOA")} <strong>{t("LOST")}</strong> {t("ONE")}
          </h1>
          <p>{t("TEXT_ADD")}</p>
        </div>
      </Container>
    </div>
  );
}
export default PageNotFound;
