import { Col, Container, Row } from "react-bootstrap";
import styles from "./Content.module.css";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { CONTENT_TYPE } from "../../utils/Constants";
import { useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/statusCode";
import { useEffect, useState } from "react";
import { TextFormate } from "../../utils/removeExtraPlusSigns";
import Loader from "../../utils/Loader/Loader";
import Sidebar from "../UserModule/Sidebar";

function PrivacyPolicy() {
  const { currentUser } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [privacyData, setPrivacyData] = useState();
  const [loader, setLoader] = useState(false);

  //======= function for privacy policy api calling=====
  async function privacyPolicy() {
    setLoader(true);
    await SublyApi.contentType(CONTENT_TYPE.PRIVACY_POLICY).then((response) => {
      setLoader(false);

      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        setPrivacyData(
          response &&
            response.data &&
            response.data.content &&
            response.data.content.privacy
        );
      }
    });
  }

  useEffect(() => {
    privacyPolicy();
  }, []);

  return (
    <div className="main">
      {loader && <Loader />}
      <div className="spaceTopSet">
        <Container>
          <Row>
            {Object.keys(currentUser).length !== 0 && (
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
            )}
            <Col
              lg={Object.keys(currentUser).length !== 0 ? 9 : 12}
              md={Object.keys(currentUser).length !== 0 ? 8 : 12}
              className={
                Object.keys(currentUser).length !== 0
                  ? "rightPart"
                  : "centerSet"
              }
            >
              <div className="rightTopPart">
                <div className={styles.searchBoxCls}>
                  <h2>{t("PRIVACY")}</h2>
                </div>
              </div>

              <div className="reportIncidentOverlay">
                <div className="reportIncident">
                  <p
                    className={styles.termsCondition}
                    dangerouslySetInnerHTML={{
                      __html: TextFormate(privacyData),
                    }}
                  ></p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
