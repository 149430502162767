import React from "react";
import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";

const ImagePreviewModal = ({ show, handleClose, imageUrl }) => {
  return (
    <>
      <Modal
        className="mainModuleImg"
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <div className="imageShow">
          <ImCross className="modal-close-icon" onClick={handleClose} />
          <img src={imageUrl} alt="Preview" className="modal-image" />
        </div>
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
