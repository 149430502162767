import { Form, Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "./VerifyModal.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderButton from "../../commonComponent/CommonButton/HeaderButton";
import { removeExtraPlusSign } from "../../utils/removeExtraPlusSigns";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import Loader from "../../utils/Loader/Loader";
import { useEffect, useState } from "react";
import { userSignupVerify } from "../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../utils/Toaster";
import { Validation } from "../../utils/Validation";
import { handleKeyDown } from "../../utils/manageConstant";

//---------function for verify OTP modal----------
function VerifyModal({
  verifyModal,
  setVerifyModal,
  formDataSet,
  phoneNo,
  dialCode,
  countryCode,
  emailActive,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { isLoading } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    reset();
    clearErrors();
  }, [verifyModal]);

  // -----function for send verification code api calling------
  async function SendEmailOtp() {
    let requestData = new FormData();
    requestData.append(
      "name",
      formDataSet ? formDataSet.name && formDataSet.name.trim() : ""
    );
    if (emailActive) {
      requestData.append(
        "email",
        formDataSet ? formDataSet.email && formDataSet.email.trim() : ""
      );
    } else {
      requestData.append(
        "dial_code",
        dialCode ? removeExtraPlusSign(dialCode) : ""
      );
      requestData.append("country_code", countryCode ? countryCode : "");
      requestData.append("phone", phoneNo ? phoneNo : "");
    }
    requestData.append(
      "password",
      formDataSet ? formDataSet.password && formDataSet.password.trim() : ""
    );
    requestData.append(
      "confirmpass",
      formDataSet
        ? formDataSet.confirmPassword && formDataSet.confirmPassword.trim()
        : ""
    );
    requestData.append("social_type", "");
    setLoader(true);
    await SublyApi.sendCode(requestData).then((response) => {
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      setLoader(false);
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  //----------function for verify signup code api calling----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "name",
      formDataSet ? formDataSet.name && formDataSet.name.trim() : ""
    );
    if (emailActive) {
      requestData.append(
        "email",
        formDataSet ? formDataSet.email && formDataSet.email.trim() : ""
      );
    } else {
      requestData.append(
        "dial_code",
        dialCode ? removeExtraPlusSign(dialCode) : ""
      );
      requestData.append("country_code", countryCode ? countryCode : "");
      requestData.append("phone", phoneNo ? phoneNo : "");
    }
    requestData.append(
      "password",
      formDataSet ? formDataSet.password && formDataSet.password.trim() : ""
    );
    requestData.append(
      "confirmpass",
      formDataSet
        ? formDataSet.confirmPassword && formDataSet.confirmPassword.trim()
        : ""
    );
    requestData.append("social_id", "");
    requestData.append("social_type", "");

    requestData.append(
      "device_token",
      localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
        ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
        : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)
    );
    requestData.append(
      "verify_code",
      formdata ? formdata.otp && formdata.otp.trim() : ""
    );

    dispatch(userSignupVerify(requestData)).then((responsejson) => {
      const response = responsejson.payload;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        navigate("/dashboard");
        setVerifyModal(false);
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  return (
    <>
      {loader && <Loader />}
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={verifyModal}
          onHide={() => {
            setVerifyModal(false);
          }}
          className={`modalDialog ${styles.modalClass}`}
          backdrop="static"
        >
          <Modal.Header className={styles.modalHeader}>
            <h4>{emailActive ? t("VERIFY_EMAIL") : t("VERIFY_PHONE")}</h4>
          </Modal.Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className={styles.modalBody}>
              <h5>{emailActive ? t("CHECK_EMAIL") : t("CHECK_NUMBER")}</h5>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("ENTER_OTP")}
                  {...register("otp", Validation.otp)}
                  onKeyDown={handleKeyDown}
                />
                {errors.otp && (
                  <span className="errorMsg">{errors.otp.message}</span>
                )}
              </Form.Group>
              <p>
                {t("DIDNT_RECIVE")}
                <NavLink
                  onClick={() => {
                    SendEmailOtp();
                  }}
                >
                  {" "}
                  {t("RESEND_OTP")}
                </NavLink>
              </p>
            </Modal.Body>
            <Modal.Footer className={styles.modalButton}>
              <Button
                type="button"
                className={styles.leftButton}
                onClick={() => setVerifyModal(false)}
              >
                {t("CANCEL")}
              </Button>
              {/* common button */}
              <HeaderButton type="submit" label={t("VERIFY_OTP")} />
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </>
  );
}
export default VerifyModal;
