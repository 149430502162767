import Modal from "react-bootstrap/Modal";
import styles from "./NewReport.module.css";
import { MdCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";

//---------function for select vehicle popup modal---------
function EthenicityModal({
  ethenicityModal,
  setEthenicityModal,
  crimesCategory,
  setEthenicity,
  ethenicity,
}) {
  const { t } = useTranslation();

  // ----------- function for manage vehicle select onChange----------------
  const handleVehicleChange = (ethenicityType) => {
    setEthenicity({
      id: ethenicityType.id,
      name: ethenicityType.value,
    });
    setEthenicityModal(false);
  };

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={ethenicityModal}
        onHide={() => {
          setEthenicityModal(false);
        }}
        className="modalClscc"
        backdrop="static"
      >
        <Modal.Header>
          <div className={styles.modalHeader}>
            <h4>{t("SELECT_ETHNIC")}</h4>
            <MdCancel
              onClick={() => {
                setEthenicityModal(false);
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.ethenicDivScroll}>
            {crimesCategory &&
              crimesCategory.ethnicity_list &&
              crimesCategory.ethnicity_list.length > 0 &&
              crimesCategory.ethnicity_list.map((ethenicityType) => {
                return (
                  <div
                    key={ethenicityType.id}
                    className={`${styles.vehiclesAdd} ${
                      ethenicity && ethenicity.id === ethenicityType.id
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => handleVehicleChange(ethenicityType)}
                  >
                    <div className={styles.displayOptionAdd}>
                      <p>{ethenicityType.value}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EthenicityModal;
