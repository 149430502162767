import { useState } from "react";
import styles from "../../authComponent/Login/Login.module.css";
import { Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { TfiEmail } from "react-icons/tfi";
import { TfiMobile } from "react-icons/tfi";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthInput from "../../commonComponent/AuthInputs";
import AuthButton from "../../commonComponent/CommonButton/AuthButton";
import { removeExtraPlusSign } from "../../utils/removeExtraPlusSigns";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import Loader from "../../utils/Loader/Loader";
import { Validation, validateNumber } from "../../utils/Validation";
import { onInputChange } from "../../utils/manageConstant";
import Toster from "../../utils/Toaster";

//---------function for password----------
function Password() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [emailActive, setEmailActive] = useState(true);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChangeClick = () => {
    setEmailActive(!emailActive);
  };

  //----------function for login api calling----------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    if (emailActive) {
      requestData.append(
        "email",
        formdata ? formdata.email && formdata.email.trim() : ""
      );
    } else {
      requestData.append(
        "dial_code",
        dialCode ? removeExtraPlusSign(dialCode) : ""
      );
      requestData.append("country_code", countryCode ? countryCode : "");
      requestData.append("phone", phoneNo ? phoneNo : "");
    }

    setLoader(true);
    await SublyApi.resetPassword(requestData).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        navigate("/password-success", {
          state: {
            emailActive: emailActive,
          },
        });
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  return (
    <div className="main">
      {loader && <Loader />}
      <Container>
        <div className="topSpace">
          <div className={styles.passwordSet}>
            <h3>{t("PASSWORD_REMINDER")}</h3>
            <h4>{t("FORGOT_PASSWORD")}</h4>
          </div>
          <div className={styles.formBlock}>
            <div className={styles.SignupText}>
              <p>{t("SELECT")}</p>
              <div>
                <span
                  className={emailActive ? styles.TextActive : styles.EmailText}
                  onClick={() => handleChangeClick()}
                >
                  <TfiEmail />
                  {t("EMAIL")}
                </span>
                <span className={styles.border}></span>
                <span
                  onClick={() => handleChangeClick()}
                  className={
                    !emailActive ? styles.TextActive : styles.EmailText
                  }
                >
                  <TfiMobile />
                  {t("SMS")}
                </span>
              </div>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
              {emailActive ? (
                <>
                  <AuthInput
                    label={t("EMAIL_ADDRESS_LOGIN")}
                    placeholder={t("EMAIL_ADDRESS")}
                    name="email"
                    type="text"
                    register={register}
                    errors={errors}
                    onInputChange={(e) => onInputChange(e, setValue)}
                    validationRules={Validation.email}
                  />
                  <div className={styles.SignupTextCode}>
                    <p>{t("SAND_EMAIL")}</p>
                  </div>
                </>
              ) : (
                <>
                  <AuthInput
                    label={t("PHONE_NUMBER")}
                    name="phoneNumber"
                    type="phone"
                    control={control}
                    errors={errors}
                    placeholder="+27 XXX-XXX-XXXX" 
                    onInputChange={(e) => onInputChange(e, setValue)}
                    phoneInputProps={{
                      country: "za",
                      countryCodeEditable: false,
                      dialCode,
                      phoneNo,
                      setCountryCode,
                      setDialCode,
                      setPhoneNo,
                      clearErrors: clearErrors,
                      placeholder: `+${dialCode || '27'} XXX-XXX-XXXX`,
                    }}
                    validate={() => validateNumber(phoneNo)}
                  />
                  <div className={styles.SignupTextCode}>
                    <p>{t("SAND_SMS")}</p>
                  </div>
                </>
              )}

              {/* common button */}
              <AuthButton type="submit" label={t("SEND_REMINDER")} />
              <div className={styles.passworText}>
                <p>
                  {t("ONBOARD")} <NavLink to="/login"> {t("LOGIN")}</NavLink>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Password;
