export const TRANSLATIONS_EN = {
  //----------------Header Text----------------------
  REPORT: "REPORT",
  JOIN: "JOIN",
  MAP: "Map",
  TIMELINE: "Timeline",
  ALERT: "Alerts",
  MESSAGES: "MESSAGES",
  SEARCH_PLACEHOLDER: "Search CrimeSpotter",

  //----------------Footer Text----------------------
  HOW_WORKS: "HOW IT WORKS",
  JOIN_THIS: "JOIN CRIMESPOTTER",
  COPYRIGHT: "COPYRIGHT CRIMESPOTTER",
  PRIVACY: "Privacy Policy",
  TERMS: "Terms and Conditions",
  CONTACTUS: "Contact Us",
  DESIGN_ADVERTISE: "Designed by In-Detail Advertising",

  //----------------Auth Module Text----------------------
  MOBILE_LENGTH: "Mobile number should be numeric and at least 10 in length",
  PHONE_MAXLENGTH: "Mobile number field cannot exceed 10 characters in length",
  LOGIN: "Login",
  WITH_EMAIL: "with your email, Whatsapp or Social Media.",
  LOGIN_USING:
    "Login using social media or your existing email or whatsapp number.",
  LOGIN_WITH: "Login with",
  OR: "Or",
  EMAIL: "Email",
  SMS: "SMS",
  EMAIL_ADDRESS: "Enter Email Address",
  VALID_EMAIL: "Enter a valid email address",
  PASSWORD: "Password",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_REMIND: "Password Reminder",
  CREATE: "Create",
  AN_ACCOUNT: "an account with email or WhatsApp",
  CREATE_ACCOUNT: "Or create an account using your social media under login",
  NAME: "Name",
  NAME_REQUIRED: "The name field is required",
  SIGNUP_WITH: "Signup with",
  SAND_EMAIL: "A confirmation code will be sent to this email",
  SAND_SMS: "A confirmation code will be sent to this number",
  CONFIRM_PASS: "Confirm Password",
  PASS_NOT_MATCH: "The password confirmation does not match",
  PASS_ERROR: "ERROR - Password does not match. Please check.",
  ONBOARD: "Already onboard?",
  SIGNUP: "Sign Up",
  SIGNIN: "Sign IN",
  SEND_REMINDER: "SEND REMINDER",
  SELECT: "Select",
  FORGOT_PASSWORD: "Forgot your password? No problem, reset it here.",
  PASSWORD_REMINDER: "Password Reminder",
  PASSWORD_SAND: "Password reminder sent, check your",
  NUMBER: "number",
  EMAIL_SMALL: "email",
  VERIFY_PHONE: "Verify your phone number",
  CHECK_EMAIL: "Check your Email for OTP",
  CHECK_NUMBER: "Check your Whatsapp Number for OTP",
  DIDNT_RECIVE: "Didn't receive OTP",
  RESEND_OTP: "Resend OTP",
  CANCEL: "Cancel",
  VERIFY_OTP: "verify otp",
  ENTER_OTP: "Enter OTP",
  OTP_REQUIRED: "OTP field is required",
  LOGIN_SUCCESSFULLY: "Logged in successfully.",
  CONF_PASS_REQUIRED: "Confirm password is required",

  //----------------User Module Text----------------------
  BLONDEE: "BlondDee",
  CRIMESPOTTER: "Crimespotter",
  DASHBOARD: "Dashboard",
  NEW_REPORT: "New Report",
  YOUR_REPORT: "Your Report",
  MESSAGE: "Messages",
  COMMENTS: "Comments",
  ALERTS: "Alerts",
  PUSH_NOTIFY: "Push Notifications",
  EMAIL_NOTIFY: "Email Notifications",
  ALERT_LOCATION: "Alert Locations",
  YOUR_PROFILE: "Your Profile",
  LOGIN_SETTING: "Login Settings",
  FOLLOWERS: "Followers",
  FOLLOWING: "Following",
  NETWORK: "Networks",
  CONNECT_TWITTER: "Connect Twitter",
  CONNECT_FB: "Connect FB",
  HELP_CENTER: "Help Centre",
  LOGOUT: "Log Out",
  WELCOME_BACK: "WELCOME BACK",
  INCIDENT: "Incident",
  SELECT_CRIME: "Select Crime",
  DATE: "Date",
  TIME: "Time",
  UNKNOWN: "Unknown Time",
  LOCATION: "Location",
  OF_INCIDENT: "of Incident",
  HAPPEN_TEXT: "Where did this happen?",
  CONFIRM_PIN:
    "Confirm pin is placed corectly - Drag the pin to location of the incident (if required).",
  RECENTRE: "RECENTRE",
  LAT: "LAT.",
  LONG: " LONG.",
  DETAILS: "Details",
  DESCRIBE_INCIDENT:
    "Describe the incident and provide as much detail as possible.",
  HASHTAG: " Add hashtags to connect crimes - eg. #AK47 #scarface.",
  ADD_PHOTO: "ADD PHOTOS / VIDEOS",
  SUBMIT_REPORT: "Submit Report",
  BY_POSTING:
    "Terms & Conditions - By posting this report you indicate acceptance of our",
  CLEAR_ALERT: "Clear Alerts",
  DOWNLOAD_NOTIFY: "Browser Push Notifications. ",
  NOTIFY_HIGHLIGHT: "Download the App for mobile notifications.",
  NEARBY_CRIME: "Nearby Crime Notifications",
  RECEIVE_NOTIFY: "Receive push notifications of crimes happening around you.",
  SUSPICIOUS: "Suspicious Activity",
  ENABLE_NOTIFY:
    "Enable push notification of Suspicious Activity reports nearby.",
  ALERT_RADIUS: "Alert Radius",
  SELECT_DISTANCE: "Around current location (Max 10km).",
  SUBMIT: "Submit",
  ALERT_SUSPIOUS: "Alerts of suspicious activity.",
  CRIME_NOTIFY: "Crime Notifications",
  ALERT_CRIME: "Alerts of crimes that have been committed.",
  SEND_EMAIL: "Sent to your profile email address",
  EMAIL_NOTIFICATION:
    "Receive email notifications of crimes happening around you or around your saved locations.",
  KM: "km",
  SELECT_DIST: "Select a distance from 1 to 8km.",
  KILOMETER: "Kilometers",
  MILES: "Miles",
  LOCATION_TEXT:
    "You can receive alerts from additional locations without beirg nearby. Such as your home or work address.",
  ADD_LOCATION_TEXT: "No Alert locations to disiplay. Add one below.",
  ADD_TEXT: "+ Add",
  PROFILE_TEXT: "Full name on profilecan beupto 50 characters like",
  REPORTS: "REPORTS",
  LOCATION_DESCRIPT: "Location Descriptor (eg. Home)",
  ENTER_NAME: "Enter Name",
  ADDRESS: "Address",
  ENTER_ADDRESS: "Enter Address",
  ADDRESS_REQUIRED: "Address field is required",
  DRAG_MAP: "Drag map to adjust pin if required.",
  RECEIVED_PUSH_NOTIFY:
    "Receive push notifications of crimes happening around this location. Push notification must be enabled in your browser.",
  ALLOW_NOTIFY: "Allow email notifications for this location.",
  DEFAULT_LOCATION: "Default Locations",
  SET_DEFAULT:
    "Set the default location for crime map when GPS location is unavailable.",
  PUBLIC_NAME: "Public Name * (max 50 chars)",
  DISPLAY_NAME: "Enter Display Name",
  NAME_MAXLENGTH: "Enter name, with a maximum of 35 characters.",
  DISPLAY_PROFILE: "Displayed on your profile Edit Anytime.",
  USERNAME: "Username * (max 15 chars)",
  ENTER_USERNAME: "Enter Username",
  USERNAME_REQUIRED: "Username is required",
  USERNAME_MAXLENGTH: "Enter username, with a maximum of 15 characters.",
  CREATE_ANYTIME: " Created from your entered name by default. Edit anytime.",
  PUBLIC_PROFILE: "Public Profile Details",
  ALL_FIELD: "All fields are optional",
  DESCRIPTION: "Profile Description (max 200 characters)",
  ENTER_DESCRIPTION: "Enter description",
  DESCRIPT_MAXLENGTH: "Enter description, with a maximum of 200 characters.",
  YOUR_LOCATION: "Your Location (suburb or country)",
  ENTER_LOCATION: "Enter Location…",
  EDIT_ANYTIME:
    "Created from your entered name by default. Edit anytime if available.",
  WEBSITE_ADDRESS: "Website Address (optional)",
  ENTER_WEBSITE: "Enter Website Address",
  TELEPHONE: "Telephone Number (optional)",
  ENTER_CONTACT: "Enter Contact Number",
  DISPLAY_EMAIL: "Display Email Address (optional)",
  SOCIAL_MEDIA: "Social Media Links",
  FACEBOOK_LINK: "Add link to Facebook",
  TWITTER_LINK: "Add link to Twitter",
  PROFILE_LOGO: "Profile Image or Logo",
  ADD_IMAGE: "ADD IMAGE",
  LOCATION_REQUIRED: "Location field is required",
  INCIDENT_MAXLENGTH:
    "Enter incident details, with a maximum of 200 characters.",
  PUBLICNAME_REQUIRED: "Name is required",
  PUBLICNAME_MAXLENGTH: "Name must be with a maximum of 50 characters.",
  PUBLICNAME_MINENGTH: "Name must be atleast 2 characters.",
  HTML_TAG: "HTML tags are not allowed.",
  SELECT_TIME: "Select a time",
  SELECT_DATE: "Select a date",
  DATE_REQUIRED: "Date field is required",
  EMAIL_ADDRESS_TEXT: "Email Address",
  MOBILE_NUMBER: "Mobile Number (optional)",
  NEW_PASS: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  ENTER_PASSWORD: "Enter Password",
  DEATILS_REQUIRED: "Details field is required.",
  INCIDENT_MINENGTH:
    "Enter incident details, with a minimum of 200 characters.",
  CONFIRM_DELETE: "Confirm Deletion",
  PLEASE_NOTE_ALERT: "PLEASE NOTE",
  REVERIFY: "NOTE: Any change will require re-verification",
  REPORT_AS: "REPORT AS",
  ANONYMS: "Anonymous",
  IDENTITY_WITHELD: "IDENTITY WITHELD",
  REGISTRATION: "Vehicle Registration",
  INPUT_VEHICLE: "Input Vehicle Modal",
  VEHICLE_COLOR: "Input Vehicle Colour",
  VEHICLE_MAKE: "Select Vehicle Make",
  FULL_NAME: "Full Name (required)",
  FEMALE: "Female",
  MALE: "Male",
  ETHNICITY: "ETHNICITY",
  SELECT_ETHNIC: "Select Ethnic Group",
  REPORT_FLAGGED_SUCCESSFULL: "Report flagged successfully",
  REPORT_BOOKMARKED_SUCCESSFULL: "Report bookmarked successfully",
  REPORT_UNBOOKMARKED_SUCCESSFULL: "Report unbookmark successfully",
  REPORT_TREND_SUCCESSFULL: "Report trended successfully",
  REPORT_UNTREND_SUCCESSFULL: "Report untrend successfully",
  PROFILE_FOLLOW_SUCCESSFULL: "Followed successfully",
  PROFILE_UNFOLLOW_SUCCESSFULL: "Unfollowed successfully",
  NO_COMMENTS_YET: "No Comments yet.",
  DID_YOU_SEE_SOMETHING: "Did you see something?",
  CAN_YOU_OFFER_INFOMATION_ON: "Can you offer infomation on this incident?",
  CHCEK_FOUND: "CHECK THIS WHEN FOUND",
  LOGIN_TEXT:
    "Your email address and / or mobile number can be used to login. Add both your email and mobile number together here to avoid creating duplicate profiles.",
  PASS_LIMIT:
    "  Password must contain a minimum of five characters and at least one number.",
  CURRENT_PASSWORD: "Current Password: ***rds77",

  SET_NOTIFICATION: "Set Email Notification",
  EMAIL_NOTIFICATION_HEADING: "Email Notifications",
  EMAIL_ADDRESS_SHOW: "Email Address",
  PUSH_NOTIFICATION_IS_ON: "Push Notifications are",
  SWITCH_NOTIFICATION_PARA:
    "Set your Crimespotter push notifications on or off.",
  ENABLE_PUSH_NOTIFY: "Enable push notification from your saved",
  NEW_MESSAGE: "New Messages",
  NEW_MESSAGE_PARA:
    "Enable push notification of new direct messages from other users.",
  NEW_FOLLOWERS: "New Followers",
  FOLLOWERS_PARA: "Enable push notification of new followers.",

  //----------------Networks Module Text----------------------
  FOURWAYS_CPF: "Fourways CPF",
  CLOSED_NETWORK: "CLOSED NETWORK",
  NEW_NETWORK_REPORT: "NEW NETWORK REPORT",
  NETWORK_REPORTS: "Network Reports",
  CRIME_STATE: "Crime State",
  CREATE_REPORT: "Create Report",
  NETWORK_CRIME_STATS: "NETWORK CRIME STATS",
  NETWORK_MESSAGES: "NETWORK MESSAGES",
  CENTRE: "CENTRE",
  ADDRESS_ERRORS_NETWORKS:
    "Address is in wrong format or not found - please try again",
  INCIDENT_DESCRIPTION_ERRORS_NETWORKS:
    "Please enter incident details - Minimum 200 characters",
  EDIT_REPORT: "EDIT REPORT",
  ARMED_ROBBERY: "Armed Robbery",
  DELETE_REPORT_SUCCESSFULLY: "Delete report succesfully.",
  SLIDER_TEXT_PUBLIC: "PUBLIC",
  SLIDER_TEXT_VISABLE: " Visible to",
  SLIDER_TEXT_ALL: "ALL",
  SLIDER_TEXT_USER: "A CrimeSpotter users",
  SLIDER_TEXT__PRIVATE: "PRIVATE",
  SLIDER_TEXT_VISIBLE_TO_NETWORK: " Visible to Network Followers",
  SLIDER_TEXT: "ONLY",
  UPDATE_REPORT: "Update Report",

  // --------------user module report details
  SHARE: "SHARE",
  BOOKMARK_COUNT: "bookmark",
  BOOKMARK_MARKED: "bookmarked",
  TREND_COUNT: "trend",
  TREND_MARKED: "trended",
  FLAG_REPORT: "FLAG REPORT",
  NO_REC_FOUND: "No Records Found",
  FOUND: "FOUND",
  ALL_CRIME: "All Crime Types",
  SEARCH_ADDRESS: "Search Address",
  SELECT_ALL: "Select All",
  DESELECT_ALL: "Deselect All",
  SUBMIT_SELECTION: " Submit Selection",
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  OFFICIAL_REPORT: "NOT AN OFFICIAL REPORT",
  REPORT_THIS: "Report this incident to your local",
  IMMEDIATE: "police immediately.",
  NEARBY_CRIME: "This incident report will be",
  ADD_CRIEMETEXT: "broadcast to all nearby",
  TO_ALL: "CrimeSpotters and added to the ",
  ADD_TO: "central crime database.",
  CONFIRM_SAVE: "CONFIRM AND SUBMIT",
  CANCEL_EDIT: "CANCEL AND EDIT",
  EG: "eg",
  PRIVATE: "PRIVATE",
  PRIVATE_DES:
    "This is a private network report and can only be seen by followers.",
  MAP_VIEW: "Map View",
  STREER_VIEW: "Street View",
  LOADING: "Loading...",

  // -------flag--popup----
  IS_THE_REPORT_INCORRECT: "Is the report incorrect?",
  FLAG_IT_BY_CLICKING_ON_A_RESON: "Flag it by clicking on a reason.",
  ONCE_A_REPORT_HAS_BEEN_FLAGGED: "Once a report has been flagged three",
  TIME_IT_WILL: "times it will be removed.",
  YOUR_REPORT_HAS_BEEN_RECEIVES: "Your report has been received.",
  THANK_YOU: " THANK YOU!",
  UNFOLLOWING: "UNFOLLOWING",
  UNFOLLOW: "Unfollow",
  FOLLOW: "follow",
  LOAD_MORE: "Load more",
  CONTINUE: "CONTINUE",
  CONFIRM_VERIFY: "CONFIRM AND VERIFY",
  SAVE_CHANES: "SAVE CHANGES",
  LOAD_MORE_COMMENTS: "LOAD MORE COMMENTS",
  YOU_HAVE_FLAG_REPORT: "You have already flagged report",
  At: "at",

  // -----delete-popup---
  CONFIRM_DELETE: "CONFIRM DELETE",
  CONFIRM_DELETE_MESSAGE: "Cannot be undone - are you sure?",
  VERIFY_NEED: "Adding or changing your email address requires verification",
  VERIFY_NUM: "Adding or changing your mobile number requires verification",
  FULLNAME: "Fullname ",
  GENDER: "Gender",
  SELECT_ONE: "select one",
  PLEASE_NOTE: "PLEASE NOTE -",
  CRIME_TEXT: `CrimeSpotter is a community alert system designed to help gather crime information and
        alert others. First report the crime to your local police -
                    then use CrimeSpotter to alert everyone nearby. Report every
                    crime, no matter how minor to build the database of crime
                    patterns and movement.`,
  PLEASE_NOTES: "Please Note",
  CHNAGE_HAVE: "Changes have not been saved and ",
  CHNAGE_HAVENEW: "will be lost. Are you sure?",

  // -------folloeing-------
  FOLLOWING_FOLLOWING_SUCCESSFULL: "Following successfully",
  FOLLOWING_UNFOLLOWING_SUCCESSFULL: "Unfollowing successfully",
  BOOKMARK: "Bookmarked",
  RECENTLY_VIEWED: "Recently Viewed",
  MISSING_PERSONS: "Missing Persons",
  WANTED: "Wanted",
  PROFILE: "Profile",
  SETTING: "Settings",
  INVITE_A_FRIEND: "Invite a Friend",
  REMOVE: " REMOVE",
  END_OF_LIST: "End of following",
  NO_RECORDS_FOLLOWING: "No recodes following ",
  NO_RECORDS_FOLLOWERS: "No recodes followers ",
  JOINED: "Joined",
  LAST_LOGIN: "Last Login",
  CONNECT_YOUR_ACCOUNT: "Connect Your Account",
  CONNECT_TWITTER_HEADING: "Auto-Post all your reports to Twitter.",
  DELETE_ALERTS_LOCATION_SUCCESSFULLY: "Delete alert location succesfully.",
  MAP_PARA: "Localised Crime Reporting, Alerts and Stats",
  CRIME_SPOTTER: `Crime Spotter is a crime reporting and information sharing app tha
            enables everyone to combat crime collectingly and to prevent
            becoming a victim by being more aware of crimes around them,`,
  THIS_MAP: `This is a map based system to map all crimes and suspicious
            activity, gathering statistics that will be valuable in the fight
            against crime and to gather data centrally that can lead to
            convictions.`,
  RECENT_REPORT: "Recent reports in",
  UPDATE: "Update",
  ADDRESS_DESCRIPTOR_REQUIRED: "Location descriptor field is required",
  BOOKMARK_REPORT: "bookmark report",
  RECENTLY_VIEWED_REPORT: "recently viewed",
  CROP: "Crop",
  IMAGE: "Image",
  REPORTEXT: "Report",
  EDIT: "edit",
  DELETE: "delete",
  TEXT_ADD: "We seem to have lost this page somewhere in the interweb.",
  WHOA: "Whoa, we",
  LOST: "lost",
  ONE: "one.",
  SEND_MESSAGE: "Send Message",
  // ----------------------------------------------------------
  CONFIRM_CLEAR: "CONFIRM CLEAR",
  GROUP: "Group",
  JOIN_PARA: "You need to be a Crimespotter to",
  JION_PARANEW: "post a report or comment.",
  JOIN_NOW: "JOIN NOW",
  ACCEPTED: "Accepted",
  TERMS_CONDTIONS: "TERMS & CONDTIONS",
  ACCEPT: "ACCEPT",
  SELECT_NONE: "Select None",
  SEARCH: "Search",
  SELECT_ALL: "Select All",
  CLEAR: "Clear",
  Search_Text:
    "Search all reports and use the filter to limit search specific items",
  WANT_SEARCH: "Please enter what you want to search",
  RESULT_FOR: "Results for",
  ICON: "Icon",
  OK: "Ok",

  // invite--friends
  FRIEND_INVITE_SENT: "Friend invite sent! Send to another if you like…",
  INVITE_HEADING_TEXT: "Invite a Friend",
  CRIMINALS_HAVE_A_NETWORK: "Criminals have a Network - Now so do we!",
  YOUR_NAME: "Your Name",
  NAME_OF_YOUR_FRIEND: "Name of your Friend",
  EMAIL_ADDRESS_OF_YOU_FRIEND: "Email Address of your Friend",
  SEND_INVITATION: "SEND INVITATION",
  KNOW_SOMEONE: "Know someone who should be joining Crime Spotter",
  FILL_IN_FORM_DESCRIPTION:
    "Fill in the form below and we will send them an invitation.",
  HOMECRIME: "home / crimesPotter /",
  SEARCH_REPORT: "Search Reports",

  USERNAME_REQUIRED: "Yourname is required",
  USERNAME_MAXLENGTH: "Enter Yourname, with a maximum of 15 characters.",
  FRIEND_NAME_REQUIRED: "Friendname is required",
  FRIEND_NAME_MAXLENGTH: "Enter Friendname, with a maximum of 15 characters.",

  PLEASE_SELECT_CATEGORY: "Please select a category",
  ENTER_YOUR_NAME: "Enter Your Name",
  ENTER_EMAIL_ADDRESS: "Enter Email Address",
  BEALERT: "Alert",
  DELETE_ACCOUNT: "Delete Account",
  NO: "NO",
  YES: "YES",
  DELETE_ALERT: "Are you sure you want to",
  LOGOUTTITLE: "logout?",
  DELETETITLE: "delete your account?",
  USER_IDENTITY_WITHELD: "User identity witheld",
  USER_SELECT_UP_TO_8_IMAGE: "You can't upload more then eight image.",
  EDITS: "EDIT",
  NO_MESSAGE_FOUND: "No Message Found",
  FOLLOWED_YOU: "Followed you!",
  CONNECT: "CONNECT",
  DISCONNECT: "DISCONNECT",
  EMAIL_ADDRESS_LOGIN: "Email Address",
  PHONE_NUMBER: "Phone Number",
  ENTER_NAME: "Enter Name",
  ENTER_CONFIRM_PASSWORD: "Enter Confirm Password",
  VERIFY_EMAIL: "Verify your Email Address",
  DESCRIBE_DETAIL:
    "Describe the incident and provide as much detail as possible. ",
  PHONE_NUMBER_REQUIRED: "Phone number is required",
  DESCRIBE_TEXT_REQUIRED: "Incident details is required",
  YOUR_ACCOUNT_IS_INACTIVE: "Your account is inactive",

  // video and image upload message
  IMAGE_WIDTH_MUST_BE_150px: "Image width must be at least 150 px",
  IMAGE_WIDTH_MUST_BE_4000px: "Image width must be less than 4000 px",
  IMAGE_HEIGHT_MUST_BE_150px: "Image height must be at least 150 px",
  IMAGE_HEIGHT_MUST_BE_4000px: "Image height must be less than 4000 px",
  IMAGE_SIZE_MUST_BE_10MB: "Image size must be less than 10 MB",

  VIDEO_WIDTH_MUST_BE_150px: "Video width must be at least 150 px",
  VIDEO_WIDTH_MUST_BE_4000px: "Video width must be less than 4000 px",
  VIDEO_HEIGHT_MUST_BE_150px: "Video height must be at least 150 px",
  VIDEO_HEIGHT_MUST_BE_4000px: "Video height must be less than 4000 px",
  VIDEO_SIZE_MUST_BE_200MB: "Video size must be less than 200 MB",

  MIN_PROFILE_IMAGE_WIDTH: "Image width must be at least 200 px",
  MAX_PROFILE_IMAGE_WIDTH: "Image width must be less than 4000 px",
  MIN_PROFILE_IMAGE_HEIGHT: "Image height must be at least 200 px",
  MAX_PROFILE_IMAGE_HEIGHT: "Image height must be less than 4000 px",

  WRITE_A_COMMENT: "Write a Comment…",
  WRITE_A_MESSAGE: "Write a Message…",

  CONTACT_US: "Contact Us",

  PASSWORD_MINLENGTH: "Enter password minimum 5 characters",
  // PASSWORD_COMPLEXITY: "Enter password like this Zxy@123",

  PHONE_NUMBER_PROFILE: "Telephone number must be a maximum of 10 digits",
  UNSUBSCRIPTION: "Unsubscription was successful!",
  EMAIL_RECEIVE:
    "We have informed the sender of this email that you don't want to recieve more emails from them.",
  USER_SELECT_UP_TO_3_VIDEO: "You can't upload more then three video",
  INVALID_VIDEO_FORMAT_MP4_3GP_ONLY: "You can upload only mp4 and 3gp extension file",
  MOBILE_VALID: "Phone number is not valid, please provide a valid number",

  YOUR_SESSTION_HASBEEN_EXPIRED: "Your session has been expired",
};
