import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import styles from "./ReportPopup.module.css";
import img from "../../../assets/Images/image.png";
import {
  setReportExit,
  setReportExitModal,
} from "../../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const ReportExitPopup = () => {
  const { reportExit, reportExitModalUrl } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setReportExit(false));
  };

  const continueModal = () => {
    dispatch(setReportExit(false));
    dispatch(setReportExitModal(false));
    navigate(reportExitModalUrl);
  };

  return (
    <Modal
      className="mainModule"
      show={reportExit}
      onHide={() => dispatch(setReportExit(false))}
    >
      <div className="header">
        <span className="alertCross">
          <RxCross2 onClick={() => onClose()} />
        </span>
      </div>

      <div className={styles.reportDivImg}>
        <div className={styles.reportImage}>
          <img src={img} alt="img" />
        </div>
        <h5>{t("PLEASE_NOTES")}</h5>
        <p>
          {t("CHNAGE_HAVE")}
          <br />
          {t("CHNAGE_HAVENEW")}
        </p>

        <div className={styles.conformBtnExit}>
          <button className={styles.conformBtn} onClick={() => continueModal()}>
            {t("CONTINUE")}
          </button>
          <button className={styles.cancelBtn} onClick={() => onClose()}>
            {t("CANCEL")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportExitPopup;
