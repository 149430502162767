import Modal from "react-bootstrap/Modal";
import styles from "../UserModule/YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import icon from "../../assets/Images/right.png";
import { useNavigate } from "react-router-dom";

function EmailAlertPopup({ showPopup, setShowPopup }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Modal
        className="mainModule mainModuleAdd"
        show={showPopup}
        onHide={() => {
          setShowPopup(false);
        }}
      >
        <div className="MainBox emailBoxCss">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  setShowPopup(false);
                  navigate("/");
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.deleteBtn}>
              <div className={styles.popupImgSet}>
                <img src={icon} alt="img" />
              </div>
              <div className={styles.deleteHeadings}>
                <h2>{t("UNSUBSCRIPTION")}</h2>
                <p>{t("EMAIL_RECEIVE")}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmailAlertPopup;
