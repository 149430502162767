import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Header from "../appComponent/Header/Header";
import ScrollToTop from "../helpers/ScrollToTop";
import Footer from "../appComponent/Footer/Footer";
import Login from "../authComponent/Login/Login";
import Join from "../authComponent/Login/Join";
import Password from "../authComponent/Password/Password";
import PasswordSuccess from "../authComponent/Password/PasswordSuccess";
import Dashboard from "../appComponent/UserModule/Dashboard/Dashboard";
import NewReport from "../appComponent/UserModule/NewReport/NewReport";
import YourReport from "../appComponent/UserModule/YourReport/YourReport";
import Messages from "../appComponent/UserModule/Message/Message";
import Comments from "../appComponent/UserModule/Comments/Comments";
import Alerts from "../appComponent/UserModule/Alert/Alert";
import PushNotification from "../appComponent/UserModule/Notifications/PushNotification";
import EmailNotification from "../appComponent/UserModule/Notifications/EmailNotification";
import AlertLocation from "../appComponent/UserModule/AlertLocation/AlertLocation";
import YourProfile from "../appComponent/UserModule/YourProfile/YourProfile";
import UpdateLocation from "../appComponent/UserModule/AlertLocation/UpdateLocation";
import LoginSettings from "../appComponent/UserModule/LoginSettings/LoginSettings";
import { useDispatch, useSelector } from "react-redux";
import NetworksDashboard from "../appComponent/NetworksModule/Dashboard/NetworksDashboard";
import NetworksNewReport from "../appComponent/NetworksModule/Dashboard/NetworksNewReport";
import NetworksCrimeState from "../appComponent/NetworksModule/Dashboard/NetworksCrimeState";
import NetworksMessage from "../appComponent/NetworksModule/Dashboard/NetworksMessage";
import NetworksYourReports from "../appComponent/NetworksModule/Dashboard/NetworksYourReports";
import MapList from "../appComponent/MapList/MapList";
import Timeline from "../appComponent/TimeLine/Timeline";
import Followers from "../appComponent/UserModule/Followers/Followers";
import Following from "../appComponent/UserModule/Following/Following";
import ConnectTwitter from "../appComponent/UserModule/ConnectTwitter/ConnectTwitter";
import MapListReport from "../appComponent/MapList/MapListReport";
import Chat from "../appComponent/UserModule/Message/Chat";
import Bookmark from "../appComponent/UserModule/Bookmark/Bookmark";
import RecentlyViewed from "../appComponent/UserModule/RecentlyViewed/RecentlyViewed";
import MissingAndWanted from "../appComponent/UserModule/MissingAndWanted/MissingAndWanted";
import PageNotFound from "../appComponent/PageNotFound/PageNotFound";
import Terms from "../appComponent/Content/Terms";
import PrivacyPolicy from "../appComponent/Content/PrivacyPolicy";
import ProfileDetail from "../appComponent/UserModule/YourProfile/ProfileDetail";
import SearchList from "../commonComponent/SearchList";
import AcceptTerms from "../appComponent/Content/AcceptTerms";
import SearchResult from "../appComponent/Header/SearchResult";
import HelpCenter from "../appComponent/UserModule/HelpCenter/HelpCenter";
import InviteFriends from "../appComponent/Content/InviteFriends";
import TimelineGuest from "../appComponent/TimeLine/TimeLineGuest";
import FireBaseNotification from "../firebase/FireBaseNotification";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Toster from "../utils/Toaster";
import { STATUS_CODES } from "../utils/statusCode";
import { SOCIAL_TYPE } from "../utils/Constants";
import { setUpdateUserData, socialSignup } from "../store/slices/Userslice";
import axios from "axios";
import SublyApi from "../helpers/Api";
import ReportDetailsShow from "../appComponent/UserModule/YourReport/ReportDetailsShow";
import EmailSubscribe from "../appComponent/EmailSubscribe/EmailSubscribe";
import ContactUs from "../appComponent/Content/ContactUs";
import HowItsWork from "../appComponent/Content/HowItsWork";

function Routers() {
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const firebaseToken = useSelector((state) => state.user.firebaseToken);

  const is_terms_accepted = useSelector(
    (state) => state.user.userData.is_terms_accepted
  );

  useEffect(() => {
    if (isAuthenticated) {
      SublyApi.firebaseTokenUpdate(isAuthenticated, firebaseToken);
    }
  }, [firebaseToken]);

  const PublicRoute = ({ children }) => {
    return !isAuthenticated ? children : <Navigate to="/dashboard" />;
  };

  const PrivateRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }

    if (is_terms_accepted === 0) {
      return <Navigate to="/terms-conditions-accept" />;
    }
    return children;
  };

  const MapReportRoute = ({ children }) => {
    if (!isAuthenticated) {
      return children;
    }
    if (is_terms_accepted === 0) {
      return <Navigate to="/terms-conditions-accept" />;
    }
    return children;
  };

  function TwitterAuthPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //===== function for handle twitter login===
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const denied = params.get("denied");
      if (denied) {
        if (localStorage.getItem("twitterLoginFrom") == "sign-up") {
          navigate("/sign-up");
        } else {
          navigate("/login");
        }
        return false;
      }
      const oauthToken = params.get("oauth_token");
      const oauthVerifier = params.get("oauth_verifier");

      if (localStorage.getItem("twitterLoginFrom") == "connectTwitter") {
        let requestData = new FormData();
        requestData.append("oauth_token", oauthToken);
        requestData.append("oauth_verifier", oauthVerifier);

        await SublyApi.ConnectTwitter(isAuthenticated, requestData).then(
          (res) => {
            if (res && res.status_code === STATUS_CODES.SUCCESS) {
              navigate("/connect-twitter");
              setUpdateUserData((prevUserData) => ({
                ...prevUserData,
                twitter_connect_username: res?.data?.twitter_connect_username,
              }));
            }
          }
        );
        return false;
      }

      // setLoader(true);
      const formData = new FormData();
      formData.append("oauth_token", oauthToken);
      formData.append("oauth_verifier", oauthVerifier);
      formData.append(
        "oauth_token_secret",
        process.env.REACT_TWITTER_OAUTH_TOKEN_SECRET
      );

      if (oauthToken && oauthVerifier) {
        try {
          const accessTokenResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}twitter/accessToken`,
            formData
          );
          // setLoader(false);
          const userDataRes = accessTokenResponse.data;
          const userData = userDataRes.data.result;

          if (userData) {
            let requestData = new FormData();
            requestData.append("social_id", userData.id ? userData?.id : "");
            requestData.append("social_type", SOCIAL_TYPE.TWITTER);
            requestData.append("name", userData.name ? userData?.name : "");
            requestData.append("email", userData.email ? userData?.email : "");

            requestData.append(
              "profile_picture",
              userData.profile_image_url
                ? userData.profile_image_url.replace("_normal", "")
                : ""
            );
            requestData.append(
              "device_token",
              localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
                ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
                : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)
            );
            dispatch(socialSignup(requestData)).then((signresponsejson) => {
              const response = signresponsejson.payload;
              const errormsg =
                response && response.data && response.data.message;
              if (response && response.status_code === STATUS_CODES.SUCCESS) {
                navigate("/dashboard");
              } else {
                Toster(t(errormsg), "error");
              }
            });
          }
        } catch (error) {
          console.error("Error obtaining access token:", error);
        }
      }
    };

    useEffect(() => {
      handleCallback();
    }, []);
  }

  return (
    <div className="app-wrapper">
      <Router basename={"/"}>
        <FireBaseNotification />
        <ScrollToTop />
        <Header />
        <div className="main-content">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <MapReportRoute>
                  <MapListReport />
                </MapReportRoute>
              }
            />
            <Route
              exact
              path="/login"
              element={<PublicRoute>{<Login />}</PublicRoute>}
            />
            <Route
              exact
              path="/join"
              element={<PublicRoute>{<Join />}</PublicRoute>}
            />
            <Route
              exact
              path="/password-reminder"
              element={<PublicRoute>{<Password />}</PublicRoute>}
            />
            <Route
              exact
              path="/password-success"
              element={<PublicRoute>{<PasswordSuccess />}</PublicRoute>}
            />
            <Route
              exact
              path="/map"
              element={
                <MapReportRoute>
                  <MapList />
                </MapReportRoute>
              }
            />
            <Route
              exact
              path="/dashboard"
              element={<PrivateRoute>{<Dashboard />}</PrivateRoute>}
            />
            <Route
              exact
              path="/new-report"
              element={<PrivateRoute>{<NewReport />}</PrivateRoute>}
            />
            <Route
              exact
              path="/report"
              element={<PrivateRoute>{<NewReport />}</PrivateRoute>}
            />
            <Route
              exact
              path="/your-report"
              element={<PrivateRoute>{<YourReport />}</PrivateRoute>}
            />
            <Route exact path="/report/:id" element={<ReportDetailsShow />} />
            <Route
              exact
              path="/messages"
              element={<PrivateRoute>{<Messages />}</PrivateRoute>}
            />
            <Route
              exact
              path="/messages/:id"
              element={<PrivateRoute>{<Chat />}</PrivateRoute>}
            />
            <Route
              exact
              path="/comments"
              element={<PrivateRoute>{<Comments />}</PrivateRoute>}
            />
            <Route
              exact
              path="/alerts"
              element={<PrivateRoute>{<Alerts />}</PrivateRoute>}
            />
            <Route
              exact
              path="/push-notification"
              element={<PrivateRoute>{<PushNotification />}</PrivateRoute>}
            />
            <Route
              exact
              path="/email-notification"
              element={<PrivateRoute>{<EmailNotification />}</PrivateRoute>}
            />
            <Route
              exact
              path="/alert-location"
              element={<PrivateRoute>{<AlertLocation />}</PrivateRoute>}
            />
            <Route
              exact
              path="/add-location"
              element={<PrivateRoute>{<UpdateLocation />}</PrivateRoute>}
            />
            <Route
              exact
              path="/profile"
              element={<PrivateRoute>{<YourProfile />}</PrivateRoute>}
            />

            <Route
              exact
              path="/profile-detail/:id"
              element={<PrivateRoute>{<ProfileDetail />}</PrivateRoute>}
            />
            <Route
              exact
              path="/login-settings"
              element={<PrivateRoute>{<LoginSettings />}</PrivateRoute>}
            />

            <Route
              exact
              path="/search-list"
              element={<PrivateRoute>{<SearchList />}</PrivateRoute>}
            />

            <Route
              exact
              path="/followers"
              element={<PrivateRoute>{<Followers />}</PrivateRoute>}
            />

            {/* ------ */}
            <Route
              exact
              path="/timeline"
              element={<PrivateRoute>{<Timeline />}</PrivateRoute>}
            />

            <Route
              exact
              path="/following"
              element={<PrivateRoute>{<Following />}</PrivateRoute>}
            />

            <Route
              exact
              path="/connect-twitter"
              element={<PrivateRoute>{<ConnectTwitter />}</PrivateRoute>}
            />

            <Route
              exact
              path="/bookmark"
              element={<PrivateRoute>{<Bookmark />}</PrivateRoute>}
            />

            <Route
              exact
              path="/recently-viewed"
              element={<PrivateRoute>{<RecentlyViewed />}</PrivateRoute>}
            />

            <Route
              exact
              path="/missing"
              element={<PrivateRoute>{<MissingAndWanted />}</PrivateRoute>}
            />

            <Route
              exact
              path="/wanted"
              element={<PrivateRoute>{<MissingAndWanted />}</PrivateRoute>}
            />

            {/* ----------------NETWORKS---------------- */}
            <Route
              exact
              path="/networks-dashboard"
              element={<PrivateRoute>{<NetworksDashboard />}</PrivateRoute>}
            />
            <Route
              exact
              path="/networks-new-report"
              element={<PrivateRoute>{<NetworksNewReport />}</PrivateRoute>}
            />
            <Route
              exact
              path="/networks-reports"
              element={<PrivateRoute>{<NetworksYourReports />}</PrivateRoute>}
            />
            <Route
              exact
              path="/networks-crime-state"
              element={<PrivateRoute>{<NetworksCrimeState />}</PrivateRoute>}
            />
            <Route
              exact
              path="/networks-messages"
              element={<PrivateRoute>{<NetworksMessage />}</PrivateRoute>}
            />
            <Route
              exact
              path="/terms-conditions"
              element={
                <MapReportRoute>
                  <Terms />
                </MapReportRoute>
              }
            />

            <Route
              exact
              path="/privacy-policy"
              element={
                <MapReportRoute>
                  <PrivacyPolicy />
                </MapReportRoute>
              }
            />

            <Route
              exact
              path="/contactus"
              element={
                <MapReportRoute>
                  <ContactUs />
                </MapReportRoute>
              }
            />

            <Route
              exact
              path="/how-it-works"
              element={
                <MapReportRoute>
                  <HowItsWork />
                </MapReportRoute>
              }
            />

            <Route
              exact
              path="/search-result"
              element={<PrivateRoute>{<SearchResult />}</PrivateRoute>}
            />

            <Route
              exact
              path="/terms-conditions-accept"
              element={<AcceptTerms />}
            />

            <Route
              exact
              path="/help-center"
              element={<PrivateRoute>{<HelpCenter />}</PrivateRoute>}
            />

            <Route
              exact
              path="/invite-friends"
              element={<PrivateRoute>{<InviteFriends />}</PrivateRoute>}
            />
            <Route
              exact
              path="/timelines"
              element={
                <MapReportRoute>
                  <TimelineGuest />
                </MapReportRoute>
              }
            />
            <Route path="/twitter-callback" element={<TwitterAuthPage />} />

            <Route
              exact
              path="/unsubscribe-email-alert"
              element={<EmailSubscribe />}
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}
export default Routers;
