import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SublyApi from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../utils/statusCode";
import Toster from "../../utils/Toaster";
import { useEffect, useState } from "react";
import Loader from "../../utils/Loader/Loader";
import styles from "./Content.module.css";
import { useForm } from "react-hook-form";
import { handleKeyPress } from "../../utils/manageConstant";
import { Validation } from "../../utils/Validation";
import { userDetailData, userLogoutClear } from "../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

function InviteFriends() {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  const onSubmit = (formdata) => {
    let requestData = new FormData();
    requestData.append("name", formdata.youName);
    requestData.append("friend_name", formdata.friendName);
    requestData.append("friend_email", formdata.friendEmail);
    setLoader(true);

    SublyApi.inviteFriends(userToken, requestData).then((response) => {
      setLoader(false);
      const msg = response && response.message;
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t(msg), "success");
        setInviteSent(true);
        reset();
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  };

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <div className={styles.inviteMainDiv}>
              <div className={styles.inviteInnerText}>
                {inviteSent && <h2>{t("FRIEND_INVITE_SENT")}</h2>}
                <h1>{t("INVITE_HEADING_TEXT")}</h1>
                <h3>{t("CRIMINALS_HAVE_A_NETWORK")}</h3>
                <div className={styles.inviteDescription}>
                  <h4>{t("KNOW_SOMEONE")}</h4>
                  <h5>{t("FILL_IN_FORM_DESCRIPTION")}</h5>
                </div>
              </div>

              <div className={styles.inviteInputFild}>
                <form
                  className={styles.inviteForm}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h3>{t("YOUR_NAME")}</h3>
                  <input
                    type="text"
                    name="name"
                    placeholder={t("ENTER_YOUR_NAME")}
                    className={styles.inputField}
                    onInputChange={(e) => handleKeyPress(e, setValue)}
                    {...register("youName", Validation.youName)}
                  />
                  {errors.youName && (
                    <span className="errorMsg">{errors.youName.message}</span>
                  )}
                  <h3>{t("NAME_OF_YOUR_FRIEND")}</h3>
                  <input
                    type="text"
                    name="friend_name"
                    placeholder={t("ENTER_YOUR_NAME")}
                    className={styles.inputField}
                    onInputChange={(e) => handleKeyPress(e, setValue)}
                    {...register("friendName", Validation.friendName)}
                  />
                  {errors.friendName && (
                    <span className="errorMsg">
                      {errors.friendName.message}
                    </span>
                  )}
                  <h3>{t("EMAIL_ADDRESS_OF_YOU_FRIEND")}</h3>
                  <input
                    type="email"
                    name="friend_email"
                    placeholder={t("ENTER_EMAIL_ADDRESS")}
                    className={styles.inputField}
                    onInputChange={(e) => handleKeyPress(e, setValue)}
                    {...register("friendEmail", Validation.friendEmail)}
                  />
                  {errors.friendEmail && (
                    <span className="errorMsg">
                      {errors.friendEmail.message}
                    </span>
                  )}
                  <button className={styles.inviteButton}>
                    {t("SEND_INVITATION")}
                  </button>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
export default InviteFriends;
