import {
  ref,
  onValue,
  push,
  set,
  get,
  update,
  remove,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import { database, storage } from "./firebase";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuid } from "uuid";
import axios from "axios";

export const getUserById = async (userId, setUserDetail = false) => {
  const userRef = ref(database, `users/${userId}`);
  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      if (setUserDetail !== false) {
        setUserDetail({ id: userId, ...snapshot.val() });
      }
      return { id: userId, ...snapshot.val() };
    } else {
      console.log(`No user found for ID: ${userId}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user with ID: ${userId}`, error);
    return null;
  }
};

export const getUserOnlineById = async (userId) => {
  const userRef = ref(database, `users/${userId}`);
  try {
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      return snapshot.val().online;
    } else {
      console.log(`No user found for ID: ${userId}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user with ID: ${userId}`, error);
    return null;
  }
};

const getUsersByIds = async (userIds) => {
  const userPromises = userIds.map((id) => getUserById(id));
  const users = await Promise.all(userPromises);
  return users.filter((user) => user !== null);
};

// Function to fetch chat history
export const ChatHistory = (userInfo, setMessageList) => {
  const messagesRef = ref(database, "chat_history/" + userInfo.userID);
  const usersRef = ref(database, "users");

  const unsubscribeMessages = onValue(messagesRef, async (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const usersData = await getUsersByIds(Object.keys(data));
      const messageList = Object.keys(data).map((key) => {
        const message = data[key];
        const user = usersData.find((obj) => obj.id == key);

        return {
          id: key,
          file_name: message.file_name || "",
          profile_pic: user?.profile_pic || "",
          profile_url: user.profile_url,
          message: message.message || "",
          user_id: message.user_id || "",
          timestamp: message.timestamp || "",
          unread_count: message.unread_count || 0,
          user_name: user ? user.user_name : "Unknown User",
          image: message.image || "",
          image_url: message.image_url || "",
          media_duration: message.media_duration || "",
          msg_read_tick: message.msg_read_tick || 0,
          type: message.type || "",
          online: user?.online,
        };
      });
      setMessageList(messageList);
    } else {
      setMessageList([]);
    }
  });
  return () => {
    unsubscribeMessages();
  };
};

// New function to reset unread count & chatCount
export const resetUnreadCount = async (currentUserId, receiverId) => {
  try {
    const chatHistoryRef = ref(
      database,
      `chat_history/${currentUserId}/${receiverId}`
    );

    // const recipientChatHistoryRef = ref(
    //   database,
    //   `chat_history/${recipientId}/${currentUserId}`
    // );

    const snapshotRec = await get(chatHistoryRef);

    const currentUser = await getUserById(currentUserId);

    const userData = snapshotRec.val();

    const userUnredCount = await parseInt(Number(userData?.unread_count));

    // if (userData && currentUser && userUnredCount>0) {
    //   const chatCount = await parseInt(Number(currentUser?.chat_count));
    //   console.log('chatCount',chatCount)
    //   const updateObject = {
    //     chat_count: (chatCount>0?chatCount-1:0)
    //   };
    //   await updateUserData(currentUserId,updateObject)
    // }

    // if(userData){
    //   await update(chatHistoryRef, {
    //     unread_count: "0",
    //     unreadCount: "0",
    //   });
    // }
  } catch (error) {
    console.error("Error resetting unread count: ", error);
  }
};

export const resetOnlyUnreadCount = async (currentUserId, receiverId) => {
  try {
    const chatHistoryRef = ref(
      database,
      `chat_history/${currentUserId}/${receiverId}`
    );

    // const recipientChatHistoryRef = ref(
    //   database,
    //   `chat_history/${recipientId}/${currentUserId}`
    // );

    const snapshotRec = await get(chatHistoryRef);

    const currentUser = await getUserById(currentUserId);

    const userData = snapshotRec.val();

    const userUnredCount = await parseInt(Number(userData?.unread_count));

    if (userData && currentUser && userUnredCount > 0) {
      const chatCount = await parseInt(Number(currentUser?.chat_count));

      const updateObject = {
        chat_count: chatCount > 0 ? chatCount - 1 : 0,
      };
      await updateUserData(currentUserId, updateObject);
    }

    if (userData) {
      await update(chatHistoryRef, {
        unread_count: "0",
        unreadCount: "0",
      });
    }
  } catch (error) {
    console.error("Error resetting unread count: ", error);
  }
};

// Object with methods related to fetching chat room data
export const FireBaseChat = (userInfo, setChatList, id, setUserData) => {
  const messagesRef = ref(database, "chat_rooms/" + userInfo.userID + "/" + id);
  const usersRef = ref(database, "users/" + id);
  const userNameRef = ref(database, "users");

  const unsubscribeMessages = onValue(messagesRef, async (snapshot) => {
    const data = snapshot.val();

    const chatHistoryReciverRef = ref(
      database,
      "chat_history/" + userInfo.userID + "/" + id
    );

    const receiverUser = await getUserById(id);
    if (receiverUser) {
      try {
        const snapshot = await get(chatHistoryReciverRef);
        if (snapshot.exists()) {
          const chatHistory = snapshot.val();
          const receiverUserNew = {
            ...receiverUser,
            unread_count: chatHistory.unread_count,
          };
          setUserData(receiverUserNew);
        } else {
          setUserData({ ...receiverUser, unread_count: 0 });
        }
      } catch (error) {
        console.error(`Error fetching user with ID: `, error);
      }
    }

    // const senderUser = await getUserById(userInfo.userID);
    // if (senderUser) {
    //   setSenderUserData(senderUser);
    // }

    if (data) {
      const unsubscribeUserName = onValue(userNameRef, (userNameSnapshot) => {
        const userNames = userNameSnapshot.val();

        const chatList = Object.keys(data)
          .filter((key) => !data[key].isBlocked) // Filter out blocked users
          .map((key) => {
            const message = data[key];

            const userShowName = userNames[message.user_id] || {};
            return {
              id: key,
              file_name: message.file_name || "",
              profile_pic: userShowName.profile_pic || "",
              message: message.message || "",
              user_id: message.user_id || "",
              timestamp: message.timestamp || 0,
              msg_read_tick: message.msg_read_tick || 0,
              unread_count: message.unread_count || 0,
              user_name: userShowName.user_name || "Unknown User",
              image: message.image || "",
              image_url: message.image_url || "",
              media_duration: message.media_duration || "",
              type: message.type || "",
            };
          });
        setChatList(chatList);
      });

      return () => {
        unsubscribeUserName();
      };
    } else {
      setChatList([]);
    }
  });
  return () => {
    unsubscribeMessages();
  };
};

export const MessageCount = (userInfo, setCount) => {
  const currentUserDataRef = ref(database, "users/" + userInfo.userID);
  const unsubscribe = onValue(currentUserDataRef, (snapshot) => {
    const userData = snapshot.val();
    if (userData) {
      setCount(userData.chat_count);
    }
  });
  return () => {
    unsubscribe();
  };
};

export const GetAlertCount = (userInfo, setCount) => {
  const currentUserDataRef = ref(database, "users/" + userInfo.userID);
  const unsubscribe = onValue(currentUserDataRef, (snapshot) => {
    const userData = snapshot.val();
    if (userData) {
      setCount(userData.alert_count);
    }
  });
  return () => {
    unsubscribe();
  };
};

// Common function to send a message and update chat history
export const sendMessage = async (
  currentUser,
  recipientId,
  messageContent,
  selectedImage,
  setPreviewImage
) => {
  const newMessage = {
    file_name: "",
    image: 0,
    image_url: "",
    media_duration: "00:00:00",
    message: messageContent.trim(),
    msg_read_tick: 1,
    timestamp: Date.now(),
    type: "Chat",
    unread_count: 0,
    user_id: currentUser.userID,
  };

  // Check if there's an image to upload
  if (selectedImage) {
    const fileType = selectedImage.type.startsWith("video") ? "Video" : "Image";
    const fileExtension = selectedImage.name.split(".").pop();
    const fileRef = storageRef(
      storage,
      `usersImage/${uuid()}.${fileExtension}`
    );
    const snapshot = await uploadBytes(fileRef, selectedImage);
    const downloadURL = await getDownloadURL(snapshot.ref);

    newMessage.image_url = downloadURL;
    newMessage.type = fileType;
    newMessage.image = fileType === "Image" ? 1 : 0;
    setPreviewImage(downloadURL);
  }

  // Reference to the chat rooms for both sender and receiver
  const senderRef = ref(
    database,
    `chat_rooms/${currentUser.userID}/${recipientId}`
  );
  const recipientRef = ref(
    database,
    `chat_rooms/${recipientId}/${currentUser.userID}`
  );

  // Reference to chat history for both sender and receiver
  const senderChatHistoryRef = ref(
    database,
    `chat_history/${currentUser.userID}/${recipientId}`
  );
  const recipientChatHistoryRef = ref(
    database,
    `chat_history/${recipientId}/${currentUser.userID}`
  );

  const snapshotRec = await get(recipientChatHistoryRef);

  const recUser = await getUserById(recipientId);

  const receiverData = snapshotRec.val();
  const reciverCount =
    (receiverData?.unread_count
      ? parseInt(Number(receiverData?.unread_count))
      : 0) + 1;

  if (reciverCount == 1) {
    if (recUser) {
      const updateObject = {
        chat_count: parseInt(recUser.chat_count) + 1,
      };
      updateUserData(recipientId, updateObject);
    }
  }
  const receiverMessage = {
    ...newMessage,
    unread_count:
      (receiverData?.unread_count
        ? parseInt(Number(receiverData?.unread_count))
        : 0) + 1,
  };

  // Function to send message to chat room
  const sendMessageToRoom = (ref) => push(ref, newMessage);

  // Function to update chat history
  const updateChatHistory = async (chatHistoryRef, message) => {
    await set(chatHistoryRef, message);
  };

  try {
    // Send message to both sender's and recipient's chat rooms
    await Promise.all([
      sendMessageToRoom(senderRef),
      sendMessageToRoom(recipientRef),
    ]);

    // Update chat history for both sender and receiver
    await Promise.all([
      updateChatHistory(senderChatHistoryRef, newMessage),
      updateChatHistory(recipientChatHistoryRef, receiverMessage),
    ]);
  } catch (error) {
    console.error("Error sending message: ", error);
  }
};

// Function to get block status
export const getBlockStatus = (
  currentUserId,
  opponentUserId,
  setBlockCurrentStatus
) => {
  const blockUserNode =
    parseInt(currentUserId) > parseInt(opponentUserId)
      ? `${opponentUserId}-${currentUserId}`
      : `${currentUserId}-${opponentUserId}`;

  const blockUsersRef = ref(database, `block_users/${blockUserNode}`);

  // const snapshot = await get(blockUsersRef);
  const unsubscribe = onValue(blockUsersRef, (snapshot) => {
    const data = snapshot.val();

    if (snapshot.exists()) {
      const blockedBy = snapshot.val().blockedBy;
      if (blockedBy === currentUserId) {
        setBlockCurrentStatus(1);
      } else if (blockedBy === opponentUserId) {
        setBlockCurrentStatus(2);
      } else if (blockedBy === "Both") {
        setBlockCurrentStatus(3);
      } else {
        setBlockCurrentStatus(0);
      }
    } else {
      setBlockCurrentStatus(0);
    }
  });

  return () => {
    unsubscribe(); // Cleanup listener on unmount
  };
};

// Function to block a user
export const blockUser = async (currentUserId, opponentUserId) => {
  const blockUserNode =
    parseInt(currentUserId) > parseInt(opponentUserId)
      ? `${opponentUserId}-${currentUserId}`
      : `${currentUserId}-${opponentUserId}`;

  try {
    const blockUsersRef = ref(database, `block_users/${blockUserNode}`);
    const snapshot = await get(blockUsersRef);
    if (snapshot.exists()) {
      const blockedBy = snapshot.val().blockedBy;
      if (blockedBy == opponentUserId) {
        const res = await set(ref(database, `block_users/${blockUserNode}`), {
          blockedBy: "Both",
        });
      } else {
        const res = await set(ref(database, `block_users/${blockUserNode}`), {
          blockedBy: currentUserId,
        });
      }
    } else {
      await set(ref(database, `block_users/${blockUserNode}`), {
        blockedBy: currentUserId,
      });
    }

  } catch (error) {
    console.error("Error blocking user: ", error);
  }
};

// Function to unblock a user
export const unblockUser = async (currentUserId, opponentUserId) => {
  const blockUserNode =
    parseInt(currentUserId) > parseInt(opponentUserId)
      ? `${opponentUserId}-${currentUserId}`
      : `${currentUserId}-${opponentUserId}`;

  try {
    const blockUsersRef = ref(database, `block_users/${blockUserNode}`);
    const snapshot = await get(blockUsersRef);
    if (snapshot.exists()) {
      const blockedBy = snapshot.val().blockedBy;
      if (blockedBy === "Both") {
        await set(blockUsersRef, { blockedBy: opponentUserId });
      } else if (blockedBy === currentUserId) {
        await remove(blockUsersRef);
      }
    }
  } catch (error) {
    console.error("Error unblocking user: ", error);
  }
};

export const clearChatData = async (currentUserId, otherUserId) => {
  try {
    const chatHistoryRef = ref(
      database,
      `chat_history/${currentUserId}/${otherUserId}`
    );
    const chatRoomRef = ref(
      database,
      `chat_rooms/${currentUserId}/${otherUserId}`
    );

    await update(chatHistoryRef, {
      message: "",
      image: "",
      image_url: "",
    });

    await Promise.all([
      // remove(chatHistoryRef),
      remove(chatRoomRef),
      // set(chatHistoryRef, ""),
      // set(chatRoomRef, "")
    ]);
  } catch (error) {
    console.error("Error clearing chat data: ", error);
  }
};

export const updateUserData = async (currentUserId, objData) => {
  try {
    const chatUserRef = ref(database, `users/${currentUserId}`);
    const res = await update(chatUserRef, objData);
  } catch (error) {
    console.error("Error clearing chat data: ", error);
  }
};
