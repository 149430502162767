import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { FaRegFlag } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../../../helpers/Api";
import Toster from "../../../../utils/Toaster";
import { STATUS_CODES } from "../../../../utils/statusCode";
import styles from "../Flag/Flag.module.css";
import { FLAG_REASONS, REASON_LABELS } from "../../../../utils/Constants";
import { userLogoutClear } from "../../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

const FlagPopup = ({
  reportDetails,
  flagPopupHandleClose,
  flagShow,
  setThanksflagShow,
}) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const flagReport = async (reason) => {
    const requestData = new FormData();
    requestData.append("reason", reason);
    setLoader(true);

    try {
      const response = await SublyApi.PostFlag(
        userToken,
        reportDetails.reportID,
        requestData
      );
      setLoader(false);

      if (response.status_code === STATUS_CODES.SUCCESS) {
        Toster(t("REPORT_FLAGGED_SUCCESSFULL"), "success");
        setThanksflagShow(
          response.message !== "You have already flagged Report"
        );
        if (response.message !== "You have already flagged Report") {
          flagPopupHandleClose(true);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(response.data?.message), "error");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Modal className="mainModule" show={flagShow} onHide={flagPopupHandleClose}>
      <div className="MainBox">
        <div className="header">
          <span className="alertCross">
            <RxCross2 onClick={flagPopupHandleClose} />
          </span>
        </div>
        <div className="hadingMainDiv">
          <div className={styles.moduleHeding}>
            <h2>{t("FLAG_REPORT")}</h2>
            <span>
              <FaRegFlag />
            </span>
          </div>
          <div className={styles.moduleHedingInner}>
            <h2>
              {t("IS_THE_REPORT_INCORRECT")} <br />
              <span>
                {t("FLAG_IT_BY_CLICKING_ON_A_RESON")} <br />
                {t("ONCE_A_REPORT_HAS_BEEN_FLAGGED")}
                <br />
                {t("TIME_IT_WILL")}
              </span>
            </h2>
          </div>
          <div className={styles.flagBtn}>
            {Object.keys(FLAG_REASONS).map((key) => (
              <button key={key} onClick={() => flagReport(FLAG_REASONS[key])}>
                {REASON_LABELS[FLAG_REASONS[key]]}
              </button>
            ))}
            <button
              onClick={flagPopupHandleClose}
              className={styles.flagCancleButton}
            >
              {t("CANCEL")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FlagPopup;
