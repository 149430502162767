import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import styles from "./Alert.module.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import cancel from "../../../assets/Images/cancel.svg";
import UserCommonButton from "../../../commonComponent/CommonButton/UserCommonButton";
import SublyApi from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODES } from "../../../utils/statusCode";
import Toster from "../../../utils/Toaster";
import { isLimit, offset } from "../../../utils/Constants";
import { formatTime } from "../../../utils/manageConstant";
import Loader from "../../../utils/Loader/Loader";
import AlertClearPopup from "../Alert/AlertClearPopup";
import AlertPupupDelete from "./AlertPupupDelete";
import {
  updateSettingCount,
  userDetailData,
  userLogoutClear,
} from "../../../store/slices/Userslice";
import { useNavigate } from "react-router-dom";

//---function for user alerts---------
function Alert() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, currentUser } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [alertList, setAlertList] = useState("");

  const [page, setPage] = useState(1);
  const [totalReports, setTotalReports] = useState("");
  // const [disable, setDisable] = useState(false);

  // alert delete state
  const [deleteAlertShow, setDeleteAlertShow] = useState(false);
  const DeleteHandleAlertShow = () => setDeleteAlertShow(true);
  const DeleteHandleAlertClose = () => setDeleteAlertShow(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const [alertId, setAlertId] = useState(false);

  // const DeleteHandleShow = () => setDeleteShow(true);
  const DeleteHandleClose = () => setDeleteShow(false);

  useEffect(() => {
    userToken && dispatch(userDetailData(userToken));
  }, []);

  // -- getAlerts details
  async function getAlertsDetails(page = 1) {
    setLoader(true);
    await SublyApi.getAlerts(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (
        response &&
        response.data &&
        response.status_code === STATUS_CODES.SUCCESS
      ) {
        const sortedReports = response.data.alertsList;
        setTotalReports(response?.data?.total_alerts);

        const count = response?.data?.total_alerts || 0;
        dispatch(
          updateSettingCount({
            keyName: "total_alerts",
            value: count,
          })
        );

        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setAlertList(sortedReports);
          } else {
            setAlertList((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setAlertList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  useEffect(() => {
    getAlertsDetails();
  }, []);

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    getAlertsDetails(page + 1);
  };

  const TimeLineViewDetails = (parent_id) => {
    navigate(`/report/${parent_id}`);
  };

  const TimeLineViewProfile = (sender_user_id) => {
    navigate(`/profile-detail/${sender_user_id}`);
  };

  return (
    <>
      <div className="main">
        {loader && <Loader />}
        <div className="spaceTopSet">
          <Container>
            <Row>
              <Col lg={3} md={4}>
                <Sidebar />
              </Col>
              <Col lg={9} md={8} className="rightPart">
                <div className="rightTopPart">
                  <h2>{t("ALERTS")}</h2>
                  {alertList?.length > 0 && (
                    <UserCommonButton
                      type="button"
                      onClick={() => DeleteHandleAlertShow()}
                      label={t("CLEAR_ALERT")}
                    />
                  )}
                </div>
                <div className={styles.alertBoxes}>
                  <div className={styles.scrollTopAlertLocation}>
                    {alertList && alertList?.length > 0 ? (
                      <>
                        {alertList &&
                          alertList.map((item, index) => (
                            <div
                              className={`alert ${styles.alertDiv}`}
                              role="alert"
                              key={index}
                            >
                              <p style={{ cursor: "pointer" }}>
                                {item && item.type !== "new_follower" ? (
                                  <strong
                                    onClick={() =>
                                      TimeLineViewDetails(
                                        item && item.parent_id
                                      )
                                    }
                                  >
                                    {(item &&
                                      item.report_data &&
                                      item.report_data.category_name ==
                                        undefined) ||
                                    (item &&
                                      item.report_data &&
                                      item.report_data.category_name == "") ? (
                                      ""
                                    ) : (
                                      <>
                                        {item &&
                                          item.report_data &&
                                          item.report_data.category_name}
                                        &nbsp; - &nbsp;
                                      </>
                                    )}
                                  </strong>
                                ) : (
                                  <strong
                                    style={{ textTransform: "capitalize" }}
                                    onClick={() =>
                                      TimeLineViewProfile(
                                        item && item.sender_user_id
                                      )
                                    }
                                  >
                                    @{item && item.sender_name}
                                    &nbsp; - &nbsp;
                                  </strong>
                                )}

                                {item && item.type !== "new_follower" ? (
                                  <o
                                    onClick={() =>
                                      TimeLineViewDetails(
                                        item && item.parent_id
                                      )
                                    }
                                  >
                                    {(item &&
                                      item.report_data &&
                                      item.report_data.location == undefined) ||
                                    item.report_data.location == "" ? (
                                      ""
                                    ) : (
                                      <>
                                        {item &&
                                          item.report_data &&
                                          item.report_data.location}
                                        &nbsp; - &nbsp;
                                      </>
                                    )}
                                  </o>
                                ) : (
                                  ""
                                )}

                                {item && item.type !== "new_follower" ? (
                                  <>
                                    <span
                                      onClick={() =>
                                        TimeLineViewDetails(
                                          item && item.parent_id
                                        )
                                      }
                                    >
                                      {item &&
                                        item.report_data &&
                                        item.report_data.date}{" "}
                                      {t("At")}{" "}
                                      {formatTime(
                                        item &&
                                          item.report_data &&
                                          item.report_data.time
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <span
                                    onClick={() =>
                                      TimeLineViewProfile(item.sender_user_id)
                                    }
                                  >
                                    {t("FOLLOWED_YOU")}
                                  </span>
                                )}
                              </p>
                              {/* <img
                                src={cancel}
                                alt="cancel icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  DeleteHandleShow();
                                  setAlertId(item.alertID);
                                }}
                              /> */}
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <div className="records_Found spaceRemove">
                          <h2>{t("NO_REC_FOUND")}</h2>
                        </div>
                      </>
                    )}

                    {alertList?.length < totalReports ? (
                      <div
                        className="loadMoreSection seprateLine"
                        style={{ backgroundColor: "#FCF8E3" }}
                      >
                        <button
                          className="loadMoreBtn"
                          onClick={loadMoreReports}
                        >
                          {t("LOAD_MORE")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <AlertClearPopup
        DeleteHandleAlertClose={DeleteHandleAlertClose}
        deleteAlertShow={deleteAlertShow}
        setAlertList={setAlertList}
        getAlertsDetails={getAlertsDetails}
        alertList={alertList}
        // disable={disable}
        // setDisable={setDisable}
        setLoader={setLoader}
      />
      <AlertPupupDelete
        DeleteHandleClose={DeleteHandleClose}
        deleteShow={deleteShow}
        alertId={alertId}
        setAlertList={setAlertList}
        alertList={alertList}
      />
    </>
  );
}
export default Alert;
