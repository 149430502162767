import { NavLink, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../store/slices/Userslice";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import { useState } from "react";
import Loader from "../../utils/Loader/Loader";
import Toster from "../../utils/Toaster";
// import "../../assets/Styles/Common.css";

//---function for user sidebar---------
function NetWorksSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);

  return (
    <div>
      {loader && <Loader />}
      <div className="sidebarNetworks">
        <div className="sideTopBox">
          <h6>{t("FOURWAYS_CPF")}</h6>
          <span>@fourwayscpf</span>
        </div>
      </div>
      <div className="sideBar">
        <h5 className="sideHeading">{t("CLOSED_NETWORK")}</h5>
        <NavLink to="/networks-dashboard" className="userActive">
          <div className="displayOption">
            <p>{t("DASHBOARD")}</p>
          </div>
        </NavLink>
        <NavLink to="/networks-new-report" className="userActive">
          <div className="displayOption">
            <p>{t("NEW_REPORT")}</p>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("NETWORK_REPORTS")}</p>
            <span>2,321</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("CRIME_STATE")}</p>
            <span>{t("CREATE_REPORT")}</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("MESSAGE")}</p>
            <span>3</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("COMMENTS")}</p>
            <span>67</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("ALERTS")}</p>
            <span>1</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("PUSH_NOTIFY")}</p>
            <span>ON</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("EMAIL_NOTIFY")}</p>
            <span>ON</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("ALERT_LOCATION")}</p>
            <span>0</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("YOUR_PROFILE")}</p>
            <span>
              <CgProfile />
            </span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("LOGIN_SETTING")}</p>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("FOLLOWERS")}</p>
            <span>34</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("FOLLOWING")}</p>
            <span>1,234</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("NETWORK")}</p>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("CONNECT_TWITTER")}</p>
            <span>@cadredoom</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("CONNECT_FB")}</p>
            <span>@bedfordviewcpf</span>
          </div>
        </NavLink>
        <NavLink to="#" className="userActive">
          <div className="displayOption">
            <p>{t("HELP_CENTER")}</p>
          </div>
        </NavLink>
        <NavLink to="/terms-conditions" className="userActive">
          <div className="displayOption">
            <p>{t("TERMS")}</p>
          </div>
        </NavLink>
        <NavLink to="/privacy-policy" className="userActive">
          <div className="displayOption">
            <p>{t("PRIVACY")}</p>
          </div>
        </NavLink>
        <NavLink className="userActive">
          <div className="displayOption">
            <p>{t("LOGOUT")}</p>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
export default NetWorksSidebar;
