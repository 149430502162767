import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import google from "../../assets/Images/social-button-2.svg";
import axios from "axios";
import { useEffect } from "react";
import { socialSignup } from "../../store/slices/Userslice";
import Toster from "../../utils/Toaster";
import { STATUS_CODES } from "../../utils/statusCode";
import { SOCIAL_TYPE } from "../../utils/Constants";

//---------function for google social api call----------
function GoogleSocial() {
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Social Login with google
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          responseGoogle(res.data);
        })
        .catch((err) => err);
    }
  }, [user]);

  // Social Login with Google
  const responseGoogle = async (response) => {
    let userData = response;
    if (userData) {
      let requestData = new FormData();
      requestData.append("social_id", userData.id ? userData.id.trim() : "");
      requestData.append("social_type", SOCIAL_TYPE.GOOGLE);
      requestData.append("name", userData.name ? userData.name.trim() : "");
      requestData.append("email", userData.email ? userData.email.trim() : "");
      requestData.append(
        "profile_picture",
        userData.picture ? userData.picture : ""
      );
      requestData.append(
        "device_token",
        localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
          ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
          : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)
      );
      dispatch(socialSignup(requestData)).then((signresponsejson) => {
        const response = signresponsejson.payload;
        const errormsg = response && response.data && response.data.message;
        if (response && response.status_code === STATUS_CODES.SUCCESS) {
          navigate("/dashboard");
        } else {
          Toster(t(errormsg), "error");
        }
      });
    }
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <img src={google} alt="google" onClick={() => login()} />
      </div>
    </>
  );
}
export default GoogleSocial;
