import { Form } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";

//--------Common search input for user module----------
function UserSearchModule({ hadleSearch, search }) {
  return (
    <div className="searchBox">
      <Form.Group>
        <Form.Control
          type="text"
          className="searchInput"
          placeholder="Search"
          value={search}
          onChange={(e) => hadleSearch(e)}
        />
      </Form.Group>
      <FiSearch />
    </div>
  );
}

export default UserSearchModule;
