import { useState } from "react";
import getIconByCategoryType from "../../commonComponent/CategoryIcons";
import styles from "../UserModule/NewReport/NewReport.module.css";
import UserCommonButton from "../../commonComponent/CommonButton/UserCommonButton";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function MultipleCategoryModal({
  setShow,
  crimesCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  mapListShow,
  endDateSelect,
  startDateSelect,
  selectedRange,
  center,
  searchAddress,
  setPage
}) {
  const { t } = useTranslation();
  const localtion = useLocation();

  // Initialize selected subcategories as an empty array if not provided
  const [selectedSubCategories, setSelectedSubCategories] = useState(
    selectedSubCategory || []
  );

  // Function to handle sub-category selection
  const handleSubCategorySelect = (subCategory) => {
    const isSelected = selectedSubCategories.some(
      (selected) => selected.id === subCategory.categoryId
    );
    if (isSelected) {
      // Remove the selected sub-category
      setSelectedSubCategories(
        selectedSubCategories.filter(
          (selected) => selected.id !== subCategory.categoryId
        )
      );
    } else {
      setSelectedSubCategories([
        ...selectedSubCategories,
        {
          id: subCategory.categoryId,
          name: subCategory.category_name,
          field_type: subCategory.field_type,
        },
      ]);
    }
  };

  const getCategoryRowSpan = (subCategoryLength) => {
    return Math.ceil((subCategoryLength + 1) / 4);
  };
  const handleCancel = () => {
    setShow(false);
  };

  const handleSelectAll = () => {
    const allSubCategories = crimesCategory.categories.flatMap((category) =>
      category.sub_category.map((subCategory) => ({
        id: subCategory.categoryId,
        name: subCategory.category_name,
        field_type: subCategory.field_type,
      }))
    );
    setSelectedSubCategories(allSubCategories);
  };

  const handleDeselectAll = () => {
    setSelectedSubCategories([]);
  };

  const handleApply = async () => {
    const selectedIds = selectedSubCategories
      .map((subCategory) => subCategory.id)
      .join(",");
    setSelectedSubCategory(selectedSubCategories);
    setShow(false);
    if (
      localtion.pathname == "/timeline" ||
      localtion.pathname == "/timelines"
    ) {
      await mapListShow(
        1,
        center?.lat,
        center?.lng,
        startDateSelect,
        endDateSelect,
        selectedIds,
        searchAddress
      );
      setPage(1)
    } else {
      await mapListShow(
        center.lat,
        center.lng,
        startDateSelect,
        selectedRange,
        endDateSelect,
        selectedIds
      );
    }
  };

  return (
    <div className={styles.showOptions}>
      <div className={styles.gridContainer}>
        {crimesCategory.categories &&
          crimesCategory.categories.map((category) => {
            const rowSpan = getCategoryRowSpan(category.sub_category.length);
            return (
              <div
                key={category.category_id}
                className={styles.categoryBox}
                style={{ gridRowEnd: `span ${rowSpan}` }}
              >
                <div className={styles.mainCategory}>
                  <img
                    src={getIconByCategoryType(category.crime_type)}
                    alt="icon"
                  />
                  <h5>{category.category_name}</h5>
                </div>

                {category.sub_category.map((subCategory) => (
                  <div
                    key={subCategory.categoryId}
                    className={`${styles.subCategory} ${
                      selectedSubCategories.some(
                        (selected) => selected.id === subCategory.categoryId
                      )
                        ? styles.selected
                        : ""
                    }`}
                    onClick={() => handleSubCategorySelect(subCategory)}
                  >
                    <div
                      className={`${styles.displayOptionss} ${styles.displayOptionssCls}`}
                    >
                      <Form.Check
                        type="checkbox"
                        id="Checkbox1"
                        checked={selectedSubCategories.some(
                          (selected) => selected.id === subCategory.categoryId
                        )}
                        className="customCheckbox"
                      />
                      <p>{subCategory.category_name}</p>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
      </div>
      <div className={styles.categoryModalButton}>
        <div className={styles.categoryButton}>
          <UserCommonButton
            type="button"
            label={t("CANCEL")}
            onClick={handleCancel}
          />
        </div>
        <div className={styles.categoryButton}>
          <UserCommonButton
            type="button"
            label={t("SELECT_ALL")}
            onClick={handleSelectAll}
          />
        </div>
        <div>
          <UserCommonButton
            type="button"
            label={t("DESELECT_ALL")}
            onClick={handleDeselectAll}
          />
        </div>
        <Button
          type="submit"
          className={styles.selectButton}
          onClick={handleApply}
        >
          {t("SUBMIT_SELECTION")}
        </Button>
      </div>
    </div>
  );
}
export default MultipleCategoryModal;
