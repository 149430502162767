import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//--------Common textarea input for user module----------
function TextArea({
  name,
  placeholder,
  onInputChange,
  register,
  validationRules = {},
}) {
  const { t } = useTranslation();
  return (
    <Form.Control
      as="textarea"
      rows={4}
      placeholder={t(placeholder)}
      {...(name
        ? register(name, {
            onChange: (e) => onInputChange(e),
            ...validationRules,
          })
        : {})}
    />
  );
}

export default TextArea;
