import { Form, InputGroup } from "react-bootstrap";
import styles from "./Fields.module.css";
import { sanitizeHtmlTags } from "../utils/sanitizeHtmlTags";

// URL validation regular expression
const urlRegex = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/;

//--------Common input group for user module----------
function InputGroups({
  name,
  register,
  onInputChange,
  errors,
  type,
  validationRules,
  validate,
  icon,
  placeholder,
  maxCount,
  label,
  isRightIcon,
  text,
  isHideGroup,
  isRequired,
  IconComponent,
  iconColor,
  onIconClick,
  usernameAvailable,
}) {
  return (
    <>
      <Form.Group className="mb-3">
        <div className={styles.profileFlex}>
          <Form.Label>
            {label}
            {isRequired && (
              <strong className={styles.requiredAstrick}>*</strong>
            )}
          </Form.Label>
          <h6>{maxCount}</h6>
        </div>
        <div className={styles.profileInputs}>
          <InputGroup className="formCls">
            {!isHideGroup && (
              <InputGroup.Text
                id="basic-addon1"
                className={styles.inputGroup}
                style={{ background: icon.color }}
                onClick={onIconClick}
              >
                {icon.image}
              </InputGroup.Text>
            )}
            <Form.Control
              type={type}
              placeholder={placeholder}
              className="inputSelect"
              {...register(name, {
                onChange: (e) => onInputChange(e),
                ...validationRules,
                ...sanitizeHtmlTags(),
                validate,
              })}
            />
          </InputGroup>

          {isRightIcon && (
            <IconComponent
              className={styles.checkIcon}
              style={{ color: iconColor }}
            />
          )}
        </div>
        <p className={styles.bottomText}>{text}</p>
        {errors[name] && (
          <span className="errorMsg">{errors[name].message}</span>
        )}
        {usernameAvailable && (
          <span>
            {usernameAvailable.is_available == 1 ? (
              <strong className="profileUserGreen">
                {usernameAvailable.message}
              </strong>
            ) : (
              <strong className="errorMsg">{usernameAvailable.message}</strong>
            )}
          </span>
        )}
      </Form.Group>
    </>
  );
}

export default InputGroups;
