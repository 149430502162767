import { useLocation, useNavigate } from "react-router-dom";
import searchStyles from "./Header.module.css";
import reportStyles from "../../appComponent/UserModule/YourReport/YourReport.module.css";
import icon from "../../assets/Images/Group.svg";
import { Button, Container } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import { useTranslation } from "react-i18next";
import Loader from "../../utils/Loader/Loader";
import { isLimit, offset } from "../../utils/Constants";
import Toster from "../../utils/Toaster";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../helpers/Api";
import { STATUS_CODES } from "../../utils/statusCode";
import { useEffect, useState } from "react";
import viewIcon from "../../assets/Images/Views.svg";
import bookmarkIcon from "../../assets/Images/Bookmark.svg";
import trendIcon from "../../assets/Images/Trend.svg";
import commentIcon from "../../assets/Images/Comment.svg";
import { formatTime } from "../../utils/manageConstant";
import {
  setSearchCountApi,
  setSearchFilterQuery,
  userLogoutClear,
} from "../../store/slices/Userslice";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import defaultImage from "../../assets/Images/user_profile_Default_image.png";

//----------function for serach result show--------------
function SearchResult() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchFilterQuery, searchCheckboxesQuery, searchCountApi } =
    useSelector((state) => state.user);

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { userToken } = useSelector((state) => state.user);
  const [totalReports, setTotalReports] = useState("");
  const [showDataList, setShowDataList] = useState([]);
  const [page, setPage] = useState(1);

  const location = useLocation();

  const loadMoreReports = () => {
    setPage((prevPage) => prevPage + 1);
    SearchList(page + 1);
  };

  // useEffect(() => {
  //   searchFilterQuery && SearchList();
  // }, [searchCountApi]);

  useEffect(() => {
    if (searchFilterQuery) {
      SearchList();
    }
  }, [searchCountApi]);

  async function SearchList(page = 1) {
    setLoader(true);
    await SublyApi.reportSearchList(
      userToken,
      isLimit,
      offset + (page - 1) * isLimit,
      searchCheckboxesQuery[0]?.checked
        ? searchCheckboxesQuery[0]?.checked
        : false,
      searchCheckboxesQuery[1]?.checked
        ? searchCheckboxesQuery[1]?.checked
        : false,
      searchCheckboxesQuery[2]?.checked
        ? searchCheckboxesQuery[2]?.checked
        : false,
      searchCheckboxesQuery[3]?.checked
        ? searchCheckboxesQuery[3]?.checked
        : false,
      searchCheckboxesQuery[4]?.checked
        ? searchCheckboxesQuery[4]?.checked
        : false,
      searchCheckboxesQuery[5]?.checked
        ? searchCheckboxesQuery[5]?.checked
        : false,
      searchCheckboxesQuery[6]?.checked
        ? searchCheckboxesQuery[6]?.checked
        : false,
      searchFilterQuery
    ).then((response) => {
      setLoader(false);
      const errormsg = response && response.data && response.data.message;
      if (response && response.status_code === STATUS_CODES.SUCCESS) {
        const sortedReports = response?.data?.reports;
        setTotalReports(response?.data?.total_reports);
        if (sortedReports && sortedReports.length > 0) {
          if (page === 1) {
            setShowDataList(sortedReports);
          } else {
            setShowDataList((prev) => [...prev, ...sortedReports]);
          }
        } else {
          setShowDataList([]);
        }
      } else if (
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "ACCOUNT_DISABLED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "SESSION_EXPIRED") ||
        (response?.data?.status_code === STATUS_CODES.BAD_REQUEST &&
          response?.data?.error_type === "INVALID_TOKEN")
      ) {
        dispatch(userLogoutClear());
        navigate("/");
        Toster(t("YOUR_SESSTION_HASBEEN_EXPIRED"), "error");
      } else {
        Toster(t(errormsg), "error");
      }
    });
  }

  const handleClear = async () => {
    setShowDataList([]);
    dispatch(setSearchFilterQuery(""));
    navigate("/");
  };

  // useEffect(() => {
  //   const localKey = localStorage.getItem("searchKey");
  //   if (localKey) {
  //     dispatch(setSearchFilterQuery(localKey));
  //     dispatch(setSearchCountApi(searchCountApi + 1));
  //   }
  // }, []);

  return (
    <div className="main">
      {loader && <Loader />}
      <div className={searchStyles.bgTopDiv}>
        <Container>
          <div className={searchStyles.bgTopDivDisplay}>
            <p>
              <span>{totalReports ? totalReports : 0} </span> {t("RESULT_FOR")}{" "}
              "{searchFilterQuery}"
            </p>
            <div className={searchStyles.cancelBtns}>
              <Button type="button" onClick={handleClear}>
                {t("CLEAR")} <ImCancelCircle />
              </Button>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="rightPart">
          <div className={searchStyles.topSpace}>
            {showDataList && showDataList.length > 0 ? (
              showDataList.map((item, index) => (
                <>
                  {!item.reportID && (
                    <div
                      className={searchStyles.messageBox}
                      onClick={() => navigate(`/profile-detail/${item.userID}`)}
                    >
                      <div className={searchStyles.messageInfo}>
                        <div className={searchStyles.messageImg}>
                          <img
                            src={
                              item?.profile_picture
                                ? item?.profile_picture
                                : defaultImage
                            }
                            alt="img"
                          />
                        </div>
                        <div className={searchStyles.messageText}>
                          <p>{item.name}</p>
                          <span>@{item.username}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {item.reportID && (
                    <div className={reportStyles.reportSection} key={index}>
                      <div className={reportStyles.reportSectionLeft}>
                        <div
                          className={reportStyles.reportImg}
                          onClick={() => navigate(`/report/${item.reportID}`)}
                        >
                          <img
                            src={
                              item?.profile_picture
                                ? item?.profile_picture
                                : defaultImage
                            }
                            alt="img"
                          />
                        </div>
                        <div className={reportStyles.reportText}>
                          <p
                            onClick={() => navigate(`/report/${item.reportID}`)}
                          >
                            <strong>{item.category_name}</strong>
                            {item.location != "undefined" && (
                              <> &nbsp; - &nbsp;</>
                            )}
                            {item.location == "undefined" ? "" : item.location}{" "}
                            &nbsp; - &nbsp;
                            <span>
                              {item.date} {t("At")}{" "}
                              {item.time ? formatTime(item.time) : t("UNKNOWN")}
                            </span>
                          </p>

                          {item.attachment_thumb && (
                            <div className={searchStyles.picShow}>
                              <LightGallery
                                speed={500}
                                closable={true}
                                hideBarsDelay={0}
                                controls={false}
                                download={false}
                                counter={false}
                              >
                                <a href={item.attachment_url}>
                                  <img
                                    src={item.attachment_url}
                                    width="100"
                                    height="100"
                                    style={{ cursor: "pointer" }}
                                  />
                                </a>
                              </LightGallery>
                            </div>
                          )}
                          {item.video_thumb_url && (
                            <div className={searchStyles.showVideo}>
                              <video controls>
                                <source
                                  src={item.attachment_url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          )}

                          <div className={reportStyles.reportShow}>
                            <p>
                              <img src={commentIcon} alt="comment icon" />
                              &nbsp;
                              {item.comment_count == ""
                                ? 0
                                : item.comment_count}
                            </p>
                            <p>
                              <img src={viewIcon} alt="view icon" />
                              &nbsp;
                              {item.view_count == "" ? 0 : item.view_count}
                            </p>
                            <p>
                              <img src={bookmarkIcon} alt="bookmark icon" />
                              &nbsp;
                              {item.bookmark_count == ""
                                ? 0
                                : item.bookmark_count}
                            </p>
                            <p>
                              <img src={trendIcon} alt="trend icon" />
                              &nbsp;
                              {item.trend_count == "" ? 0 : item.trend_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : (
              <div className="notFound">
                <h3>{t("SEARCH_PLACEHOLDER")}</h3>
                <p>{t("Search_Text")}</p>
              </div>
            )}

            {showDataList?.length < totalReports ? (
              <div className="loadMoreSection">
                <button className="loadMoreBtn" onClick={loadMoreReports}>
                  {t("LOAD_MORE")}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
export default SearchResult;
