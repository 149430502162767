import Modal from "react-bootstrap/Modal";
import styles from "../Flag/Flag.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { FaRegFlag } from "react-icons/fa";

function FlagClosePopup(props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        className="mainModule"
        show={props.thanksflagShow}
        onHide={props.ThanksflagHandleClose}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2 onClick={props.ThanksflagHandleClose} />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div className={styles.moduleHeding}>
              <h2>{t("FLAG_REPORT")}</h2>
              <span>
                <FaRegFlag />
              </span>
            </div>

            <div className={styles.hedingFlagClose}>
              <h2>
                {t("THANK_YOU")} <br />
                <span>{t("YOUR_REPORT_HAS_BEEN_RECEIVES")}</span>
              </h2>
            </div>

            <div className={styles.flagBtn}>
              <button onClick={props.ThanksflagHandleClose}>
                {" "}
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FlagClosePopup;
