import Modal from "react-bootstrap/Modal";
import styles from "../../appComponent/UserModule/YourReport/YourReport.module.css";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

function DeleteConfirm({ setShowAlertModal, showAlertModal, onDelete, title }) {
  const { t } = useTranslation();

  // ----------- function for manage checkbox----------------
  const handleAlert = () => {
    onDelete();
    setShowAlertModal(false);
  };

  return (
    <>
      <Modal
        className="mainModule"
        show={showAlertModal}
        onHide={() => {
          setShowAlertModal(false);
        }}
      >
        <div className="MainBox">
          <div className="header">
            <span className="alertCross">
              <RxCross2
                onClick={() => {
                  setShowAlertModal(false);
                }}
              />
            </span>
          </div>
          <div className="hadingMainDiv">
            <div
              className={`${styles.deleteHeading} ${styles.deleteHeadingSpace}`}
            >
              <h2>{t("BEALERT")}</h2>
              <p>
                {t("DELETE_ALERT")} {title}
              </p>
            </div>

            <div className={styles.deleteBtnAdd}>
              <button
                className={styles.deleteCancel}
                onClick={() => {
                  setShowAlertModal(false);
                }}
              >
                {t("NO")}
              </button>
              <button
                className={styles.deleteContinue}
                onClick={() => {
                  handleAlert();
                }}
              >
                {t("YES")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteConfirm;
