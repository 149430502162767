import moment from "moment";

export function removeExtraPlusSigns(str) {
  let newStr = "+" + str;
  return newStr.replace(/\++/g, "+");
}

export const formatDateTime = (date, format = "YYYY-MM-DD HH:mm:ss") => {
  return moment(new Date(date)).format(format);
};

export const timestampToDate = (timestamp) => {
  const formattedDate = moment(timestamp).format("DD MMM YYYY");
  return formattedDate;
};

export const ChatTimestampToDate = (timestamp) => {
  const formattedDate = moment(timestamp).format("DD/MM/YYYY");
  return formattedDate;
};

export const img_path =
  "https://dmd64b2y1h1gb.cloudfront.net/uploads/profile/thumb/";
