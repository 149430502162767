// Removes extra "+" signs
export function removeExtraPlusSign(str) {
  let newStr = "+" + str;
  return newStr.replace(/\++/g, "+");
}

export const TextFormate = (text) => {
  if (!text) return "";
  return text.replace(/\r\n|\r|\n/g, "<br />");
};
